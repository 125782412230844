import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PreguntaService } from '@app/core';
import { PuestoTrabajoService } from '@core/service/puesto-trabajo.service';
import { EmpleadoService } from '@core/service/empleado.service';

@Component({
  selector: 'app-puesto-operativo',
  templateUrl: './puesto-operativo.component.html',
  styleUrls: ['./puesto-operativo.component.css']
})
export class PuestoOperativoComponent implements OnInit {
  puestoOperativoForm: FormGroup;
  preguntas: any[];
  puestoTrabajos: any[];
  empleados: any[];
  @Output() formReady = new EventEmitter<FormGroup>();
  @Input() sucursalId: string;
  @Input() categoria: any;
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private preguntasService: PreguntaService,
    private puestoTrabajoService: PuestoTrabajoService,
    private empleadosService: EmpleadoService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.createForm();
    if (this.sucursalId) {
      this.empleadosService
        // tslint:disable-next-line:radix
        .getEmpleadoSucursalId(parseInt(this.sucursalId))
        .subscribe(
          // mandar el id de la sucursal
          data => {
            this.empleados = data;
          }
        );
    }

    this.preguntasService.getPreguntaId(4).subscribe(data => {
      this.preguntas = data;
      for (let i = 0; i < data.length; i++) {
        this.puestoOperativos.push(
          this.crearPuestoOperativoFormGruop(data[i].pregunta_id)
        );
      }
      this.puestoOperativoForm.patchValue({ puestoOperativos: data });
      if (this.categoria) {
        this.puestoOperativoForm.patchValue({
          puestoOperativos: this.categoria.preguntas,
          observacion: this.categoria.observacion,
          categoria_id: this.categoria.categoria_id
        });
      }
    });

    this.puestoTrabajoService.getpuestotrabajo().subscribe(data => {
      this.puestoTrabajos = data;
      for (let i = 0; i < data.length; i++) {
        // this.planOperativos.push(this.crearPlanOperativoFormGruop(data[i].trabajo_id));
        this.planOperativos.push(this.crearPlanOperativoFormGruop());
      }
      this.puestoOperativoForm.patchValue({ planOperativos: data });
      if (this.categoria) {
        this.puestoOperativoForm.patchValue({
          planOperativos: this.categoria.puestos
        });
      }
    });
    this.formReady.emit(this.puestoOperativoForm);
  }

  createForm() {
    this.puestoOperativoForm = this.formBuilder.group({
      puestoOperativos: this.formBuilder.array([]),
      planOperativos: this.formBuilder.array([]),
      observacion: null,
      categoria_id: null
    });
  }

  get puestoOperativos(): FormArray {
    return this.puestoOperativoForm.get('puestoOperativos') as FormArray;
  }

  get planOperativos(): FormArray {
    return this.puestoOperativoForm.get('planOperativos') as FormArray;
  }

  crearPuestoOperativoFormGruop(pregunta_id?: number) {
    return this.formBuilder.group({
      descripcion: null,
      respuesta: false,
      observacion: null,
      pregunta_id: pregunta_id
    });
  }

  crearPlanOperativoFormGruop(trabajo_id?: number) {
    return this.formBuilder.group({
      nombre: null,
      empleado_id: null,
      trabajo_id: trabajo_id
    });
  }

  mostrarOb(i: any, input: HTMLInputElement) {
    // const input = document.getElementById(i);
    // console.log(input.classList.add(''));
    input.classList.remove('d-none');
  }

  ocultar(i: number, input: HTMLInputElement) {
    input.classList.add('d-none');
  }
}
