import { Component, OnInit } from '@angular/core';
import {
  AuthenticationService,
  InformeService,
  SucursalService
} from '@app/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reporte-sucursal',
  templateUrl: './reporte-sucursal.component.html',
  styleUrls: ['./reporte-sucursal.component.css']
})
export class ReporteSucursalComponent implements OnInit {
  loading: false;
  reporteForm: FormGroup;
  isLoading: false;
  sucursales: any[];
  infomes: any[];
  currentUserSubscription: Subscription;
  currentUser: any;
  userId: string;

  constructor(
    private informeService: InformeService,
    private sucursalService: SucursalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    // console.log(this.infomes);
    this.createForm();
    this.sucursalService.getAll().subscribe(data => {
      this.sucursales = data;
      // console.log(data);
    });
    this.currentUser = this.authService.credentials.user;
    // console.log('aqui users ', this.currentUser);
    // this.currentUserSubscription = this.authService.currentUser.subscribe(
    //   user => {
    //     this.currentUser = user;
    this.userId = this.currentUser.id;
    //   }
    // );

    this.reporteForm.patchValue({ user_id: this.userId });
    this.informeService
      .getReporteFecha(this.reporteForm.value)
      .subscribe(data => {
        this.infomes = data;
        // console.log(data);
      });
  }

  createForm() {
    this.reporteForm = this.formBuilder.group({
      fecha1: [moment().format('YYYY-MM-DD'), [Validators.required]],
      fecha2: [moment().format('YYYY-MM-DD'), [Validators.required]],
      sucursal_id: ['0', [Validators.required]],
      user_id: null
    });
  }

  buscar(value: any) {
    // console.log(value);
    this.informeService.getReporteFecha(value).subscribe(data => {
      this.infomes = data;
      console.log(data);
    });
  }

  verInfrome(informe_id: any) {
    this.router.navigate(['/dashboard/informes/detalles', informe_id]);
  }

  veficarDatos() {
    // console.log(this.infomes);
    if (this.infomes) {
      if (this.infomes && this.infomes.length <= 0) {
        return true;
      }
      return false;
    }
    return false;
  }
}
