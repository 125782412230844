import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment.prod';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  @Input() imagenes: any[];
  @Input() contador: number;
  url: string;

  constructor() {
    // this.contador = 0;
    this.url = environment.imgUrl;
  }

  ngOnInit() {}

  onPreviousClick() {
    const previous = this.contador - 1;
    this.contador = previous < 0 ? this.imagenes.length - 1 : previous;
  }

  onNextClick() {
    const next = this.contador + 1;
    this.contador = next === this.imagenes.length ? 0 : next;
  }

  indicators(i: number) {
    this.contador = i === this.imagenes.length ? 0 : i;
  }
}
