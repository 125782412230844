<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body bg-root">
        <div class="row">
          <div class="col-12">
            <h3 class="page-title text-white">Lista de Informes del dia</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="" *ngIf="informes">
  <div class="row mt-3">
    <div class="col-xs-12 col-md-3 col-lg-3 col-sm-6 col-xlg-2 " *ngFor="let supervicion of informes">
      <div class="card" style="min-width: 13rem">
        <div class="card-header bg-root text-white m-1 p-1">
          <p class="display-7 m-1">{{ supervicion.fecha | date: 'dd/MM/yyyy' }}</p>
        </div>
        <div class="card-body">
          <p class="">
            Nombre:
            <strong>{{ supervicion.supervisor.nombre_completo }}</strong>
            <br />
            Sucursal:<strong>{{ supervicion.sucursal.nombre }}</strong>
          </p>
          <div class="button-group" [ngSwitch]="supervicion.estado">
            <div class="div" *ngSwitchCase="'APERTURA'">
              <button type="button" class="btn btn-sm waves-effect waves-light  btn-rounded btn-primary">
                Apertura
              </button>
            </div>
            <div class="div" *ngSwitchCase="'TRANSICION'">
              <button type="button" class="btn btn-sm waves-effect waves-light  btn-rounded btn-primary">
                Transicion
              </button>
              <button
                *hasPermission="['administrador']"
                type="button"
                class="btn btn-sm btn-outline-danger btn-circle m-1"
                title="Eliminar transicion"
                (click)="eliminar(supervicion.informe_id)"
              >
                <i class=" fas fa-trash-alt"></i>
              </button>
            </div>
            <div class="div" *ngSwitchCase="'CIERRE'">
              <button type="button" class="btn btn-sm waves-effect waves-light  btn-rounded btn-primary">
                Cierre
              </button>
              <button
                *hasPermission="['administrador']"
                type="button"
                class="btn btn-sm btn-outline-danger btn-circle m-1"
                title="Eliminar cierre"
                (click)="eliminar(supervicion.informe_id)"
              >
                <i class=" fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
          <hr class="m-1 p-1" />
          <div class="row d-flex justify-content-around">
            <small class="float-left align-self-center"> codigo: {{ supervicion.informe_id }}</small>
            <button
              type="button"
              class="btn btn-sm btn-outline-warning btn-circle m-1 float-right"
              title="Detalle"
              (click)="verInforme(supervicion.informe_id, supervicion.fecha)"
            >
              <i class="mdi mdi-account-card-details"></i>
            </button>
            <a
              [routerLink]="['../galeria', supervicion.informe_id]"
              title="Ver fotos"
              class="btn btn-sm btn-outline-danger btn-circle m-1 "
            >
              <i class="mdi mdi-file-image"></i>
            </a>
            <button
              *hasPermission="['administrador']"
              type="button"
              class="btn btn-sm btn-outline-danger btn-circle m-1 float-right"
              title="Eliminar Todo"
              (click)="deleteAll(supervicion.informe_id)"
            >
              <i class=" fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
