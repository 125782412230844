import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informe',
  templateUrl: './infrome.component.html',
  styleUrls: ['./informe.component.css']
})
export class InformeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
