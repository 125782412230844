<div class="row">
  <div class="col">
    <a [routerLink]="['/dashboard/informes/lista-supercion/', id]">
      <i class="fas fa-arrow-left text-info"></i>
    </a>
  </div>
</div>
<div class="mat-typography">
  <h2 class="text-center ">Cierre Sucursal</h2>
  <div class="checkout-form">
    <form novalidate [formGroup]="cierreForm">
      <div class="row">
        <div class="col col-md-6 ">
          <!--        <p class="h3">{{usuario.nombres }} {{ usuario.apellidos}}</p>-->
          <p class="">
            <label>Supervisor: </label> <br>
            <span>{{ getNombreSupervisor() }}</span>
          </p>
        </div>
      </div>
      <mat-tab-group class="text-center">
        <mat-tab label="Saldo de productos finales">
          <app-saldo-productos
            [categoria]="informe.categorias[0]"
            *ngIf="informe"
            (formReady)="formInitialized('saldoProducto', $event)"
          ></app-saldo-productos>
          <app-saldo-productos *ngIf="!informe" (formReady)="formInitialized('saldoProducto', $event)"></app-saldo-productos>
        </mat-tab>
        <mat-tab label="Infraestructura" style="background:yellow">
          <!--          <billing-form (formReady)="formInitialized('billing', $event)"></billing-form>-->
          <app-infraestructura
            [categoria]="informe.categorias[3]"
            *ngIf="informe"
            (formReady)="formInitialized('infra', $event)"
          ></app-infraestructura>
          <app-infraestructura *ngIf="!informe" (formReady)="formInitialized('infra', $event)"></app-infraestructura>
        </mat-tab>
        <mat-tab label="Activo">
          <app-activo [categoria]="informe.categorias[1]" *ngIf="informe" (formReady)="formInitialized('activo', $event)"></app-activo>
          <app-activo *ngIf="!informe" (formReady)="formInitialized('activo', $event)"></app-activo>
        </mat-tab>

        <mat-tab label="Informe Financiero">
          <app-informe-financiero
            [categoria]="informe.categorias[2]"
            *ngIf="informe"
            (formReady)="formInitialized('informeFinanciero', $event)"
          ></app-informe-financiero>
          <app-informe-financiero *ngIf="!informe" (formReady)="formInitialized('informeFinanciero', $event)"></app-informe-financiero>
        </mat-tab>
        <mat-tab label="Salida Personal">
          <app-salida-personal
            [categoria]="informe.categorias[4]"
            *ngIf="informe"
            (formReady)="formInitialized('salidaPersonal', $event)"
          ></app-salida-personal>
          <app-salida-personal *ngIf="!informe" (formReady)="formInitialized('salidaPersonal', $event)"></app-salida-personal>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>
  <div class="row text-center">
    <div class="col">
      <button type="button" uiSref="work" class="btn btn-success btn-raised " [disabled]="isLoading" (click)="save()">
        Registrar
      </button>
    </div>
  </div>
  <!--  <h2 class="mat-h2">Form Value</h2>-->
  <!-- <pre>
    {{ cierreForm.value | json }}
  </pre> -->
</div>
