<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header bg-root ">
        <h4 class="card-title text-white float-left">Lista Puesto de Trabajo</h4>
        <button type="button" class="btn  btn-success float-right" (click)="nuevo()">
          Nuevo Puesto
        </button>
      </div>
      <div class="card-body">
        <div class="table-responsive-sm table-responsive-lg table-responsive-md table-hover">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Puesto de trabajo</th>
                <th scope="col">Estado</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let puesto of puestos; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ puesto.nombre }}</td>
                <td>
                  <label class="switch switch_type1" role="switch" *ngIf="puesto.activo == true">
                    <input type="checkbox" class="switch__toggle" (click)="enable(puesto.trabajo_id)" checked="false" />
                    <span class="switch__label"></span>
                  </label>
                  <label class="switch switch_type1" role="switch" *ngIf="puesto.activo == false">
                    <input type="checkbox" class="switch__toggle" (click)="enable(puesto.trabajo_id)" />
                    <span class="switch__label"></span>
                  </label>
                </td>
                <td>
                  <button type="button" class="btn btn-sm btn-icon  btn-outline " (click)="editar(puesto.trabajo_id)">
                    <i class="fas fa-pencil-alt text-warning " style="font-size: 20px"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
