import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SucursalService } from '@app/core';
import { CrearSucursalComponent } from '@app/dashboard/sucursal/crear-sucursal/crear-sucursal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lista-sucursal',
  templateUrl: './lista-sucursal.component.html',
  styleUrls: ['./lista-sucursal.component.css']
})
export class ListaSucursalComponent implements OnInit {
  productos: any[];
  modalOptions: NgbModalOptions = {};

  constructor(
    private sucursalService: SucursalService,
    private modalService: NgbModal,
    private route: Router
  ) {
    this.listarPrdouctos();
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {}

  listarPrdouctos() {
    this.sucursalService.getAll().subscribe(data => {
      this.productos = data;
      console.log(data);
    });
  }

  nuevo() {
    const modal = this.modalService.open(
      CrearSucursalComponent,
      this.modalOptions
    );
    modal.componentInstance.title = 'Nueva Sucursal';
    modal.componentInstance.estado = true;
    modal.result.then(res => {
      if (res) {
        this.listarPrdouctos();
      }
    });
  }

  editar(trabajo_id: any) {
    const modal = this.modalService.open(
      CrearSucursalComponent,
      this.modalOptions
    );
    modal.componentInstance.title = 'Editar Sucursal';
    modal.componentInstance.sucursalId = trabajo_id;
    modal.result.then(res => {
      if (res) {
        this.listarPrdouctos();
      }
    });
  }

  verEmpleados(sucursal_id: any) {
    this.route.navigate(['/dashboard/sucursales/empleados/' + sucursal_id], {
      replaceUrl: true
    });
  }
}
