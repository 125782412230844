import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-inputs-opcion',
  templateUrl: './inputs-opcion.component.html',
  styleUrls: ['./inputs-opcion.component.css']
})
export class InputsOpcionComponent implements OnInit {
  @Input() label: string;
  @Input() public group: FormGroup;

  constructor() {
  }

  ngOnInit() {
    // console.log(this.label);
    this.group.controls['cantidad'].valueChanges.subscribe(value => {
      if (value < 0) {
        this.group.patchValue({ cantidad: 0 });
      }
    });
  }
}
