<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form action="" [formGroup]="userForm" (ngSubmit)="registerUser(userForm.value)" class="form-horizontal form-material" id="userform" novalidate>
  <div class="modal-body">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 col-xs-12">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group m-t-20">
                <div class="col-xs-12">
                  <app-form-error-wrapper [control]="userForm.controls['username']" [controlName]="'Nombre de Usuario'">
                    <label for=""><i class="fa fa-user"></i> Nombre de Usuario</label>
                    <input class="form-control" type="text" formControlName="username" placeholder="Nombre de Usuario" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group m-t-20">
                <div class="col-xs-12">
                  <app-form-error-wrapper [control]="userForm.controls['password']" [controlName]="'Contraseña'">
                    <label for=""><i class="fa fa-key"></i> Contraseña</label>
                    <input class="form-control" type="password" formControlName="password" placeholder="Contraseña" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group m-t-20">
                <div class="col-xs-12">
                  <app-form-error-wrapper [control]="userForm.controls['email']" [controlName]="'Correo Electrónico'">
                    <label for="">Correo Electrónico</label>
                    <input class="form-control" type="email" formControlName="email" placeholder="Ejemplo@gmail.com" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group m-t-20">
                <div class="col-xs-12">
                  <app-form-error-wrapper [control]="userForm.controls['nombres']" [controlName]="'Nombres'">
                    <label for="">Nombres</label>
                    <input class="form-control" type="text" formControlName="nombres" placeholder="Nombres" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group m-t-20">
                <div class="col-xs-12">
                  <app-form-error-wrapper [control]="userForm.controls['apellidos']" [controlName]="'Apellidos'">
                    <label for="">Apellidos</label>
                    <input class="form-control" type="text" formControlName="apellidos" placeholder="Apellidos" />
                  </app-form-error-wrapper>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group m-t-20">
                <div class="col-xs-12">
                  <app-form-error-wrapper [control]="userForm.controls['celular']" [controlName]="'celular'">
                    <label for="">Celular</label>
                    <input class="form-control" type="text" formControlName="celular" placeholder="Celular" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group m-t-20">
                <div class="col-xs-12">
                  <app-form-error-wrapper [control]="userForm.controls['ci']" [controlName]="'ci'">
                    <label for="">Cédula de Identidad</label>
                    <input class="form-control" type="text" formControlName="ci" placeholder="C.I del Usuario " />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group m-t-20">
                <div class="col-xs-12">
                  <label for="">Permisos:</label><br />
                  <div formArrayName="roles">
                    <div *ngFor="let myOption of roles.controls; let i = index">
                      <div formGroupName="{{ i }}" class="custom-control custom-checkbox">
                        <input type="checkbox" id="a{{ i }}" class="custom-control-input" [formControl]="myOption.get('selected')" />
                        <label for="a{{ i }}" class="custom-control-label">
                          {{ myOption.get('display_name').value }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer ">
    <!--   <div class="container-fluid">
         <div class="row">
           <div class=" col-xs-4 col-sm-4 ">
             <button type="button" class="btn btn-default btn-block  waves-effect" aria-label="Close" title="Cerrar"
                     (click)="activeModal.dismiss('Cross click')">Cerrar
             </button>
           </div>
           <div class=" col-xs-6 col-sm-8 ">
             <button class="btn btn-primary  btn-block text-uppercase waves-effect waves-light" [disabled]="userForm.invalid || isLoading">
               <app-button-loader [isLoading]="isLoading" [label]="'Guardar'"></app-button-loader>
             </button>
           </div>
         </div>
       </div>-->
    <button type="button" class="btn btn-outline-secondary   waves-effect w-30" aria-label="Close" title="Cerrar"
            (click)="activeModal.dismiss('Cross click')">
      Cerrar
    </button>
    <button class="btn btn-success   btn-block text-uppercase waves-effect waves-light w-70" [disabled]="userForm.invalid || isLoading">
      <app-button-loader [isLoading]="isLoading" [label]="'Guardar'"></app-button-loader>
    </button>
    <!--    <button type="button" class="btn btn-default    waves-effect flex-grow-1" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          Cerrar
        </button>
        <button class="btn btn-primary  btn-block text-uppercase waves-effect waves-light flex-grow-1" [disabled]="userForm.invalid || isLoading">
          <app-button-loader [isLoading]="isLoading" [label]="'Guardar'"></app-button-loader>
        </button>-->
  </div>
</form>
