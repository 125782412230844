import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Rol } from '../model/rol.model';
import { AuthenticationService } from './authentication.service';
import { Subscription } from 'rxjs';
@Injectable()
export class AuthenticationGuard implements CanActivate {
  userroles: any;
  currentUserSubscription: Subscription;

  currentUser: any;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authenticationService.isAuthenticated()) {
      this.currentUser = this.authenticationService.credentials.user;
      const roles = route.data.roles;

      const match = this.roleMatch(roles);
      if (match) {
        return true;
      }
    }

    console.log('Not authenticated, redirecting...');
    this.router.navigate(['/login'], {
      replaceUrl: true
    });
    return false;
  }

  roleMatch(allowedRoles: any): boolean {
    let isMatch = false;
    const userRoles = this.currentUser.roles;

    allowedRoles.forEach((element: any) => {
      const allowed = userRoles.filter((a: any) => a.name === element);
      if (allowed.length > 0) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }
}
