import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MultimediaService } from '@app/core/service/multimedia.service';

@Component({
  selector: 'app-archivos',
  templateUrl: './archivos.component.html',
  styleUrls: ['./archivos.component.css']
})
export class ArchivosComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private multimediaService: MultimediaService
  ) {}

  get f() {
    return this.myForm.controls;
  }
  images: any = [];

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  imagenURL: any = {};
  progress = 0;
  isLoading = false;
  @Input() title: string;
  @Input() id: string;

  myForm = new FormGroup({
    turno_id: new FormControl(''),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  @Output() formReady = new EventEmitter<FormGroup>();
  private fileName: any;
  ngOnInit() {
    this.formReady.emit(this.myForm);
  }
  onFileChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      this.fileName = event.target.files[0].name;
      const [file] = event.target.files;

      this.fileData = <File>event.target.files[0];
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.myForm.patchValue({
          turno_id: this.id,
          file: event.target.files[0]
        });
      };
    }
  }

  submit() {
    const formData = new FormData();
    formData.append('turno_id', this.id);
    formData.append('file', this.fileData);

    // console.log(this.fileData);

    this.isLoading = true;
    this.multimediaService
      .saveArchivosInforme(formData)
      .pipe(
        finalize(() => {
          this.myForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data1: any) => {
          // console.log(data1);
          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'success',
            showConfirmButton: false,
            text: data1.success,
            timer: 2000
          });
          //   this.router.navigate(['/dashboard']);
        },
        error1 => {
          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'error',
            text: error1.error,
            showConfirmButton: false,
            timer: 2000
          });
        }
      );
  }
}
