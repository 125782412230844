<div class="d-flex">
  <a class="flex-grow-0 " (click)="back()" style="margin-top: 0px; cursor: pointer">
    <i class="fas fa-arrow-left text-info"></i>
  </a>
</div>

<div class="row el-element-overlay mt-2" *ngFor="let multi of multimedias">
  <div class="col-12">
    <div class="card">
      <div class="card-body bg-root">
        <div class="row">
          <div class="col col-sm-6 col-md-9">
            <h3 class="card-title text-white">{{ multi.estado }}</h3>
          </div>
          <div class="col col-sm-6 col-md-3 d-flex justify-content-around">
            <button type="button" (click)="addFiles(multi.turno_id)" class="btn btn-success float-right text-white" title="Subir archivo">
              <i class="fas fa-archive text-white" style="font-size: 20px"></i></button>
            <button type="button" (click)="addFotos(multi.turno_id)" class="btn btn-success float-right text-white" title="Subir foto">
              <i class="fas fa-image text-white" style="font-size: 20px"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-lg-3 col-md-3" *ngFor="let foto of multi.fotos">
        <div class="card">
          <div class="el-card-item">
            <div class="el-card-avatar el-overlay-1">
              <img [src]="url + foto.nombre" alt="Imagen de informe" (click)="verFoto(multi.fotos, foto.id)" />
            </div>
            <div class="d-flex no-block align-items-center">
              <div class="m-l-15">
                <h4 class="m-b-0">{{ multi.created_at }}</h4>
                <!--            <span class="text-muted">globe type chair for rest</span>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="row">
      <div class=" col-lg-2 col-md-3" *ngFor="let ar of multi.archivos">
        <div class="card">
          <div class="card-body px-1">
            <div class="row">
              <div class="col text-center">
                <a class="btn btn-block" [href]="url + ar.nombre" target="_blank">
                  <i class="fas fa-file-alt" style="font-size: 40px"></i>
                  <p>{{ ar.alias }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
