<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div
  id="main-wrapper"
  [ngClass]="{ 'show-sidebar': showMobileMenu }"
  [dir]="options.dir"
  [attr.data-theme]="options.theme"
  [attr.data-layout]="options.layout"
  [attr.data-sidebartype]="options.sidebartype"
  [attr.data-sidebar-position]="options.sidebarpos"
  [attr.data-header-position]="options.headerpos"
  [attr.data-boxed-layout]="options.boxed"
>
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->
  <header class="topbar noprint" [attr.data-navbarbg]="options.navbarbg">
    <nav class="d-flex top-navbar navbar-expand-md" [ngClass]="options.navbarbg == 'skin6' ? 'navbar-light' : 'navbar-dark'">
      <div class="navbar-header" [ngClass]="expandLogo ? 'expand-logo' : ''" [attr.data-logobg]="options.logobg">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)">
          <i [ngClass]="showMobileMenu ? 'ti-close' : 'ti-menu'"></i>
        </a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <a class="navbar-brand" href="/">
          <!-- Logo icon -->
          <b class="logo-icon">
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <img src="assets/images/logo-ferca.png" alt="homepage" class="dark-logo" width="40px" />
            <!-- Light Logo icon -->
            <img src="assets/images/logo-ferca.png" alt="homepage" class="light-logo" />
          </b>
          <!--End Logo icon -->
          <!-- Logo text -->
          <span class="logo-text">
            <!-- dark Logo text -->

          </span>
        </a>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Toggle which is visible on mobile only -->
        <!-- ============================================================== -->
        <a
          class="topbartoggler d-block d-md-none waves-effect waves-light"
          href="javascript:void(0)"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
        >
          <i class="ti-more"></i>
        </a>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div class="navbar-collapse collapse" id="navbarSupportedContent" [attr.data-navbarbg]="options.navbarbg" [ngbCollapse]="!isCollapsed">
        <app-navigation (toggleSidebar)="toggleSidebarType()" class="w-100"></app-navigation>
      </div>
    </nav>
  </header>
  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <aside class="left-sidebar noprint" [attr.data-sidebarbg]="options.sidebarbg" (mouseover)="Logo()" (mouseout)="Logo()">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar" [perfectScrollbar]="config">
      <app-sidebar></app-sidebar>
    </div>
    <!-- End Sidebar scroll-->
  </aside>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
    <app-breadcrumb></app-breadcrumb>
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid ">
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer text-center noprint">© {{ anio }} FERCA</footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
  <!--  <aside class="customizer noprint" [ngClass]="{ 'show-service-panel': showSettings }">
      <a href="javascript:void(0)" class="btn-dark btn btn-circle btn-lg service-panel-toggle" (click)="showSettings = !showSettings">
        <i class="fa fa-spin fa-cog"></i>
      </a>
      <div class="customizer-body custom-pills" [perfectScrollbar]="config">
        <ngb-tabset type="pills" [justify]="tabStatus">
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-wrench font-20"></i>
            </ng-template>
            <ng-template ngbTabContent>
              <div class="p-15 border-bottom">
                &lt;!&ndash; Sidebar &ndash;&gt;
                <h5 class="font-medium m-b-20 m-t-10">Layout Settings</h5>
                <div class="custom-control custom-checkbox m-t-10">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    name="theme-view"
                    id="theme-view"
                    [attr.checked]="options.theme == 'dark' ? 'checked' : null"
                    (change)="options.theme = options.theme == 'light' ? 'dark' : 'light'"
                  />
                  <label class="custom-control-label" for="theme-view">Dark Theme</label>
                </div>
                <div class="custom-control custom-checkbox m-t-10">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    name="sidebar-position"
                    id="sidebar-position"
                    [attr.checked]="options.sidebarpos == 'fixed' ? 'checked' : null"
                    (change)="options.sidebarpos = options.sidebarpos == 'fixed' ? 'absolute' : 'fixed'"
                  />
                  <label class="custom-control-label" for="sidebar-position">Fixed Sidebar</label>
                </div>
                <div class="custom-control custom-checkbox m-t-10">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    name="header-position"
                    id="header-position"
                    [attr.checked]="options.headerpos == 'fixed' ? 'checked' : null"
                    (change)="options.headerpos = options.headerpos == 'fixed' ? 'absolute' : 'fixed'"
                  />
                  <label class="custom-control-label" for="header-position">Fixed Header</label>
                </div>
                <div class="custom-control custom-checkbox m-t-10">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    name="boxed-layout"
                    id="boxed-layout"
                    [attr.checked]="options.boxed == 'boxed' ? 'checked' : null"
                    (change)="options.boxed = options.boxed == 'full' ? 'boxed' : 'full'"
                  />
                  <label class="custom-control-label" for="boxed-layout">Boxed Layout</label>
                </div>
                <div class="custom-control custom-checkbox m-t-10">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    name="rtl-layout"
                    id="rtl-layout"
                    [attr.checked]="options.dir == 'rtl' ? 'checked' : null"
                    (change)="options.dir = options.dir == 'rtl' ? 'ltr' : 'rtl'"
                  />
                  <label class="custom-control-label" for="rtl-layout">RTL</label>
                </div>
              </div>
              <div class="p-15 border-bottom">
                &lt;!&ndash; Sidebar &ndash;&gt;
                <h5 class="font-medium m-b-20 m-t-10">Sidebar Types</h5>
                <div class="custom-control custom-radio m-t-10">
                  <input
                    type="radio"
                    class="custom-control-input"
                    name="sidebar"
                    [(ngModel)]="options.sidebartype"
                    value="mini-sidebar"
                    id="minisidebar"
                    (change)="options.sidebartype = 'mini-sidebar'"
                  />
                  <label class="custom-control-label" for="minisidebar">Mini Sidebar</label>
                </div>
                <div class="custom-control custom-radio m-t-10">
                  <input
                    type="radio"
                    class="custom-control-input"
                    name="sidebar"
                    [(ngModel)]="options.sidebartype"
                    value="iconbar"
                    id="iconsidebar"
                    (change)="options.sidebartype = 'iconbar'"
                  />
                  <label class="custom-control-label" for="iconsidebar">Icon Sidebar</label>
                </div>
                <div class="custom-control custom-radio m-t-10">
                  <input
                    type="radio"
                    class="custom-control-input"
                    name="sidebar"
                    [(ngModel)]="options.sidebartype"
                    value="overlay"
                    id="overlaysidebar"
                    (change)="options.sidebartype = 'overlay'"
                  />
                  <label class="custom-control-label" for="overlaysidebar">Overlay Sidebar</label>
                </div>
                <div class="custom-control custom-radio m-t-10">
                  <input
                    type="radio"
                    class="custom-control-input"
                    name="sidebar"
                    [(ngModel)]="options.sidebartype"
                    value="full"
                    id="fullsidebar"
                    (change)="options.sidebartype = 'full'"
                  />
                  <label class="custom-control-label" for="fullsidebar">Full Sidebar</label>
                </div>
              </div>
              <div class="p-15 border-bottom">
                &lt;!&ndash; Logo BG &ndash;&gt;
                <h5 class="font-medium m-b-20 m-t-10">Logo Backgrounds</h5>
                <ul class="theme-color">
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-logobg="skin1" (click)="options.logobg = 'skin1'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-logobg="skin2" (click)="options.logobg = 'skin2'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-logobg="skin3" (click)="options.logobg = 'skin3'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-logobg="skin4" (click)="options.logobg = 'skin4'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-logobg="skin5" (click)="options.logobg = 'skin5'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-logobg="skin6" (click)="options.logobg = 'skin6'"></a>
                  </li>
                </ul>
                &lt;!&ndash; Logo BG &ndash;&gt;
              </div>
              <div class="p-15 border-bottom">
                &lt;!&ndash; Navbar BG &ndash;&gt;
                <h5 class="font-medium m-b-20 m-t-10">Navbar Backgrounds</h5>
                <ul class="theme-color">
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-navbarbg="skin1" (click)="options.navbarbg = 'skin1'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-navbarbg="skin2" (click)="options.navbarbg = 'skin2'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-navbarbg="skin3" (click)="options.navbarbg = 'skin3'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-navbarbg="skin4" (click)="options.navbarbg = 'skin4'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-navbarbg="skin5" (click)="options.navbarbg = 'skin5'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-navbarbg="skin6" (click)="options.navbarbg = 'skin6'"></a>
                  </li>
                </ul>
                &lt;!&ndash; Navbar BG &ndash;&gt;
              </div>
              <div class="p-15 border-bottom">
                &lt;!&ndash; Logo BG &ndash;&gt;
                <h5 class="font-medium m-b-20 m-t-10">Sidebar Backgrounds</h5>
                <ul class="theme-color">
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-sidebarbg="skin1" (click)="options.sidebarbg = 'skin1'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-sidebarbg="skin2" (click)="options.sidebarbg = 'skin2'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-sidebarbg="skin3" (click)="options.sidebarbg = 'skin3'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-sidebarbg="skin4" (click)="options.sidebarbg = 'skin4'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-sidebarbg="skin5" (click)="options.sidebarbg = 'skin5'"></a>
                  </li>
                  <li class="theme-item">
                    <a href="javascript:void(0)" class="theme-link" data-sidebarbg="skin6" (click)="options.sidebarbg = 'skin6'"></a>
                  </li>
                </ul>
                &lt;!&ndash; Logo BG &ndash;&gt;
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-message-reply font-20"></i>
            </ng-template>
            <ng-template ngbTabContent>
              <ul class="mailbox list-style-none m-t-20">
                <li>
                  <div class="message-center chat-scroll" [perfectScrollbar]="config">
                    &lt;!&ndash; Message &ndash;&gt;
                    <a href="#" class="message-item" id="chat_user_1" data-user-id="1">
                      <span class="user-img">
                        <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" />
                        <span class="profile-status online pull-right" data-status="online"></span>
                      </span>
                      <span class="mail-contnet">
                        <h5>Chris Evans</h5>
                        <span class="mail-desc">Just see the my admin!</span>
                        <span class="time">9:30 AM</span>
                      </span>
                    </a>
                    &lt;!&ndash; Message &ndash;&gt;
                    <a href="#" class="message-item" id="chat_user_2" data-user-id="2">
                      <span class="user-img">
                        <img src="assets/images/users/2.jpg" alt="user" class="rounded-circle" />
                        <span class="profile-status busy pull-right" data-status="busy"></span>
                      </span>
                      <span class="mail-contnet">
                        <h5>Ray Hudson</h5>
                        <span class="mail-desc">I've sung a song! See you at</span>
                        <span class="time">9:10 AM</span>
                      </span>
                    </a>
                    &lt;!&ndash; Message &ndash;&gt;
                    <a href="#" class="message-item" id="chat_user_3" data-user-id="3">
                      <span class="user-img">
                        <img src="assets/images/users/3.jpg" alt="user" class="rounded-circle" />
                        <span class="profile-status away pull-right" data-status="away"></span>
                      </span>
                      <span class="mail-contnet">
                        <h5>Lb James</h5>
                        <span class="mail-desc">I am a singer!</span>
                        <span class="time">9:08 AM</span>
                      </span>
                    </a>
                    &lt;!&ndash; Message &ndash;&gt;
                    <a href="#" class="message-item" id="chat_user_4" data-user-id="4">
                      <span class="user-img">
                        <img src="assets/images/users/4.jpg" alt="user" class="rounded-circle" />
                        <span class="profile-status offline pull-right" data-status="offline"></span>
                      </span>
                      <span class="mail-contnet">
                        <h5>Don Andres</h5>
                        <span class="mail-desc">Just see the my admin!</span>
                        <span class="time">9:02 AM</span>
                      </span>
                    </a>
                    &lt;!&ndash; Message &ndash;&gt;
                    <a href="#" class="message-item" id="chat_user_5" data-user-id="5">
                      <span class="user-img">
                        <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" />
                        <span class="profile-status online pull-right" data-status="online"></span>
                      </span>
                      <span class="mail-contnet">
                        <h5>Chris Evans</h5>
                        <span class="mail-desc">Just see the my admin!</span>
                        <span class="time">9:30 AM</span>
                      </span>
                    </a>
                    &lt;!&ndash; Message &ndash;&gt;
                    <a href="#" class="message-item" id="chat_user_6" data-user-id="6">
                      <span class="user-img">
                        <img src="assets/images/users/2.jpg" alt="user" class="rounded-circle" />
                        <span class="profile-status busy pull-right" data-status="busy"></span>
                      </span>
                      <span class="mail-contnet">
                        <h5>Ray Hudson</h5>
                        <span class="mail-desc">I've sung a song! See you at</span>
                        <span class="time">9:10 AM</span>
                      </span>
                    </a>
                    &lt;!&ndash; Message &ndash;&gt;
                    <a href="#" class="message-item" id="chat_user_7" data-user-id="7">
                      <span class="user-img">
                        <img src="assets/images/users/3.jpg" alt="user" class="rounded-circle" />
                        <span class="profile-status away pull-right" data-status="away"></span>
                      </span>
                      <span class="mail-contnet">
                        <h5>Lb James</h5>
                        <span class="mail-desc">I am a singer!</span>
                        <span class="time">9:08 AM</span>
                      </span>
                    </a>
                    &lt;!&ndash; Message &ndash;&gt;
                    <a href="#" class="message-item" id="chat_user_8" data-user-id="8">
                      <span class="user-img">
                        <img src="assets/images/users/4.jpg" alt="user" class="rounded-circle" />
                        <span class="profile-status offline pull-right" data-status="offline"></span>
                      </span>
                      <span class="mail-contnet">
                        <h5>Don Andres</h5>
                        <span class="mail-desc">Just see the my admin!</span>
                        <span class="time">9:02 AM</span>
                      </span>
                    </a>
                    &lt;!&ndash; Message &ndash;&gt;
                    <a href="#" class="message-item" id="chat_user_9" data-user-id="9">
                      <span class="user-img">
                        <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" />
                        <span class="profile-status online pull-right" data-status="online"></span>
                      </span>
                      <span class="mail-contnet">
                        <h5>Chris Evans</h5>
                        <span class="mail-desc">Just see the my admin!</span>
                        <span class="time">9:30 AM</span>
                      </span>
                    </a>
                    &lt;!&ndash; Message &ndash;&gt;
                    <a href="#" class="message-item" id="chat_user_10" data-user-id="10">
                      <span class="user-img">
                        <img src="assets/images/users/2.jpg" alt="user" class="rounded-circle" />
                        <span class="profile-status busy pull-right" data-status="busy"></span>
                      </span>
                      <span class="mail-contnet">
                        <h5>Ray Hudson</h5>
                        <span class="mail-desc">I've sung a song! See you at</span>
                        <span class="time">9:10 AM</span>
                      </span>
                    </a>
                    &lt;!&ndash; Message &ndash;&gt;
                    <a href="#" class="message-item" id="chat_user_11" data-user-id="11">
                      <span class="user-img">
                        <img src="assets/images/users/3.jpg" alt="user" class="rounded-circle" />
                        <span class="profile-status away pull-right" data-status="away"></span>
                      </span>
                      <span class="mail-contnet">
                        <h5>Lb James</h5>
                        <span class="mail-desc">I am a singer!</span>
                        <span class="time">9:08 AM</span>
                      </span>
                    </a>
                    &lt;!&ndash; Message &ndash;&gt;
                    <a href="#" class="message-item" id="chat_user_12" data-user-id="12">
                      <span class="user-img">
                        <img src="assets/images/users/4.jpg" alt="user" class="rounded-circle" />
                        <span class="profile-status offline pull-right" data-status="offline"></span>
                      </span>
                      <span class="mail-contnet">
                        <h5>Don Andres</h5>
                        <span class="mail-desc">Just see the my admin!</span>
                        <span class="time">9:02 AM</span>
                      </span>
                    </a>
                  </div>
                </li>
              </ul>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-star-circle font-20"></i>
            </ng-template>
            <ng-template ngbTabContent>
              <div class="p-15">
                <h6 class="m-t-20 m-b-20">Activity Timeline</h6>
                <div class="steamline">
                  <div class="sl-item">
                    <div class="sl-left bg-success">
                      <i class="ti-user"></i>
                    </div>
                    <div class="sl-right">
                      <div class="font-medium">
                        Meeting today
                        <span class="sl-date"> 5pm</span>
                      </div>
                      <div class="desc">you can write anything</div>
                    </div>
                  </div>
                  <div class="sl-item">
                    <div class="sl-left bg-info">
                      <i class="fas fa-image"></i>
                    </div>
                    <div class="sl-right">
                      <div class="font-medium">Send documents to Clark</div>
                      <div class="desc">Lorem Ipsum is simply</div>
                    </div>
                  </div>
                  <div class="sl-item">
                    <div class="sl-left">
                      <img class="rounded-circle" alt="user" src="assets/images/users/2.jpg" />
                    </div>
                    <div class="sl-right">
                      <div class="font-medium">
                        Go to the Doctor
                        <span class="sl-date">5 minutes ago</span>
                      </div>
                      <div class="desc">Contrary to popular belief</div>
                    </div>
                  </div>
                  <div class="sl-item">
                    <div class="sl-left">
                      <img class="rounded-circle" alt="user" src="assets/images/users/1.jpg" />
                    </div>
                    <div class="sl-right">
                      <div>
                        <a href="javascript:void(0)">Stephen</a>
                        <span class="sl-date">5 minutes ago</span>
                      </div>
                      <div class="desc">Approve meeting with tiger</div>
                    </div>
                  </div>
                  <div class="sl-item">
                    <div class="sl-left bg-primary">
                      <i class="ti-user"></i>
                    </div>
                    <div class="sl-right">
                      <div class="font-medium">
                        Meeting today
                        <span class="sl-date"> 5pm</span>
                      </div>
                      <div class="desc">you can write anything</div>
                    </div>
                  </div>
                  <div class="sl-item">
                    <div class="sl-left bg-info">
                      <i class="fas fa-image"></i>
                    </div>
                    <div class="sl-right">
                      <div class="font-medium">Send documents to Clark</div>
                      <div class="desc">Lorem Ipsum is simply</div>
                    </div>
                  </div>
                  <div class="sl-item">
                    <div class="sl-left">
                      <img class="rounded-circle" alt="user" src="assets/images/users/4.jpg" />
                    </div>
                    <div class="sl-right">
                      <div class="font-medium">
                        Go to the Doctor
                        <span class="sl-date">5 minutes ago</span>
                      </div>
                      <div class="desc">Contrary to popular belief</div>
                    </div>
                  </div>
                  <div class="sl-item">
                    <div class="sl-left">
                      <img class="rounded-circle" alt="user" src="assets/images/users/6.jpg" />
                    </div>
                    <div class="sl-right">
                      <div>
                        <a href="javascript:void(0)">Stephen</a>
                        <span class="sl-date">5 minutes ago</span>
                      </div>
                      <div class="desc">Approve meeting with tiger</div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </aside>-->
  <div class="chat-windows"></div>
</div>
