<div
  class="auth-wrapper d-flex no-block justify-content-center align-items-center"
  style="background:url(assets/images/background/login-register.jpg) no-repeat center center;"
>
  <div class="auth-box m-3 m-md-0">
    <div id="loginform">
      <div class="logo">
        <span class="db"><img src="assets/images/logo-ferca.png" alt="logo" width="300px"/></span>
        <h5 class="font-medium m-b-20">Ingrese sus credenciales</h5>
      </div>
      <!-- Form -->
      <div class="row">
        <div class="col-12">
          <form [formGroup]="loginForm" (ngSubmit)="login()" class="form-horizontal" id="loginform" novalidate>
            <div class="form-group m-t-20">
              <div class="col-xs-12">
                <app-form-error-wrapper [control]="loginForm.controls['email']" [controlName]="'Usuario'">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                    </div>
                    <input class="form-control" type="text" formControlName="email" placeholder="Usuario" />
                  </div>
                </app-form-error-wrapper>
              </div>
            </div>

            <div class="form-group">
              <div class="col-xs-12">
                <app-form-error-wrapper [control]="loginForm.controls['password']" [controlName]="'Password'">
                  <!--  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon2"><i class="ti-key"></i></span>
                    </div>
                    <input class="form-control" type="password" formControlName="password" placeholder="Password" />
                  </div>-->
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon2"><i class="ti-key"></i></span>
                    </div>
                    <input [type]="type" class="form-control" formControlName="password" aria-describedby="basic-addon2" />
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2" (click)="cambiar(estadoType)" style="cursor: pointer">
                        <i class="mdi" [ngClass]="estadoType ? 'mdi-eye-off ' : 'mdi-eye'"></i
                      ></span>
                    </div>
                  </div>
                </app-form-error-wrapper>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <div class="custom-control custom-checkbox d-flex">
                  <input type="checkbox" formControlName="recuerdame" class="custom-control-input" id="customCheck1" />
                  <label class="custom-control-label font-14" for="customCheck1">Recuérdame</label>
                  <!--                  <a href="javascript:void(0)" id="to-recover" class="text-dark font-14 ml-auto"><i class="fa fa-lock m-r-5"></i> ¿Olvidó pwd?</a>-->
                </div>
              </div>
            </div>
            <div class="form-group text-center m-t-10">
              <div class="col-xs-12">
                <button
                  class="btn btn-success  btn-lg btn-block text-uppercase  waves-effect waves-light btn-rounded"
                  [disabled]="loginForm.invalid || isLoading"
                >
                  <app-button-loader [isLoading]="isLoading" [label]="'INGRESAR'"></app-button-loader>
                </button>
              </div>
            </div>
            <div class="form-group m-b-0"></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
