import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsuarioComponent } from './usuario.component';
import { ListaUsuariosComponent } from './lista-usuarios/lista-usuarios.component';
import { AuthenticationGuard } from '@app/core';
import { UsersAperturaComponent } from '@app/dashboard/usuario/users-apertura/users-apertura.component';

const routes: Routes = [
  {
    path: '',
    component: UsuarioComponent,
    canActivate: [AuthenticationGuard],
    data: {
      title: 'Dashboard',
      roles: ['administrador']
    },
    children: [
      {
        path: 'lista-usuarios',
        //   canActivate: [AuthenticationGuard],
        component: ListaUsuariosComponent,
        data: {
          title: 'Lista usuarios',
          //    roles: ['administrador']
          urls: [{ title: 'Dashboard', url: '/dashboard' }, { title: 'Lista usuarios' }]
        }
      },
      {
        path: 'permiso-apertura',
        component: UsersAperturaComponent,
        data: {
          // title: 'Permisos de aperturas',
          urls: [{ title: 'Dashboard', url: '/dashboard' }, { title: 'Lista de aperturas' }]
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsuarioRoutingModule {}
