import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Categoria } from '@core/model/categoria.model';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService extends BaseAPIClass {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.baseUrl = '/categorias';
  }

  getPreguntasCierre(): Observable<Categoria[]> {
    return this.httpClient.get<Categoria[]>(this.baseUrl + `/cierre`);
  }
  getCategoriasHabilitadas(): Observable<Categoria[]> {
    return this.httpClient.get<Categoria[]>(this.baseUrl + `/habilitados`);
  }

  getCategoriasId(): Observable<Categoria[]> {
    return this.httpClient.get<Categoria[]>(this.baseUrl + `/habilitados`);
  }

  getPoliticaId(politica_id: any): Observable<Categoria[]> {
    return this.httpClient.get<Categoria[]>(
      this.baseUrl + `/politica/${politica_id}`
    );
  }
}
