<div class="modal-header">
  <h4 class="modal-title">Verificar</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('cerrar')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="confirmacionForm" (ngSubmit)="register(confirmacionForm.value)" class="form-horizontal form-material" id="userform" novalidate>
  <div class="modal-body">
    <div class="card-body">
      <div class="form-row">
        <div class="form-group col-12">
          <app-form-error-wrapper [control]="confirmacionForm.controls['username']" [controlName]="'Usuario'">
            <label for="usuario">Usuario

            </label>
            <button type="button" class="btn btn-sm btn-secondary ml-2" style="border-radius: 6px;padding: 0px 12px;"
                    ngbPopover="El usuario ingresado debe ser propiedad del supervisor que forma parte del proceso de transición, aparte de usted"
                    [openDelay]="300" [closeDelay]="500" triggers="mouseenter:mouseleave">
              <i class="fas fa-info" style="font-size: 10px"></i>
            </button>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <i class="ti-user"></i>
                </span>
              </div>
              <input class="form-control" type="text" id="usuario" formControlName="username" placeholder="Usuario de transferencia"/>
            </div>
          </app-form-error-wrapper>
        </div>

        <div class="form-group col-12">
          <app-form-error-wrapper [control]="confirmacionForm.controls['password']" [controlName]="'Password'">
            <label for="password">Contraseña</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon2">
                  <i class="ti-pencil"></i>
                </span>
              </div>
              <input class="form-control" type="password" id="password" formControlName="password" placeholder="Ingrese contraseña" />
            </div>
          </app-form-error-wrapper>
        </div>
      </div>
      <!--    </form>-->
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary waves-effect w-30" aria-label="Close" (click)="activeModal.close('cerrar')">
      Cerrar
    </button>
    <button
      type="submit"
      class="btn btn-success   w-70 btn-block text-uppercase waves-effect waves-light"
      [disabled]="confirmacionForm.invalid || isLoading"
    >
      <app-button-loader [isLoading]="isLoading" [label]="'Verificar'"></app-button-loader>
    </button>
  </div>
</form>
