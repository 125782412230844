import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsuarioRoutingModule } from './usuario-routing.module';
import { UsuarioComponent } from './usuario.component';
import { ListaUsuariosComponent } from './lista-usuarios/lista-usuarios.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { SharedModule } from '@app/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AssignSucursalComponent } from './assign-sucursal/assign-sucursal.component';
import { ComponentsModule } from '@app/component/component.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UsersAperturaComponent } from '@app/dashboard/usuario/users-apertura/users-apertura.component';
@NgModule({
  declarations: [
    UsuarioComponent,
    ListaUsuariosComponent,
    CreateUserComponent,
    AssignSucursalComponent,
    UsersAperturaComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    SharedModule,
    UsuarioRoutingModule,
    ComponentsModule,
    NgMultiSelectDropDownModule
  ],
  entryComponents: [CreateUserComponent, AssignSucursalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UsuarioModule {}
