<div class="d-flex">


  <a class="flex-grow-0 " [routerLink]="['/']" style="margin-top: 0px">
    <i class="fas fa-arrow-left text-info"></i></a>
</div>


<div class="row mt-2">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body bg-root">
        <div class="row">
          <div class="col col-md-6 col-xs-12 col-lg-4 col-xl-4">
            <h4 class="page-title text-white">{{nombreSucursal}}</h4>
          </div>
          <div class="col col-md-6 col-xs-12 col-lg-8 col-xl-8 text-md-right  mt-md-0">
            <div class="button-group" *hasPermission="['supervisor', 'jefe']">
              <a
                href="javascript:void(0)"
                (click)="redirect({detail:{url:'/dashboard/informes/apertura', sucursal_id:id, tipo: ' '}})"
                class="btn waves-effect waves-light  btn-rounded btn-success"
              >Apertura</a>
              <!--              (click)="redirect('/dashboard/informes/apertura', id, null, 'Apertura')"-->
              <!--         <a
                type="button"
                [routerLink]="['../../transicion', id]"
                class="btn waves-effect waves-light  btn-rounded btn-primary"
              >
                Transicion
              </a>
              <a
                [routerLink]="['../../cierre', id]"
                class="btn waves-effect waves-light  btn-rounded btn-primary"
                data-dismiss="modal"
                >Cierre</a>
              -->
              <!--          <button type="button" class="btn waves-effect waves-light btn-lg btn-rounded btn-primary">Cierre</button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row">
  <div class=" col-12"
       infinite-scroll
       [infiniteScrollDistance]="7"
       [infiniteScrollUpDistance]="50"
       (scrolled)="onScroll()"
       (scrolledUp)="onUp()"
       [infiniteScrollDisabled]="disableScroll"
  >
    <custom-card-informe
      [urlops]="{
      apertura:'',
      transicion:'/dashboard/informes/transicion',
      cierre:'/dashboard/informes/cierre'
      }"
      [data]="superviciones"
      (redirectClick)="redirect($event)"
      (showInformeClick)="verInforme($event)"
      (showPhotoClick)="verFoto($event)"
    ></custom-card-informe>
    <!--    (showInformeClick)="verInforme($event)"-->

    <!--    <div class="row mt-3">
          <div class="col-xs-12 col-md-3 col-lg-3 col-sm-6 col-xlg-2 " *ngFor="let supervicion of superviciones">
            <div class="card">
              <div class="card-header bg-root text-white m-1 p-1">
                <p class="display-8 m-1">{{ supervicion.fecha | date: 'dd-MMM-yy' }}</p>
              </div>
              <div class="card-body">
                <div class="button-group" *hasPermission="['supervisor', 'jefe']">
                  <a
                    href="javascript:void(0)"
                    *ngIf="supervicion.estado === 'APERTURA'"
                    (click)="redirect('/dashboard/informes/transicion', id, supervicion.informe_id, 'Transicion')"
                    class="btn btn-sm waves-effect waves-light  btn-rounded btn-primary"
                  >Transicion</a
                  >
                  <a
                    *ngIf="supervicion.estado === 'TRANSICION'"
                    href="javascript:void(0)"
                    (click)="redirect('/dashboard/informes/cierre', id, supervicion.informe_id, 'Cierre')"
                    class="btn btn-sm waves-effect waves-light  btn-rounded btn-primary text-black-50"
                    data-dismiss="modal"
                  >Cierre</a
                  >
                  &lt;!&ndash;              [routerLink]="['../../cierre', id, supervicion.informe_id]"&ndash;&gt;
                  <a *ngIf="supervicion.estado === 'CIERRE'" class="btn btn-sm waves-effect waves-light  btn-rounded btn-success">Finalizado</a>
                </div>
                <div class="row d-flex justify-content-around">
                  <small class="float-left align-self-center"> codigo: {{ supervicion.informe_id }}</small>
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-warning btn-circle m-1 float-right"
                    title="Detalle"
                    (click)="verInforme(supervicion.informe_id, supervicion.fecha)"
                  >
                    <i class="mdi mdi-account-card-details"></i>
                  </button>

                  <a [routerLink]="['../../galeria', supervicion.informe_id]" title="Ver fotos" class="btn btn-sm btn-outline-danger btn-circle m-1 ">
                    <i class="mdi mdi-file-image"></i>
                  </a>
                  &lt;!&ndash;<button
                  *hasPermission="['administrador']"
                  type="button"
                  class="btn btn-sm btn-outline-danger btn-circle m-1 float-right"
                  title="Eliminar"
                  (click)="eliminar(supervicion.informe_id)"
                >
                  <i class=" fas fa-trash-alt"></i>
                </button>&ndash;&gt;
                </div>
              </div>
            </div>
          </div>
        </div>-->
    <div class="row" style="height: 40vh; width: 100% ; margin-top: -50px">
      <div class="col-12" *ngIf="loadingScroll">
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>
</div>
