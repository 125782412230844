import { Component, OnInit } from '@angular/core';
import { InformeService } from '@core/service/informe.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Paginated } from '@core/model/paginated.model';
import { AuthenticationService, SucursalService, UserService } from '@app/core';
// import '../../../../assets/js/cutom elemets/analytics-counter';
// import '../../../../assets/js/cutom elemets/analytics-counter';

@Component({
  selector: 'app-lista-supervicion',
  templateUrl: './lista-supervicion.component.html',
  styleUrls: ['./lista-supervicion.component.css']
})
export class ListaSupervicionComponent implements OnInit {
  id: string;
  superviciones: any[];
  paginate: Paginated;
  loadingScroll: boolean;
  url: string;
  disableScroll: boolean;
  isHablitado = false;
  user: any;
  user_id: string;
  nombreSucursal = '';

  constructor(
    private informeService: InformeService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authService: AuthenticationService,
    private  sucursalService: SucursalService
  ) {
    this.loadingScroll = false;
    this.disableScroll = false;
  }

  ngOnInit() {
    // this.route.snapshot.paramMap.get('idSucursal');
    this.route.params.subscribe(params => {
      this.id = params.idSucursal;
      // tslint:disable-next-line:radix
      this.listaSupervicioSucursal(parseInt(this.id));
    });
    // console.log(this.id);
    if (this.authService.credentials) {
      this.user = this.authService.credentials.user;
      this.user_id = this.user.id;
    }
    const sucu = this.sucursalService.getById(this.id).subscribe(res => {
      this.nombreSucursal = res.nombre;
    });
    const data = {
      sucursal_id: this.id,
      user_id: this.user_id
    };
    this.userService.userOpening(data).subscribe(data1 => {
      // console.log(data1);
      this.isHablitado = data1.success;
    });
  }

  listaSupervicioSucursal(idSucursal: number) {
    this.loadingScroll = true;
    this.informeService.getInformeSucursal(idSucursal).subscribe(data => {
      console.log('supervicion', data);
      this.paginate = data;
      this.superviciones = data.data;
      this.loadingScroll = false;
    });
  }


  /*  verInforme(informe_id: string, fecha: string) {
      // console.log(informe_id);
      // @ts-ignore
      this.router.navigate(['/dashboard/informes/detalles', informe_id]);
    }*/

  eliminar(informe_id: any) {
    Swal.fire({
      title: 'Estas seguro?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, acepto'
    }).then((result: any) => {
      if (result.value) {
        this.informeService.deleteInforme(informe_id).subscribe(data => {
          // console.log(data);
          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'success',
            text: data.success,
            showConfirmButton: false,
            timer: 2000
          });
        });
      }
    });
  }

  onScroll() {
    this.loadingScroll = true;
    if (this.loadingScroll) {
      this.loadNext();
      this.disableScroll = true;
    }
  }

  loadNext() {
    // console.log(page, last_page);
    if (this.paginate.next_page_url) {
      this.informeService
        .getInformeSucursalPaginateNext(this.paginate.next_page_url)
        .subscribe(data => {
          // console.log('newData', data);
          this.paginate = data; // formateamos con nuevo paginnate
          if (data.next_page_url) {
            this.disableScroll = false; // desbroqueamos el scrool
          }
          this.superviciones = this.superviciones.concat(data.data); // concatena dos array iguales
          this.loadingScroll = false;
        });
    } else {
      this.loadingScroll = false;
    }
  }

  onUp() {
    console.log('on up');
  }

  // redirect(url: string, sucursal_id: string, informe_id: any, tipos: string) {
  redirect(datacustom: any) {
    const data = {
      sucursal_id: this.id,
      user_id: this.user_id
    };
    console.log(datacustom);
    if (datacustom.detail.tipo === 'Cierre') {
      const dataCierre = {
        sucursal_id: this.id,
        user_id: this.user_id,
        informe_id: datacustom.detail.informe_id
      };
      // console.log(dataCierre);
      this.userService.userClose(dataCierre).subscribe(
        data1 => {
          // console.log(data1);
          this.isHablitado = data1.success;
          // console.log(url);
          if (data1.success) {
            this.router.navigate([datacustom.detail.url, datacustom.detail.sucursal_id, datacustom.detail.informe_id]);
          } else {
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'warning',
              text: 'No autorizado para cierre.',
              showConfirmButton: false,
              timer: 2000
            });
          }
        },
        error => {

          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'error',
            text: error.error,
            showConfirmButton: false,
            timer: 2000
          });
        }
      );
    } else if (datacustom.detail.tipo === 'Transicion') {
      const dataTransi = {
        sucursal_id: this.id,
        user_id: this.user_id,
        informe_id: datacustom.detail.informe_id
      };
      // console.log(dataTransi);
      this.userService.userTransicion(dataTransi).subscribe(data3 => {
        // console.log(data3);
        if (data3.success) {
          this.router.navigate([datacustom.detail.url, datacustom.detail.sucursal_id, datacustom.detail.informe_id]);
        } else {
          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'warning',
            text: 'No autorizado para transicion.',
            showConfirmButton: false,
            timer: 2000
          });
        }
      });
    } else {
      console.log(datacustom);
      this.userService.userOpening(data).subscribe(
        data1 => {
          // console.log(data1);
          this.isHablitado = data1.success;
          if (data1.success) {
            this.router.navigate([datacustom.detail.url, datacustom.detail.sucursal_id]);
          } else {
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'warning',
              text: 'No autorizado para apertura.',
              showConfirmButton: false,
              timer: 2000
            });
          }
        },
        error => {
          // console.log(error);
          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'error',
            text: error.error,
            showConfirmButton: false,
            timer: 2000
          });
        }
      );
    }
  }


  verFoto(data: any) {
    console.log(data);
    this.router.navigate([data.detail.ruta, data.detail.informe_id]);
  }


  verInforme(data: any) {
    console.log('ver', event);
    this.router.navigate([data.detail.ruta, data.detail.informe_id]);
  }
}
