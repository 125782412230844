import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewContainerRef,
  TemplateRef
} from '@angular/core';
// import * as jwt_decode from 'jwt-decode';
import { Directive } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { Subscription } from 'rxjs';
import { User } from '@app/core/model/user.model';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective {
  //  private currentUser;
  private permissions: any[];
  private isHidden = true;
  username: string;
  user: User;
  currentUser: any;

  userRoles: any;

  currentUserSubscription: Subscription;
  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private authenticationService: AuthenticationService,
    private viewContainer: ViewContainerRef
  ) {}

  /// ngOnInit() {
  // this.updateView();
  // }

  @Input()
  set hasPermission(val: any) {
    this.permissions = val;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }
  private checkPermission() {
    let hasPermission = false;

    if (this.authenticationService.isAuthenticated()) {
      // console.log(route.data.roles);
      const match = this.roleMatch(this.permissions);
      if (match) {
        hasPermission = true;
      }
    }

    return hasPermission;
  }

  roleMatch(allowedRoles: any): boolean {
    this.currentUser = this.authenticationService.credentials.user;
    this.user = this.currentUser;
    this.userRoles = this.user.roles;

    let isMatch = false;
    const userRoles = this.userRoles;
    allowedRoles.forEach((element: any) => {
      const allowed = userRoles.filter((a: any) => a.name === element);
      if (allowed.length > 0) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }
  getDecodedAccessToken(token: string): any {
    try {
      // return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  // ngOnDestroy() {
  //   // this.subscription && this.subscription.unsubscribe();
  // }
}
