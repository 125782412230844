<div *ngIf="loading">
  <app-spinner></app-spinner>
</div>
<div class="row">
  <div class="col">
    <a  (click)="back()">
      <i class="fas fa-arrow-left text-info"></i>
    </a>
  </div>
</div>
<div *ngFor="let i of detalles">
  <div class="modal-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9 col-sm-12 d-flex justify-content-between">
          <span class=" h4 text-md-left text-sm-left ">
            <span>Detalle de:</span> <br> <strong>{{ i.fecha | date: 'dd/MM/yyyy' }}</strong>
          </span>
          <span class="h4 text-left ml-2">
           <span> Sucursal:</span> <br> <strong class="text-md-right">{{ i.sucursal  }}</strong>
          </span>
          <span class="h4 text-md-left  text-sm-left ml-2">Codigo: <br> <strong>{{ i.codigo }}</strong></span>
        </div>
        <div class="col-md-3 col-sm-12 d-flex justify-content-around">
          <button type="button" class="noprint btn btn-success  " onclick="window.print();return false;">
            <i class="fa fa-print"></i>
          </button>
          <a type="button" class="noprint btn  btn-success " [href]="url+'/reporte-pdf/informe/'+ informeId" target="_blank">PDF</a>
          <button type="button" class="noprint btn  btn-success " [routerLink]="['/dashboard/informes/galeria/',i.codigo ]">Archivos</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 mt-2" *ngFor="let detalle of i.turnos">
        <div class="card ">
          <div class="card-header bg-danger bg-root text-white">
            <div class="row d-flex justify-content-between">
              <p class="text-center float-left float-sm-none mb-1">
                {{ detalle.titulo }}
              </p>
              <div class="text-center " *ngIf="detalle.a_tiempo; else f_tiempo">
                <p class=" mb-1" style="font-size: 12px">
                  <strong>{{ detalle.fecha | date: 'dd/MM/yyyy' }} </strong>
                </p>
                <button
                  *hasPermission="['administrador']"
                  type="button"
                  class="btn btn-sm btn-outline-warning btn-circle m-1 "
                  title="editar"
                  (click)="editar(detalle.turno_id)"
                >
                  <i class="mdi mdi-lead-pencil"></i>
                </button>
              </div>
              <ng-template #f_tiempo>
                <p class="text-center mb-1 " style="font-size: 12px">
                  <strong>{{ detalle.fecha | date: 'dd/MM/yyyy' }} </strong>
                  <i class="mdi mdi-clock-alert" style="font-size: 17px"> </i>
                </p>
                <button
                  *hasPermission="['administrador']"
                  type="button"
                  class="btn btn-sm btn-outline-warning btn-circle m-1"
                  title="editar"
                  (click)="editar(detalle.turno_id)"
                >
                  <i class="mdi mdi-lead-pencil"></i>
                </button>
              </ng-template>
              <p class="text-center float-right float-sm-none mb-1">
                {{ detalle.detalle }}
              </p>
            </div>
          </div>
          <div class="card-body row">
            <div class="col-6 " *ngFor="let categoria of detalle.categorias">
              <div class="card">
                <div class="card-header ">
                  <h5 class="card-title ">{{ categoria.nombre }} </h5>
                </div>
                <div class="card-body">
                  <div class="row" *ngFor="let pregunta of categoria.preguntas">
                    <div class="col col-md-10 col-sm-10">
                      <p class="text-left text-12" [class.text-danger]="pregunta.respuesta == 'NO'">
                        {{ pregunta.pregunta }}
                      </p>
                    </div>
                    <div class="col col-md-2 col-sm-2">
                      <p class="text-right h3 text-12" [class.text-danger]="pregunta.respuesta == 'NO'">
                        {{ pregunta.respuesta }}
                      </p>
                    </div>
                    <div class="col" *ngIf="pregunta.observacion">
                      <p class="text-left text-12 text-danger"><strong>observacion: </strong>
                        {{pregunta.observacion}}</p>
                    </div>
                  </div>
                  <div
                    class="text-uppercase "
                    *ngIf="categoria.puestos.length > 0"
                  >
                    <p class="text-center text-12">
                      <strong>plan de operativos en sucursal</strong>
                    </p>
                    <div
                      class="row text-center"
                      *ngFor="let puestos of categoria.puestos"
                    >
                      <div class="col col-sm-4">
                        <p class="text-center text-12 ">{{ puestos.puesto }}</p>
                      </div>
                      <div class="col col-sm-8">
                        <p class="text-center text-12">
                          {{ puestos.nombre_completo }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="" *ngIf="categoria.productos.length > 0">
                    <div class="row text-capitalize text-center">
                      <div class="col col-sm-6"><strong>Producto</strong></div>
                      <div class="col col-sm-6"><strong>Cantidad</strong></div>
                    </div>
                    <div
                      class="row text-center"
                      *ngFor="let producto of categoria.productos"
                    >
                      <div class="col col-sm-6">
                        <p class="text-center text-12">{{ producto.nombre }}</p>
                      </div>
                      <div class="col col-sm-6">
                        <p class="text-center h3 text-12">
                          {{ producto.cantidad }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr/>
                  <p class="card-text">
                    <strong>Observaciones: </strong> {{ categoria.observacion }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="modal-body">
  <div class="row">
    <div class="col-12 mt-2" *ngFor="let detalle of detalles">
      <div class="card ">
        <div class="card-header bg-danger text-white">
          {{ detalle.titulo }}
        </div>
        <div class="card-body row">
          <div class="col-md-6 " *ngFor="let categoria of detalle.categorias">
            <div class="card">
              <div class="card-header ">
                <h5 class="card-title">{{ categoria.nombre }}</h5>
              </div>
              <div class="card-body">
                <div class="row" *ngFor="let pregunta of categoria.preguntas">
                  <div class="col col-sm-10">
                    <p
                      class="text-left"
                      [class.text-danger]="pregunta.respuesta == 'NO'"
                    >
                      {{ pregunta.pregunta }}
                    </p>
                  </div>
                  <div class="col col-sm-2">
                    <p
                      class="text-right h3"
                      [class.text-danger]="pregunta.respuesta == 'NO'"
                    >
                      {{ pregunta.respuesta }}
                    </p>
                  </div>
                </div>
                <div class="text-uppercase " *ngIf="categoria.puestos.length>0">
                  <p class="text-center"><strong>plan de operativos en sucursal</strong></p>
                  <div class="row text-center"
                       *ngFor="let puestos of categoria.puestos">
                    <div class="col col-sm-4">
                      <p class="text-center ">{{ puestos.puesto }}</p>
                    </div>
                    <div class="col col-sm-8">
                      <p class="text-center ">{{ puestos.nombre_completo }}</p>
                    </div>
                  </div>
                </div>

                <div class="" *ngIf="categoria.productos.length > 0">
                  <div class="row text-capitalize text-center">
                    <div class="col col-sm-6"><strong>Producto</strong></div>
                    <div class="col col-sm-6"><strong>Cantidad</strong></div>
                  </div>
                  <div
                    class="row text-center"
                    *ngFor="let producto of categoria.productos"
                  >
                    <div class="col col-sm-6">
                      <p class="text-center ">{{ producto.nombre }}</p>
                    </div>
                    <div class="col col-sm-6">
                      <p class="text-center h3 ">{{ producto.cantidad }}</p>
                    </div>
                  </div>
                </div>

                <hr />
                <p class="card-text">
                  <strong>Observaciones: </strong> {{ categoria.observacion }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->

<!--<div class="modal-footer">
  <button
    type="button"
    class="btn btn-info"
    (click)="activeModal.dismiss('Cross click')"
  >
    Cerrar
  </button>
</div>-->
