import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { PreguntaService } from '@app/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-infraestructura',
  templateUrl: './infraestructura.component.html',
  styleUrls: ['./infraestructura.component.css']
})
export class InfraestructuraComponent implements OnInit {
  infraForm: FormGroup;
  preguntas: any[];
  @Output() formReady = new EventEmitter<FormGroup>();
  @Input() categoria: any;

  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private preguntasService: PreguntaService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.createForm();

    this.preguntasService.getPreguntaId(12).subscribe(data => {
      this.preguntas = data;
      for (let i = 0; i < data.length; i++) {
        this.infraestructura.push(
          this.crearInfraestructuraFormGruop(data[i].pregunta_id)
        );
      }
      this.infraForm.patchValue({ infraestructura: data });
      if (this.categoria) {
        this.infraForm.patchValue({
          infraestructura: this.categoria.preguntas,
          observacion: this.categoria.observacion,
          categoria_id: this.categoria.categoria_id
        });
      }
    });
    this.formReady.emit(this.infraForm);
  }

  createForm() {
    this.infraForm = this.formBuilder.group({
      infraestructura: this.formBuilder.array([]),
      observacion: null,
      categoria_id: null
    });
  }

  get infraestructura(): FormArray {
    return this.infraForm.get('infraestructura') as FormArray;
  }

  crearInfraestructuraFormGruop(pregunta_id?: string): FormGroup {
    return this.formBuilder.group({
      descripcion: null,
      respuesta: false,
      observacion: null,
      pregunta_id: pregunta_id
    });
  }

  respuesta() {
    // console.log(this.infraForm.get('infraestructura.respuesta'))
    // console.log(<FormGroup>this.infraForm.get('infraestructura'));
    return <FormControl>this.infraForm.get('infraestructura.respuesta');
    // return this.infraForm.get('infra.infraestructura');
  }

  save(form?: any) {
    /*    if (!form.valid)
      return;
    this.formDataService.setPersonal(this.personal);

    let firstState = this.workflowService.getFirstInvalidStep(STEPS.work);
    if (firstState.length > 0) {
    };*/
    this.router.navigateByUrl('/dashboard/informes/apertura/activo', {
      relativeTo: this.route.parent,
      skipLocationChange: true
    });
  }

  mostrarOb(i: any, input: HTMLInputElement) {
    // const input = document.getElementById(i);
    // console.log(input.classList.add(''));
    input.classList.remove('d-none');
  }

  ocultar(i: number, input: HTMLInputElement) {
    input.classList.add('d-none');
  }

  /*  this.router.navigate(['/dashboard/informes/apertura/infraestructura'], {
    skipLocationChange: true
  });*/
}
