<div class="modal-header">
  <h4 class="modal-title">Asignar Sucursal</h4>

  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <ng-multiselect-dropdown
          [placeholder]="'Seleccionar sucursal'"
          [data]="sucursales"
          [(ngModel)]="selectedItems"
          [settings]="dropdownSettings"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
        >
        </ng-multiselect-dropdown>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary waves-effect"
            aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"
          >
            Cerrar
          </button>
          <button
            class="btn btn-success   btn-block text-uppercase waves-effect waves-light"
            (click)="registerSucursal()"
          >
            <app-button-loader
              [isLoading]="isLoading"
              [label]="'Guardar'"
            ></app-button-loader>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
