<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body bg-root">
        <div class="row">
          <div class="col">
            <h3 class="page-title text-white">Lista de Categorias</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div *ngIf="!turnos">
    <app-spinner></app-spinner>
  </div>

  <div class="col col-md-4  col-sm-12 mt-2" *ngFor="let turno of turnos">
    <div class="card  ">
      <div class="card-header bg-root text-white text-center">
        <p>{{ turno.nombre }}</p>
        <!--        <p class=" h3 text-white">{{ turno.hora }}</p>-->
        <input
          [type]="type"
          [formControl]="form"
          [value]="turno.hora"
          title="enter para guardar"
          class="form-control"
          (keydown.enter)="editar(turno.politica_id, $event.target)"
        />
        <!--        <div class="24hr-example">
          &lt;!&ndash;          <input placeholder="24hr format" aria-label="24hr format" [value]="'09:00'"  [ngxTimepicker]="fullTime" [format]="24"  readonly>&ndash;&gt;
          <input
            placeholder="24hr Formato"
            [formControl]="form"
            aria-label="24hr format"
            [value]="turno.hora"
            [ngxTimepicker]="fullTime"
            [format]="24"
            readonly
          />
          <ngx-material-timepicker #fullTime></ngx-material-timepicker>
        </div>-->
        <!--        <div class="24hr-example">-->
        <!--          &lt;!&ndash;          <input placeholder="24hr format" aria-label="24hr format" [value]="'09:00'"  [ngxTimepicker]="fullTime" [format]="24"  readonly>&ndash;&gt;-->
        <!--          <input placeholder="24hr format" aria-label="24hr format" [value]="turno.hora" (hora)="hora" [ngxTimepicker]="fullTime" [format]="24" readonly>-->
        <!--          <ngx-material-timepicker  #fullTime></ngx-material-timepicker>-->
        <!--        </div>-->

        <!--<button
          type="button"
          class="btn btn-sm btn-icon  btn-outline "
          (click)="editar(turno.politica_id)"
        >
          <i class="mdi mdi-clock-end text-white" style="font-size: 30px"></i>
        </button>-->
      </div>

      <div class="card-body border col col-md-12" *ngFor="let categoria of turno.lista_categorias">
        <!--        <h5 class="card-title">{{ categoria.nombre }}</h5>-->
        <p class="card-text"><strong>Categoria: </strong> {{ categoria.nombre }}</p>
      </div>
      <!--      </div>-->
    </div>
  </div>
</div>
