import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PreguntaService } from '@app/core';

@Component({
  selector: 'app-informe-financiero',
  templateUrl: './informe-financiero.component.html',
  styleUrls: ['./informe-financiero.component.css']
})
export class InformeFinancieroComponent implements OnInit {
  informeForm: FormGroup;
  preguntas: any[];
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  @Output() formReady = new EventEmitter<FormGroup>();
  @Input() categoria: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private preguntasService: PreguntaService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.createForm();

    this.preguntasService.getPreguntaId(11).subscribe(data => {
      this.preguntas = data;
      for (let i = 0; i < data.length; i++) {
        this.informeFinanciero.push(
          this.crearActivosFormGruop(data[i].pregunta_id)
        );
      }
      this.informeForm.patchValue({ informeFinanciero: data });
      if (this.categoria) {
        this.informeForm.patchValue({
          informeFinanciero: this.categoria.preguntas,
          observacion: this.categoria.observacion,
          categoria_id: this.categoria.categoria_id
        });
      }
    });
    this.formReady.emit(this.informeForm);
  }
  createForm() {
    this.informeForm = this.formBuilder.group({
      informeFinanciero: this.formBuilder.array([]),
      observacion: null,
      categoria_id: null
    });
  }

  get informeFinanciero(): FormArray {
    return this.informeForm.get('informeFinanciero') as FormArray;
  }

  crearActivosFormGruop(id?: number): FormGroup {
    return this.formBuilder.group({
      descripcion: null,
      respuesta: false,
      observacion: null,
      pregunta_id: id
    });
  }

  mostrarOb(i: any, input: HTMLInputElement) {
    // const input = document.getElementById(i);
    // console.log(input.classList.add(''));
    input.classList.remove('d-none');
  }

  ocultar(i: number, input: HTMLInputElement) {
    input.classList.add('d-none');
  }
}
