<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-lg-4 col-xl-3">
            <h3 class="page-title">Lista de Empleados</h3>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6">
            <ng-multiselect-dropdown
              *hasPermission="['administrador']"
              [placeholder]="'Seleccion un Empleado'"
              [data]="empleados"
              [(ngModel)]="selectedItems"
              [settings]="dropdownSettings"
              (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)"
            >
            </ng-multiselect-dropdown>
          </div>
          <div class="col-md-2 col-lg-2 col-xl-3 text-md-right mt-4 mt-md-0">
            <button
              *hasPermission="['administrador']"
              class="btn btn-md btn-cyan text-white"
              (click)="agregarEmpleado()"
              [disabled]="isLoading"
            >
              <i class="fa fa-plus-square mr-2"></i>
              <app-button-loader [isLoading]="isLoading" [label]="'Agregar Empleado '"></app-button-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <!-- .col -->

  <div *ngFor="let item of empleadosSucursal" class="col-md-3 col-lg-3 col-xlg-3">
    <div class="card">
      <div class="card-body">
        <div class="m-t-30 text-center ">
          <div class=" " *ngIf="item.foto; else icono">
            <img class="rounded-circle" [src]="url + item.foto" width="150" height="150" />
          </div>
          <ng-template class="d-flex align-items-center justify-content-center " #icono>
            <!--            <td><i class="fa {{unidad.icono}}" style="font-size: 30px"></i></td>-->
            <div
              class="round d-flex align-items-center justify-content-center"
              style="width: 150px;height: 150px; font-size: 50px;margin: 20px auto;"
            >
              <span class=""> {{ item.nombres | slice: 0:1 }} {{ item.apellidos | slice: 0:1 }} </span>
            </div>
          </ng-template>
          <h4 class="card-title m-t-10">{{ item.nombres }} {{ item.apellidos }}</h4>
          <div class="row text-center justify-content-md-center">
            <div class="col-4">
              <!--              <a class="link" href="javascript:void(0)"><i class="icon-picture m-r-10"></i> 54 -->
              <!--              </a>-->
              <small>
                <div class="badge badge-pill badge-primary" *ngFor="let rol of item.roles">
                  {{ rol.name }}
                </div>
              </small>
            </div>
            <div class="col-12 text-right">
              <button
                type="button"
                class="btn btn-sm btn-icon btn-pure btn-outline "
                data-toggle="tooltip"
                data-original-title="Eliminar"
                (click)="eliminar(item.empleado_id)"
              >
                <i class="icon-trash  text-danger"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  <div class="card card-body h-80">
      <div class="row">
        <div class="col-md-12 text-center">
          &lt;!&ndash;          <span class="round"> {{ item.nombres | slice: 0:1 }} {{ item.apellidos | slice: 0:1 }} </span>&ndash;&gt;
&lt;!&ndash;          <img [src]="url+item.foto" alt="">&ndash;&gt;
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <h5 class="card-subtitle pt-20"></h5>



          <br/>
          <div class="text-right">

          </div>
        </div>
      </div>
    </div>-->
  </div>

  <!-- /.col -->
</div>
