import { Component, OnInit } from '@angular/core';
import { PreguntaService } from '@app/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CrearPreguntaComponent } from '@app/dashboard/informe/crear-pregunta/crear-pregunta.component';
import { TurnoService } from '@core/service/turno.service';
import { CategoriaService } from '@core/service/categoria.service';
import { Categoria } from '@core/model/categoria.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-lista-pregunta',
  templateUrl: './lista-pregunta.component.html',
  styleUrls: ['./lista-pregunta.component.css']
})
export class ListaPreguntaComponent implements OnInit {
  preguntas: any[];
  modalOptions: NgbModalOptions = {};
  turnos: any[];
  categorias: Categoria[];
  formGroup: FormGroup;

  constructor(
    private preguntasService: PreguntaService,
    private modalService: NgbModal,
    private turnosService: TurnoService,
    private categoriaService: CategoriaService,
    private formBuilder: FormBuilder
  ) {
    this.modalOptions = {
      size: 'lg',
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
    this.createForm();
    this.listarTurnos();
  }

  ngOnInit() {
    this.listarPreguntas();
  }

  listarPreguntas() {
    this.preguntasService.getCustomSelect({}).subscribe(data => {
      // console.log(data);
      this.preguntas = data;
    });
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      politica_id: 0,
      categoria_id: 0
    });
  }

  listarTurnos() {
    this.turnosService.getTurnosHabilitados().subscribe(data => {
      this.turnos = data;
      console.log('politica: ', data);
      // this.formGroup.patchValue({ politica_id: data[0].politica_id });
      // this.listCategorias(data[0].politica_id);
    });
  }

  listCategorias(politica_id: any) {
    this.categoriaService.getPoliticaId(politica_id).subscribe(data => {
      this.categorias = data;
      console.log(data);
      // this.formGroup.patchValue({ categoria_id: data[0].categoria_id });

      this.preguntasService
        .getCustomSelect(this.formGroup.value)
        .subscribe(data2 => {
          // console.log(data2);
          this.preguntas = data2;
        });
    });
  }

  changeCategoria(categoria_id: any) {
    // console.log(this.formGroup.value);
    this.preguntasService
      .getCustomSelect(this.formGroup.value)
      .subscribe(data => {
        // console.log(data);
        this.preguntas = data;
      });
  }

  nuevaPregunta() {
    const modal = this.modalService.open(
      CrearPreguntaComponent,
      this.modalOptions
    );
    modal.componentInstance.title = 'Nueva Pregunta';
    modal.componentInstance.estado = true;
    modal.result.then(res => {
      if (res) {
        this.listarPreguntas();
      }
    });
  }

  editar(pregunta_id: any) {
    const modal = this.modalService.open(
      CrearPreguntaComponent,
      this.modalOptions
    );
    modal.componentInstance.title = 'Editar Pregunta';
    modal.componentInstance.preguntaId = pregunta_id;
    modal.result.then(res => {
      if (res) {
        this.listarPreguntas();
      }
    });
  }

  enable(pregunta_id: any) {
    // console.log(pregunta_id);
    this.preguntasService.enable(pregunta_id).subscribe(
      data => {
        // console.log(data);
        this.listarPreguntas();
      },
      error1 => console.log(error1)
    );
  }
}
