<nav class="sidebar-nav">
  <ul id="sidebarnav">
    <!-- User Profile-->

    <!-- First level menu -->

    <li class="sidebar-item" *hasPermission="['jefe', 'administrador']">
      <div class="nav-small-cap">
        <i class="mdi mdi-dots-horizontal"></i><span class="hide-menu">CONFIGURACION</span>
      </div>
      <a *hasPermission="['administrador']" [routerLink]="['/dashboard/usuarios/lista-usuarios']"
         class="sidebar-link waves-effect waves-dark" routerLinkActive="active"><i class="mdi mdi-account-circle"></i>
        <span class="hide-menu">Usuarios</span>
      </a>
      <a *hasPermission="['administrador']" [routerLink]="['/dashboard/sucursales/empleados']"
         class="sidebar-link waves-effect waves-dark" routerLinkActive="active"><i class="mdi mdi-account-multiple"></i>
        <span class="hide-menu">Empleados</span>
      </a>

      <a *hasPermission="['administrador']" [routerLink]="['/dashboard/sucursales/lista-sucursales']"
         class="sidebar-link waves-effect waves-dark" routerLinkActive="active"><i class="mdi mdi-home-map-marker"></i>
        <span class="hide-menu">Sucursales</span>
      </a>
      <a *hasPermission="['administrador']" [routerLink]="['/dashboard/usuarios/permiso-apertura']"
         class="sidebar-link waves-effect waves-dark" routerLinkActive="active"><i class="mdi mdi-account-key"></i>
        <span class="hide-menu">Permiso de aperturas</span>
      </a>
      <a *hasPermission="['administrador']" [routerLink]="['/dashboard/sucursales/puestos-trabajos']"
         class="sidebar-link waves-effect waves-dark" routerLinkActive="active"><i class="mdi mdi-account-switch"></i>
        <span class="hide-menu">Puestos de Trabajo</span>
      </a>
      <a *hasPermission="['administrador']" [routerLink]="['/dashboard/sucursales/productos']"
         class="sidebar-link waves-effect waves-dark" routerLinkActive="active"><i class="mdi mdi-bowl"></i>
        <span class="hide-menu">Productos</span>
      </a>
      <a *hasPermission="['administrador']" [routerLink]="['/dashboard/informes/categorias']"
         class="sidebar-link waves-effect waves-dark" routerLinkActive="active"><i class="mdi mdi-file-tree"></i>
        <span class="hide-menu">Categorias</span>
      </a>
      <a *hasPermission="['administrador']" [routerLink]="['/dashboard/informes/preguntas']"
         class="sidebar-link waves-effect waves-dark" routerLinkActive="active"><i class="mdi mdi-file-powerpoint-box"></i>
        <span class="hide-menu">Preguntas</span>
      </a>
    </li>
    <li class="sidebar-item" *hasPermission="['jefe', 'supervisor']">
      <div class="nav-small-cap"><i class="mdi mdi-dots-horizontal"></i><span class="hide-menu">Sucursal</span>
      </div>
      <a *hasPermission="['jefe', 'supervisor']" [routerLink]="['/dashboard']"
         class="sidebar-link waves-effect waves-dark" routerLinkActive="active"><i class="mdi mdi-home-map-marker"></i>
        <span class="hide-menu">Sucursales</span>
      </a>
    </li>
    <li class="sidebar-item" *hasPermission="['jefe','administrador']">
      <div class="nav-small-cap"><i class="mdi mdi-dots-horizontal"></i><span class="hide-menu">REPORTES</span>
      </div>
      <a *hasPermission="['jefe','administrador']" [routerLink]="['/dashboard/sucursales/reporte-sucursal']"
         class="sidebar-link waves-effect waves-dark" routerLinkActive="active"><i class="mdi mdi-file-document-box"></i>
        <span class="hide-menu"> Reporte de Sucursales </span>
      </a>
      <a *hasPermission="['jefe','administrador']" [routerLink]="['/dashboard/informes/seguimiento-empleado']"
         class="sidebar-link waves-effect waves-dark" routerLinkActive="active"><i class="mdi mdi-file-document-box"></i>
        <span class="hide-menu">Seguimiento Empleado</span>
      </a>
      <a *hasPermission="['jefe', 'administrador']" [routerLink]="['/dashboard/informes/diario']"
         class="sidebar-link waves-effect waves-dark" routerLinkActive="active"><i class="mdi mdi-file-document-box"></i>
        <span class="hide-menu">Informe del Dia</span>
      </a>
    </li>
  </ul>
</nav>
