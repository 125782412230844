import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import {
  AuthenticationService,
  InformeService,
  SucursalService
} from '@app/core';
import { pipe, Subscription } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmarComponent } from '@app/dashboard/informe/transicion/confirmar/confirmar.component';
import { InformePoliticaService } from '@core/service/informe-politica.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-transicion',
  templateUrl: './transicion.component.html',
  styles: []
})
export class TransicionComponent implements OnInit {
  transicionForm: FormGroup;
  usuarios: any[];
  usuario: any;
  currentUserSubscription: Subscription;
  currentUser: any;
  apellidos: string;
  nombres: string;
  userId: string;
  id: string;
  modalOptions: NgbModalOptions = {};
  isLoading = false;
  informeDetalle: any;
  @Input() informe: any;
  @Input() editEstado: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private informeService: InformeService,
    private sucursalService: SucursalService,
    private authService: AuthenticationService,
    private politicaService: InformePoliticaService,
    private modalService: NgbModal
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    // this.route.snapshot.paramMap.get('idSucursal');
    this.route.snapshot.paramMap.getAll('idSucursal');
    this.route.params.subscribe(params => {
      this.id = params.idSucursal;
      // console.log('params', params);
      this.informeService.getById(params.idInforme).subscribe(data => {
        this.informeDetalle = data;
        // console.log('informedetalle', data);
      });
    });
    // console.log('informedetalle', this.informeDetalle);
    this.currentUser = this.authService.credentials;
    if (this.currentUser) {
      this.apellidos = this.currentUser.user.apellidos;
      this.nombres = this.currentUser.user.nombres;
      this.userId = this.currentUser.user.id;
      this.crearForm();
      if (this.informe) {
        this.transicionForm.patchValue({
          fecha: this.informe.fecha,
          supervisor_id: this.informe.supervisor_id,
          user_id: this.informe.user_id
        });
      }
    }
    // console.log('aqui users ', this.currentUser);
    // this.currentUserSubscription = this.authService.currentUser.subscribe(
    //   user => {
    //     this.currentUser = user;
    //     this.apellidos = this.currentUser.data.user.apellidos;
    //     this.nombres = this.currentUser.data.user.nombres;
    //     this.userId = this.currentUser.data.user.id;
    //     this.crearForm();
    //     if (this.informe) {
    //       this.transicionForm.patchValue({
    //         fecha: this.informe.fecha,
    //         supervisor_id: this.informe.supervisor_id,
    //         user_id: this.informe.user_id
    //       });
    //     }
    //   }
    // );
    // console.log('Informe:', this.informe);
    // console.log('editEstado:', this.editEstado);
    /*    this.sucursalService
      .getUserSucursal({ user_id: this.userId, sucursal_id: this.id })
      .subscribe(data => {
        console.log('usuarios: ', data);
        this.usuarios = data;
      });*/
  }

  crearForm() {
    this.transicionForm = this.fb.group({
      user_id: this.userId,
      sucursal_id: this.id,
      fecha: null,
      turno_id: this.informe ? this.informe.turno_id : null
      // supervisor_id: [null, [Validators.required]]
    });
  }

  formInitialized(name: string, form: FormGroup) {
    this.transicionForm.setControl(name, form);
  }

  getNombreSupervisor() {
    if (this.informe) {
      return this.informe.usuario.nombre_completo;
    }
    return ` ${this.apellidos} ${this.nombres}`;
  }

  save() {
    this.transicionForm.patchValue({
      fecha: this.informeDetalle.fecha
    });

    // console.log(this.transicionForm.value);
    this.isLoading = true;

    const modal = this.modalService.open(ConfirmarComponent, this.modalOptions);
    modal.componentInstance.sucursal_id = this.id
      ? this.id
      : this.informe.sucursal_id; // id sucursal
    // modal.componentInstance.informe_id = this.informeDetalle ? this.informeDetalle.informe_id : this.informe.informe_id; // id informe
    modal.componentInstance.informe_id = this.informe
      ? this.informe.informe_id
      : this.informeDetalle.informe_id; // id informe

    modal.result.then(res => {
      console.log(res);
      if (res === 'cerrar') {
        this.isLoading = false;
        return;
      }
      if (res === true) {
        // console.log(this.transicionForm.value);
        if (this.editEstado) {
          this.informeService
            .editCategoriaTransicion(this.transicionForm.value)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
            .subscribe(
              data => {
                // console.log(data);
                Swal.fire({
                  position: 'top-end',
                  width: '23vh',
                  heightAuto: true,
                  icon: 'success',
                  text: 'Su proceso se realizo con exito.',
                  showConfirmButton: false,
                  timer: 2000
                });
              },
              error1 => {
                this.isLoading = false;
                Swal.fire({
                  position: 'top-end',
                  width: '23vh',
                  heightAuto: true,
                  icon: 'error',
                  text: error1.error,
                  showConfirmButton: false,
                  timer: 2000
                });
                // console.log(error1);
              }
            );
        } else {
          this.politicaService
            .saveInformeTransicion(this.transicionForm.value)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
            .subscribe(
              data => {
                // console.log(data);
                Swal.fire({
                  position: 'top-end',
                  width: '23vh',
                  heightAuto: true,
                  icon: 'success',
                  text: 'Su proceso se realizo con exito.',
                  showConfirmButton: false,
                  timer: 2000
                });
                this.router.navigate([
                  `/dashboard/informes/lista-supercion/${this.id}`
                ]);
              },
              error => {
                this.isLoading = false;
                Swal.fire({
                  position: 'top-end',
                  width: '23vh',
                  heightAuto: true,
                  icon: 'error',
                  text: error.error,
                  showConfirmButton: false,
                  timer: 2000
                });
              }
            );
        }
      }
    });
  }
}
