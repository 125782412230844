import { Component, OnInit } from '@angular/core';
import { InformeService } from '@app/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-diario',
  templateUrl: './lista-diario.component.html',
  styleUrls: ['./lista-diario.component.css']
})
export class ListaDiarioComponent implements OnInit {
  informes: any[];

  constructor(private informeService: InformeService, private router: Router) {
    this.listarInfome();
  }

  ngOnInit() {}

  listarInfome() {
    this.informeService.getInformeDiario().subscribe(data => {
      // console.log(data);
      this.informes = data;
    });
  }

  verInforme(informe_id: any, fecha: any) {
    this.router.navigate(['/dashboard/informes/detalles', informe_id]);
  }

  eliminar(informe_id: any) {
    // elimina paso a paso
    Swal.fire({
      title: 'Esta seguro...?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar'
    }).then(result => {
      if (result.value) {
        this.informeService.deleteInformeStep(informe_id).subscribe(data => {
          this.listarInfome();

          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'error',
            text: 'Usuario o Password incorrecto!',
            showConfirmButton: false,
            timer: 2000
          });
          // console.log(data);
        });
      }
    });
  }

  deleteAll(informe_id: any) {
    Swal.fire({
      title: 'Estas seguro eliminar todo el informe?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, acepto'
    }).then((result: any) => {
      if (result.value) {
        this.informeService.deleteInforme(informe_id).subscribe(data => {
          // console.log(data);
          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'success',
            text: data.success,
            showConfirmButton: false,
            timer: 2000
          });
        });
      }
    });
  }
}
