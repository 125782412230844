<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 ">
      <div class="card">
        <div class="card-header text-center bg-root">
          <p class="h4 text-white float-left">Lista de Empleados</p>
          <button (click)="createEmpleado()" class="btn  btn-success float-right">
            Nuevo Empleado
          </button>
        </div>
        <div class="card-body">
          <input class="form-control" #searchTerm (keyup)="search(searchTerm.value)" placeholder="Buscar empleados..." />
          <div *ngIf="empleados && !empleadoService.isLoading" class="row">
            <div class="col-md-12">
              <table class="table table-responsive-sm table-hover table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Foto</th>
                    <th>Nombre Completo</th>
                    <th>CI</th>
                    <th>Celular</th>
                    <th>Activo</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody *ngFor="let empleado of empleados.data; let i = index">
                  <tr>
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      <img height="50" width="50" [src]="imageURL + empleado.foto" alt="" />
                    </td>
                    <td>{{ empleado.nombres + ' ' + empleado.apellidos }}</td>
                    <td>{{ empleado.ci }}</td>
                    <td>{{ empleado.celular }}</td>
                    <td>
                      <label class="switch switch_type1" role="switch" *ngIf="empleado.activo == true">
                        <input type="checkbox" class="switch__toggle" (click)="enable(empleado.empleado_id)" checked="false" />
                        <span class="switch__label"></span>
                      </label>
                      <label class="switch switch_type1" role="switch" *ngIf="empleado.activo == false">
                        <input type="checkbox" class="switch__toggle" (click)="enable(empleado.empleado_id)" />
                        <span class="switch__label"></span>
                      </label>
                    </td>

                    <td>
                      <button type="button" class="btn btn-sm btn-icon  btn-outline " (click)="editModal(empleado.empleado_id)">
                        <i class="fas fa-pencil-alt text-warning " style="font-size: 20px"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                Mostrando empleados {{ empleados.from }} a {{ empleados.to }} de
                {{ empleados.total }}
              </p>
              <p>Página {{ empleados.current_page }} de {{ empleados.last_page }}</p>
              <button (click)="prevPage()" [disabled]="!empleados.prev_page_url" >
                Anterior
              </button>
              <button (click)="nextPage()" [disabled]="!empleados.next_page_url">
                Siguiente
              </button>
            </div>
          </div>
          <p *ngIf="empleadoService.isLoading">Cargando...</p>
        </div>
      </div>
    </div>
  </div>
</div>
