import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { PreguntaService } from '@app/core';
import { ThemePalette } from '@angular/material';

@Component({
  selector: 'app-activo',
  templateUrl: './activo.component.html',
  styleUrls: ['./activo.component.css']
})
export class ActivoComponent implements OnInit {
  activoForm: FormGroup;
  preguntas: any[];
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  @Output() formReady = new EventEmitter<FormGroup>();
  @Input() categoria: any;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private preguntasService: PreguntaService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.createForm();

    this.preguntasService.getPreguntaId(2).subscribe(data => {
      this.preguntas = data;
      for (let i = 0; i < data.length; i++) {
        this.activos.push(this.crearActivosFormGruop(data[i].pregunta_id));
      }
      this.activoForm.patchValue({ activos: data });
      if (this.categoria) {
        this.activoForm.patchValue({
          activos: this.categoria.preguntas,
          observacion: this.categoria.observacion,
          categoria_id: this.categoria.categoria_id
        });
      }
    });
    this.formReady.emit(this.activoForm);
  }

  createForm() {
    this.activoForm = this.formBuilder.group({
      activos: this.formBuilder.array([]),
      observacion: null,
      categoria_id: null
    });
  }

  get activos(): FormArray {
    return this.activoForm.get('activos') as FormArray;
  }

  crearActivosFormGruop(id?: number): FormGroup {
    return this.formBuilder.group({
      descripcion: null,
      respuesta: false,
      observacion: null,
      pregunta_id: id
    });
  }
  get observacion() {
    // return this.activoForm.get('activos') ;
    // return this.activoForm.get('activos.observacion') as FormControl;
    // return  this.activoForm.get(['address', 'street']);
    // return  this.activoForm.get(['activos', 'observacion1']);
    // return  this.activos.controls[2].get('observacion1');
    // return  this.activos.controls['2' , 'observacion1'].value;
    return;
  }
  save(form?: any) {
    /*    if (!form.valid)
          return;
        this.formDataService.setPersonal(this.personal);

        let firstState = this.workflowService.getFirstInvalidStep(STEPS.work);
        if (firstState.length > 0) {
        };*/
    this.router.navigateByUrl('/dashboard/informes/apertura/ambiente', {
      relativeTo: this.route.parent,
      skipLocationChange: true
    });
  }

  mostrarOb(i: any, input: HTMLInputElement) {
    // const input = document.getElementById(i);
    // console.log(input.classList.add(''));
    input.classList.remove('d-none');
  }

  ocultar(i: number, input: HTMLInputElement) {
    input.classList.add('d-none');
  }
}
