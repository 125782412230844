<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form action="" [formGroup]="preguntaForm" (ngSubmit)="register(preguntaForm.value)" class="form-horizontal form-material" id="userform" novalidate>
  <div class="modal-body">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="form-row ">
            <div class="form-group  col-md-12 col-sm-12">
              <app-form-error-wrapper [control]="preguntaForm.controls['descripcion']" [controlName]="'pregunta'">
                <label for="pregunta"> Pregunta</label>
                <input class="form-control" type="text" formControlName="descripcion" id="pregunta" placeholder="" />
              </app-form-error-wrapper>
            </div>
          </div>
          <div class="form-row" *ngIf="estado">
            <div class="form-group  col-md-6 col-sm-12">
              <app-form-error-wrapper [control]="preguntaForm.controls['politica_id']" [controlName]="'Puesto'">
                <label for="puesto">Politica</label>
                <select id="puesto" formControlName="politica_id" class="form-control" (change)="listCategorias(preguntaForm.value.politica_id)">
                  <option *ngFor="let puesto of turnos" value="{{ puesto.politica_id }}">
                    {{ puesto.nombre }}
                  </option>
                </select>
              </app-form-error-wrapper>
            </div>
            <div class="form-group  col-md-6 col-sm-12">
              <app-form-error-wrapper [control]="preguntaForm.controls['categoria_id']" [controlName]="'Categoria'">
                <label for="categoria_id"> Categoria</label>
                <select formControlName="categoria_id" id="categoria_id" class="form-control">
                  <option *ngFor="let categoria of categorias" value="{{ categoria.categoria_id }}">
                    {{ categoria.nombre }}
                  </option>
                </select>
              </app-form-error-wrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary waves-effect w-30" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      Cerrar
    </button>
    <button class="btn btn-success   btn-block text-uppercase waves-effect waves-light w-70" [disabled]="preguntaForm.invalid || isLoading">
      <app-button-loader [isLoading]="isLoading" [label]="'Guardar'"></app-button-loader>
    </button>
  </div>
</form>
