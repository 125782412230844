import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, UserService } from '@app/core';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.css']
})
export class ConfirmarComponent implements OnInit {
  confirmacionForm: FormGroup;
  isLoading = false;
  @Input() sucursal_id: any;
  @Input() informe_id: any;
  usuario: any;
  userId: string;
  currentUserSubscription: Subscription;
  currentUser: any;
  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private authService: AuthenticationService
  ) {
    // this.isLoading = false;
  }

  ngOnInit() {
    this.currentUser = this.authService.credentials;
    if (this.currentUser) {
      this.userId = this.currentUser.user.id;
      this.createForm();
    }
    // console.log('aqui users ', this.currentUser);
    // this.currentUserSubscription = this.authService.currentUser.subscribe(
    //   user => {
    //     this.currentUser = user;
    //
    //     this.userId = this.currentUser.data.user.id;
    //     this.createForm();
    //   }
    // );

    this.confirmacionForm.patchValue({
      sucursal_id: this.sucursal_id,
      informe_id: this.informe_id
    });
    // console.log(this.sucursal_id);
    // console.log(this.informe_id);
    // console.log(this.confirmacionForm.value);
  }

  createForm() {
    this.confirmacionForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required],
      sucursal_id: null,
      informe_id: null,
      user_id: this.userId
    });
  }

  register(value?: any) {
    // console.log(value);

    this.isLoading = true;
    this.userService
      .getCompareUser(this.confirmacionForm.value)
      .pipe(
        finalize(() => {
          this.confirmacionForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        data => {
          if (data) {
            this.activeModal.close(data);
          } else {
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'error',
              text: 'Usuario o Password incorrecto!',
              showConfirmButton: false,
              timer: 1000
            });
          }
          // console.log(data);
        },
        (error: any) => {
          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'error',
            text: error.error,
            showConfirmButton: false,
            timer: 2000
          });
          // console.log(`Login error: ${error}`);
        }
      );
  }
}
