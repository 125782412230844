import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment.prod';
import { Paginated } from '../model/paginated.model';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoService extends BaseAPIClass {
  baseUrl = environment.serverBaseUrl;
  public isLoading = false;
  private clientesSearchUrl = this.baseUrl + '/empleados?term=';

  constructor(protected httpClient: HttpClient, handler: HttpBackend) {
    super(httpClient);
    this.httpClient = new HttpClient(handler);
    this.baseUrl = this.baseUrl + '/empleados';
  }
  getEmpleadoSucursalId(sucursalId: number): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseUrl + `/sucursal/${sucursalId}`);
  }

  getEmpleadosDisponibles(sucursalId: number): Observable<any[]> {
    return this.httpClient.get<any[]>(
      this.baseUrl + `/disponibles/${sucursalId}`
    );
  }

  getEmpleados(): Promise<Paginated> {
    this.isLoading = true;
    return this.httpClient
      .get(this.baseUrl)
      .toPromise()
      .then(response => {
        // console.log(response);
        this.isLoading = false;
        return response as Paginated;
      })
      .catch(this.handleError);
  }
  getEmpleadosAtUrl(url: string): Promise<Paginated> {
    this.isLoading = true;
    return this.httpClient
      .get(url)
      .toPromise()
      .then(response => {
        this.isLoading = false;
        return response as Paginated;
      })
      .catch(this.handleError);
  }
  updateEmpleado(id: string, object: any) {
    return this.httpClient.post(this.baseUrl + '/' + id, object);
  }

  search(term: string): Promise<Paginated> {
    this.isLoading = true;
    return this.httpClient
      .get(`${this.clientesSearchUrl}${term}`)
      .toPromise()
      .then(response => {
        this.isLoading = false;
        return response as Paginated;
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    // this.isLoading = false;
    return Promise.reject(error.message || error);
  }

  enable(id: any) {
    return this.httpClient.get(this.baseUrl + '/habilitar/' + id);
  }
  getAllEmpleados(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseUrl + '/list');
  }
}
