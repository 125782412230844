import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PreguntaService } from '@app/core';
import { CategoriaService } from '@core/service/categoria.service';
import { Categoria } from '@core/model/categoria.model';
import { TurnoService } from '@core/service/turno.service';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-crear-pregunta',
  templateUrl: './crear-pregunta.component.html',
  styleUrls: ['./crear-pregunta.component.css']
})
export class CrearPreguntaComponent implements OnInit {
  @Input() title: string;
  @Input() preguntaId: string;
  @Input() estado: boolean;
  preguntaForm: FormGroup;
  isLoading: boolean;
  categorias: Categoria[];
  turnos: any[];

  constructor(
    private formBuilder: FormBuilder,
    private preguntaService: PreguntaService,
    private categoriaService: CategoriaService,
    private turnosService: TurnoService,
    public activeModal: NgbActiveModal
  ) {
    this.estado = false;
    this.isLoading = false;
    this.listarTurnos();
    // this.listCategorias();
  }

  ngOnInit() {
    this.createForm();
    if (this.preguntaId) {
      this.preguntaService.getById(this.preguntaId).subscribe(data => {
        this.preguntaForm.patchValue(data);
      });
    }
  }

  listCategorias(politica_id: any) {
    this.categoriaService.getPoliticaId(politica_id).subscribe(data => {
      this.categorias = data;
      this.preguntaForm.patchValue({ categoria_id: data[0].categoria_id });
    });
  }

  listarTurnos() {
    this.turnosService.getTurnosHabilitados().subscribe(data => {
      this.turnos = data;
      // console.log('turnos: ',data);
      this.preguntaForm.patchValue({ politica_id: data[0].politica_id });
      this.listCategorias(data[0].politica_id);
    });
  }

  createForm() {
    this.preguntaForm = this.formBuilder.group({
      politica_id: null,
      descripcion: ['', [Validators.required]],
      categoria_id: [null, [Validators.required]]
    });
  }

  get turno() {
    return this.preguntaForm.get('politica_id');
  }

  register(value: any) {
    this.isLoading = true;
    if (this.estado) {
      // console.log('form: ', this.preguntaForm.value)
      this.preguntaService
        .create(value)
        .pipe(
          finalize(() => {
            this.preguntaForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          (data: any) => {
            // console.log(data);
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'success',
              text: data.success,
              showConfirmButton: false,
              timer: 2000
            });
            this.activeModal.close(data);
          },
          error1 => {
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'error',
              text: error1.error,
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
    } else {
      this.preguntaService
        .update(this.preguntaId, value)
        .pipe(
          finalize(() => {
            this.preguntaForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          data => {
            // console.log(data);
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'success',
              text: data.success,
              showConfirmButton: false,
              timer: 2000
            });
            this.activeModal.close(data);
          },
          error1 => {
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'error',
              text: error1.error,
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
    }
  }
}
