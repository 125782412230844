import { Component, OnInit } from '@angular/core';
import { Paginated } from '@app/core/model/paginated.model';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Empleado } from '@app/core/model/empleado.model';
import { environment } from '@env/environment.prod';
import { Subject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { EmpleadoService } from '@app/core/service/empleado.service';
import { CreateEmpleadoComponent } from './create-empleado/create-empleado.component';

@Component({
  selector: 'app-empleados',
  templateUrl: './empleados.component.html',
  styleUrls: ['./empleados.component.css']
})
export class EmpleadosComponent implements OnInit {
  empleados: Paginated;
  modalOptions: NgbModalOptions = {};
  imageURL = environment.imgUrl;

  pager = {};
  pageOfItems: any = [];

  clientes: Paginated;
  private searchTermStream = new Subject<string>();
  currentSearchTerm: string = null;

  constructor(
    private formBuilder: FormBuilder,
    public empleadoService: EmpleadoService,
    private modalService: NgbModal
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      size: 'lg'
    };
  }

  ngOnInit() {
    // this.createForm();
    // this.listClient();

    this.empleadoService
      .getEmpleados()
      .then(empleados => (this.empleados = empleados));

    this.searchTermStream.subscribe((term: string) => {
      this.empleadoService.search(term).then((empleados: any) => {
        // console.log('serviciobusqueda:',empleados);
        this.currentSearchTerm = term;
        this.updateEmpleados(empleados);
      });
    });
  }

  updateEmpleados(empleados: Paginated) {
    // console.log('update', empleados);
    const empleadosWithUpdatedNavs = this.updateNavUrls(
      this.currentSearchTerm,
      empleados
    );
    this.empleados = empleadosWithUpdatedNavs;
  }

  updateNavUrls(term: string, empleados: Paginated): Paginated {
    if (term) {
      if (empleados.prev_page_url) {
        empleados.prev_page_url += `&term=${term}`;
      }
      if (empleados.next_page_url) {
        empleados.next_page_url += `&term=${term}`;
      }
    }
    return empleados;
  }

  prevPage() {
    this.empleadoService
      .getEmpleadosAtUrl(this.empleados.prev_page_url)
      .then((empleados: any) => {
        this.updateEmpleados(empleados);
      });
  }

  nextPage() {
    this.empleadoService
      .getEmpleadosAtUrl(this.empleados.next_page_url)
      .then((empleados: any) => {
        this.updateEmpleados(empleados);
      });
  }

  search(term: string) {
    this.searchTermStream.next(term);
  }

  /*  createForm() {
      this.clientForm = this.formBuilder.group({
        nombres: ['', [Validators.required]],
        apellidos: ['', [Validators.required]],
        ci_nit: ['', [Validators.required]],
        direccion: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        // latitud: ['', [Validators.required]],
        // longitud: ['', [Validators.required]],
        foto: ['', [Validators.required]],
        celular: ['', [Validators.required]]
      });
    }*/
  listClient() {
    this.empleadoService.getAll().subscribe(
      data => {
        console.log('empleados ', data);
        this.empleados = data;
      },
      error => {
        console.log(error.error);
      }
    );
  }

  /*registerClient() {
    const newClient: Client = new Client();
    newClient.nombres = this.clientForm.value.nombres;
    newClient.apellidos = this.clientForm.value.apellidos;
    newClient.ci_nit = this.clientForm.value.ci_nit;
    newClient.direccion = this.clientForm.value.direccion;
    newClient.email = this.clientForm.value.email;
    newClient.foto = this.clientForm.value.foto;
    newClient.celular = this.clientForm.value.celular;
    // newClient.latitud=this.clientForm.value.latitud;
    // newClient.longitud=this.clientForm.value.longitud;
    newClient.email = this.clientForm.value.email;

    this.clientService.create(newClient).subscribe(
      data => {
        Swal.fire(data);
        this.clientForm.reset();
        this.listClient();
      },
      error => {
        Swal.fire(error.error);
      }
    );
  }*/

  /* enableClient(id: number) {
    this.clientService.enableClient(id).subscribe(
      data => {
        this.listClient();
      },
      error => {
        console.log(error.error);
      }
    );
  }*/
  createEmpleado() {
    const modalRef = this.modalService.open(
      CreateEmpleadoComponent,
      this.modalOptions
    );
    modalRef.componentInstance.title = 'Crear nuevo empleado';
    modalRef.result.then(result => {
      if (result) {
        this.empleadoService
          .getEmpleados()
          .then(empleados => (this.empleados = empleados));
        this.searchTermStream.subscribe((term: string) => {
          this.empleadoService.search(term).then((empleados: any) => {
            this.currentSearchTerm = term;

            this.updateEmpleados(empleados);
          });
        });
      }
    });
  }

  editModal(id: string) {
    // alert(id);
    const modal = this.modalService.open(
      CreateEmpleadoComponent,
      this.modalOptions
    );
    modal.componentInstance.empleado_id = id;
    modal.componentInstance.estado = true;
    modal.componentInstance.title = 'Editar Empleado';
    modal.result.then(result => {
      if (result) {
        this.empleadoService
          .getEmpleados()
          .then(empleados => (this.empleados = empleados));
        this.searchTermStream.subscribe((term: string) => {
          this.empleadoService.search(term).then((empleados: any) => {
            this.currentSearchTerm = term;
            this.updateEmpleados(empleados);
          });
        });
      }
    });

    // modal.close();
  }

  enable(id: any) {
    this.empleadoService.enable(id).subscribe(data => {
      // console.log(data);
      this.listClient(); // todo temporal
    });
  }
}
