import { Component, OnInit } from '@angular/core';
import { SucursalService } from '@core/service/sucursal.service';
import { LocalStorageService } from '@app/core';

@Component({
  selector: 'app-lista-informes',
  templateUrl: './lista-informes.component.html',
  styles: []
})
export class ListaInformesComponent implements OnInit {
  sucursales: any[];
  credencial: any;
  usuario: any;

  constructor(
    private sucursalService: SucursalService,
    public localSorage: LocalStorageService
  ) {
    this.credencial = JSON.parse(this.localSorage.getItem('credentials'));
    this.usuario = this.credencial.data.user;
  }

  ngOnInit() {
    this.listarSucursal();
  }

  listarSucursal() {
    this.sucursalService.getSucrsalesUser(this.usuario.id).subscribe(data => {
      // console.log(data);
      this.sucursales = data;
    });
  }
}
