import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PreguntaService } from '@app/core';
import { ProductoService } from '@core/service/producto.service';

@Component({
  selector: 'app-producto-insumo',
  templateUrl: './producto-insumo.component.html',
  styleUrls: ['./producto-insumo.component.css']
})
export class ProductoInsumoComponent implements OnInit {
  prodcInsumoForm: FormGroup;
  preguntas: any[];
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  preguntaProductos: any[];
  @Output() formReady = new EventEmitter<FormGroup>();
  @Input() categoria: any;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private productoService: ProductoService,

    private preguntasService: PreguntaService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.createForm();
    this.productoService.getProductosHabilitados().subscribe(data => {
      this.preguntaProductos = data;
      // console.log('preguntaProductos', data);
      for (let i = 0; i < data.length; i++) {
        this.productosInsumos.push(this.crearProductosInsumoFormGruop());
      }
      this.prodcInsumoForm.patchValue({ productosInsumos: data });
      if (this.categoria) {
        this.prodcInsumoForm.patchValue({
          productosInsumos: this.categoria.productos
        });
      }
    });
    this.preguntasService.getPreguntaId(5).subscribe(data => {
      // console.log(data);
      this.preguntas = data;
      for (let i = 0; i < data.length; i++) {
        this.productosInsumosRespuesta.push(this.crearProductosFormGruop());
      }
      this.prodcInsumoForm.patchValue({ productosInsumosRespuesta: data });
      if (this.categoria) {
        this.prodcInsumoForm.patchValue({
          productosInsumosRespuesta: this.categoria.preguntas,
          observacion: this.categoria.observacion,
          categoria_id: this.categoria.categoria_id
        });
      }
    });
    this.formReady.emit(this.prodcInsumoForm);
  }

  createForm() {
    this.prodcInsumoForm = this.formBuilder.group({
      productosInsumos: this.formBuilder.array([]),
      productosInsumosRespuesta: this.formBuilder.array([]),
      observacion: null,
      categoria_id: null
    });
  }
  get productosInsumos(): FormArray {
    return this.prodcInsumoForm.get('productosInsumos') as FormArray;
  }
  get productosInsumosRespuesta(): FormArray {
    return this.prodcInsumoForm.get('productosInsumosRespuesta') as FormArray;
  }
  crearProductosFormGruop(id?: number): FormGroup {
    return this.formBuilder.group({
      descripcion: null,
      respuesta: null,
      observacion: null,
      pregunta_id: id
    });
  }

  crearProductosInsumoFormGruop(id?: number): FormGroup {
    return this.formBuilder.group({
      nombre: null,
      cantidad: [0, [Validators.required, Validators.min(0)]],
      producto_id: id
    });
  }

  mostrarOb(i: any, input: HTMLInputElement) {
    // const input = document.getElementById(i);
    // console.log(input.classList.add(''));
    input.classList.remove('d-none');
  }

  ocultar(i: number, input: HTMLInputElement) {
    input.classList.add('d-none');
  }
}
