import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PreguntaService } from '@app/core';

@Component({
  selector: 'app-saldo-productos',
  templateUrl: './saldo-productos.component.html',
  styleUrls: ['./saldo-productos.component.css']
})
export class SaldoProductosComponent implements OnInit {
  saldoForm: FormGroup;
  preguntas: any[];
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  @Output() formReady = new EventEmitter<FormGroup>();
  @Input() categoria: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private preguntasService: PreguntaService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.createForm();
    this.preguntasService.getPreguntaId(9).subscribe(data => {
      this.preguntas = data;
      for (let i = 0; i < data.length; i++) {
        this.saldoProducto.push(
          this.crearSaldoProductoFormGruop(data[i].pregunta_id)
        );
      }
      this.saldoForm.patchValue({ saldoProductos: data });
      if (this.categoria) {
        // console.log('Categorias ', this.categoria);
        this.saldoForm.patchValue({ saldoProductos: this.categoria.preguntas });
        this.saldoForm.patchValue({
          saldoProductos: this.categoria.preguntas,
          observacion: this.categoria.observacion,
          categoria_id: this.categoria.categoria_id
        });
      }
    });
    this.formReady.emit(this.saldoForm);
  }
  createForm() {
    this.saldoForm = this.formBuilder.group({
      saldoProductos: this.formBuilder.array([]),
      observacion: null,
      categoria_id: null
    });
  }
  get saldoProducto(): FormArray {
    return this.saldoForm.get('saldoProductos') as FormArray;
  }

  crearSaldoProductoFormGruop(pregunta_id?: number) {
    return this.formBuilder.group({
      descripcion: null,
      respuesta: false,
      observacion: null,
      pregunta_id: pregunta_id
    });
  }

  mostrarOb(i: any, input: HTMLInputElement) {
    // const input = document.getElementById(i);
    // console.log(input.classList.add(''));
    input.classList.remove('d-none');
  }

  ocultar(i: number, input: HTMLInputElement) {
    input.classList.add('d-none');
  }
}
