import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AuthenticationService,
  InformeService,
  SucursalService
} from '@app/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { InformePoliticaService } from '@core/service/informe-politica.service';

@Component({
  selector: 'app-apertura',
  templateUrl: './apertura.component.html',
  styles: []
})
export class AperturaComponent implements OnInit {
  aperturaForm: FormGroup;
  @Input() turno_id: any;
  currentUserSubscription: Subscription;
  currentUser: any;
  apellidos: string;
  nombres: string;
  userId: string;
  id: string;
  usuarios: any[];
  isLoading = false;
  date: Date = new Date();
  @Input() informe: any;
  @Input() editEstado: boolean;
  fecha: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private informeService: InformeService,
    private authService: AuthenticationService,
    private sucursalService: SucursalService,
    private politicaService: InformePoliticaService
  ) {
    this.fecha = moment().format('YYYY-MM-DD');
  }

  ngOnInit() {
    // console.log('turno id Edit', this.turno_id);
    if (this.informe) {
      // console.log('turno id', this.informe);
      this.id = this.informe.sucursal_id;
      this.listarEmpleados();
    }

    this.route.snapshot.paramMap.get('idSucursal');
    this.route.params.subscribe(params => {
      this.id = params.idSucursal;
    });
    // console.log(this.id);
    this.currentUser = this.authService.credentials;
    // console.log('aqui users ', this.currentUser);
    if (this.currentUser) {
      this.apellidos = this.currentUser.user.apellidos;
      this.nombres = this.currentUser.user.nombres;
      this.userId = this.currentUser.user.id;
    }
    // this.currentUserSubscription = this.authService.credentials.user
    //   user => {
    // this.currentUser = this.authService.credentials;
    // console.log('aqui users ', this.currentUser);
    // this.apellidos = this.currentUser.data.user.apellidos;
    // this.nombres = this.currentUser.data.user.nombres;
    // this.userId = this.currentUser.data.user.id;
    // }
    // );

    this.aperturaForm = this.fb.group({
      user_id: this.userId,
      sucursal_id: this.id ? this.id : this.informe.sucursal_id,
      fecha: ['', [Validators.required]],
      supervisor_id: [null, [Validators.required]],
      turno_id: this.informe ? this.informe.turno_id : null
    });
    // console.log('sucursal_id', this.id);
    // console.log('editEstado', this.editEstado);

    this.listarEmpleados();
    this.aperturaForm.patchValue({ fecha: this.fecha });
    if (this.informe) {
      this.aperturaForm.patchValue({
        fecha: this.informe.fecha,
        supervisor_id: this.informe.supervisor_id,
        user_id: this.informe.user_id
      });
    }
  }

  formInitialized(name: string, form: FormGroup) {
    this.aperturaForm.setControl(name, form);
  }

  getNombreSupervisor() {
    if (this.informe) {
      return this.informe.usuario.nombre_completo;
    }
    return ` ${this.apellidos} ${this.nombres}`;
  }

  listarEmpleados() {
    // lista de usuarios a tranferir
    this.sucursalService
      .getUserSucursal({
        sucursal_id: this.id ? this.id : this.informe.sucursal_id
      })
      .subscribe(data => {
        console.log('usuarios: ', data);
        this.usuarios = data;
      });
  }

  save() {
    // console.log(this.aperturaForm.value);
    this.isLoading = true;
    if (this.editEstado) {
      this.informeService
        .editCategoriaApertura(this.aperturaForm.value)
        .pipe(
          finalize(() => {
            this.aperturaForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          data => {
            // console.log(data);
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'success',
              showConfirmButton: false,
              text: data.success,
              timer: 2000
            });
          },
          error1 => {
            this.isLoading = false;
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'error',
              text: error1.error,
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
    } else {
      this.politicaService
        .saveInforme(this.aperturaForm.value)
        .pipe(
          finalize(() => {
            this.aperturaForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          (data1: any) => {
            // console.log(data1);
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'success',
              showConfirmButton: false,
              text: data1.success,
              timer: 2000
            });
            this.router.navigate([
              `/dashboard/informes/lista-supercion/${this.id}`
            ]);
          },
          error1 => {
            this.isLoading = false;
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'error',
              text: error1.error,
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
    }
  }
}
