import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MultimediaService } from '@app/core/service/multimedia.service';
@Component({
  selector: 'app-fotos',
  templateUrl: './fotos.component.html',
  styleUrls: ['./fotos.component.css']
})
export class FotosComponent implements OnInit {
  images: any = [];

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  imagenURL: any = {};
  progress = 0;
  isLoading = false;
  @Input() title: string;
  @Input() id: string;

  myForm = new FormGroup({
    turno_id: new FormControl(''),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  @Output() formReady = new EventEmitter<FormGroup>();

  constructor(
    public activeModal: NgbActiveModal,
    private multimediaService: MultimediaService
  ) {}
  ngOnInit() {
    this.formReady.emit(this.myForm);
  }

  get f() {
    return this.myForm.controls;
  }

  onFileChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();

        reader.onload = (event2: any) => {
          // console.log('imagen???', event2.target.result);
          this.images.push(event2.target.result);

          this.myForm.patchValue({
            turno_id: this.id,
            fileSource: this.images
          });
        };

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  deleteUser(index: number) {
    this.images.splice(index, 1);
  }

  submit() {
    // console.log(this.myForm.value);
    this.isLoading = true;
    this.multimediaService
      .saveFotosInforme(this.myForm.value)
      .pipe(
        finalize(() => {
          this.myForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data1: any) => {
          // console.log(data1);
          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'success',
            showConfirmButton: false,
            text: data1.success,
            timer: 2000
          });
          //   this.router.navigate(['/dashboard']);
        },
        error1 => {
          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'error',
            text: error1.error,
            showConfirmButton: false,
            timer: 2000
          });
        }
      );
  }
}
