<div class="form-group text-center">
  <!--  <button type="button" uiSref="work" class="btn btn-success btn-raised" [disabled]="!personalForm.valid" (click)="save(personalForm)"> Next </button>-->
  <div class="card" [formGroup]="saldoForm">
    <div class="card-body">
      <h4 class="card-title">Activos</h4>
      <div formArrayName="saldoProductos">
        <div class="form-row"
             *ngFor="let detalle of saldoProducto.controls; let i = index" [formGroupName]="i">
          <div class="col-12">
            <div class="row">
              <div class="col-6 ">
                <p class="text-right">   {{ detalle.get('descripcion').value }}</p>
              </div>
              <div class="col-6">
                <div class="d-flex  justify-content-around">
                    <app-switch-control
                      formControlName="respuesta"
                    ></app-switch-control>
                    <button
                      type="button"
                      class="btn btn-sm btn-icon btn-pure btn-outline "
                      (click)="mostrarOb(i, input)" (dblclick)="ocultar(i,input)"
                    >
                      <i class="mdi mdi-tooltip-outline-plus h3"></i>
                    </button>
                  </div>
                  <input
                    type="text"
                    class="form-control d-none"
                    id="{{ i }}"
                    #input
                    formControlName="observacion" placeholder="Observación por pregunta"/>
                </div>
            </div>
          </div>
          <!--          <p class="col col-xs-8 col-md-9 text-sm-left text-md-center">-->

          <!--          </p>-->
          <!--          <div class="col col-xs-4 col-md-3 text-xl-left">
                      <div class="d-flex  justify-content-around">
                        <app-switch-control
                          formControlName="respuesta"
                        ></app-switch-control>
                        <button
                          type="button"
                          class="btn btn-sm btn-icon btn-pure btn-outline "
                          (click)="mostrarOb(i)"
                        >
                          <i class="mdi mdi-tooltip-outline-plus"></i>
                        </button>
                      </div>
                      <input
                        type="text"
                        class="form-control d-none"
                        id="{{ i }}"
                        formControlName="observacion"
                      />
                    </div>-->
        </div>
      </div>
      <div class="col-12">
        <label for="observacion">Observacion</label>
        <input
          type="text"
          class="form-control"
          id="observacion"
          formControlName="observacion"
          placeholder="Observación general"
        />
      </div>
    </div>
  </div>
  <!--  <button
    type="button"
    uiSref="work"
    class="btn btn-success btn-raised"
    (click)="save()"
  >
    Siguiente
  </button>-->
</div>
