import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InformeRoutingModule } from './informe-routing.module';
import { ListaInformesComponent } from './lista-informes/lista-informes.component';
import { InformeComponent } from '@app/dashboard/informe/informe.component';
import { ListaSupervicionComponent } from './lista-supervicion/lista-supervicion.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetalleComponent } from './detalle/detalle.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ListaCategoriaComponent } from './lista-categoria/lista-categoria.component';
import { ListaPreguntaComponent } from './lista-pregunta/lista-pregunta.component';
import { CrearPreguntaComponent } from './crear-pregunta/crear-pregunta.component';
import { SharedModule } from '@app/shared';
import { ListaDiarioComponent } from './lista-diario/lista-diario.component';
import { FotosComponent } from './fotos/fotos.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ReporteEmpleadosComponent } from './reporte-empleados/reporte-empleados.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { GaleriaComponent } from './galeria/galeria.component';
import { ArchivosComponent } from './archivos/archivos.component';
import { EditDetalleComponent } from './edit-detalle/edit-detalle.component';
import { AperturaModule } from '@app/dashboard/informe/apertura/apertura.module';
import { TransicionModule } from '@app/dashboard/informe/transicion/transicion.module';
import { CierreModule } from '@app/dashboard/informe/cierre/cierre.module';
import { CarouselComponent } from '@shared/carousel/carousel.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    InformeComponent,
    ListaInformesComponent,
    ListaSupervicionComponent,
    DetalleComponent,
    ListaCategoriaComponent,
    ListaPreguntaComponent,
    CrearPreguntaComponent,
    ListaDiarioComponent,
    ReporteEmpleadosComponent,
    GaleriaComponent,
    FotosComponent,
    ArchivosComponent,
    EditDetalleComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    InformeRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    AperturaModule,
    TransicionModule,
    CierreModule,
    InfiniteScrollModule
  ],
  exports: [InformeComponent],
  entryComponents: [
    // DetalleComponent,
    CrearPreguntaComponent,
    FotosComponent,
    ArchivosComponent,
    EditDetalleComponent,
    CarouselComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InformeModule {}
