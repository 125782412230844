import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SucursalComponent } from './sucursal.component';
import { EmpleadosSucursalComponent } from './empleados-sucursal/empleados-sucursal.component';
import { ListaPuestoTrabajoComponent } from '@app/dashboard/sucursal/lista-puesto-trabajo/lista-puesto-trabajo.component';
import { CrearProductoComponent } from '@app/dashboard/sucursal/crear-producto/crear-producto.component';
import { ListaProductoComponent } from '@app/dashboard/sucursal/lista-producto/lista-producto.component';
import { EmpleadosComponent } from './empleados/empleados.component';
import { ListaSucursalComponent } from '@app/dashboard/sucursal/lista-sucursal/lista-sucursal.component';
import { ReporteSucursalComponent } from '@app/dashboard/sucursal/reporte-sucursal/reporte-sucursal.component';
import { AuthenticationGuard } from '@app/core';

const routes: Routes = [
  {
    path: '',
    component: SucursalComponent,
    canActivate: [AuthenticationGuard],
    data: {
      title: 'Dashboard',
      roles: ['administrador', 'supervisor', 'jefe']
    },
    children: [
      {
        path: 'empleados/:id',
        component: EmpleadosSucursalComponent
      },
      {
        path: 'puestos-trabajos',
        component: ListaPuestoTrabajoComponent,
        data: {
          // title: 'Puestos de trabajo',
          urls: [{ title: 'Dashboard', url: '/dashboard' }, { title: 'Lista puestos de trabajo' }]
        }
      },
      {
        path: 'productos',
        component: ListaProductoComponent,
        data: {
          // title: 'Productos',
          urls: [{ title: 'Dashboard', url: '/dashboard' }, { title: 'Lista de productos' }]
        }
      },
      {
        path: 'lista-sucursales',
        component: ListaSucursalComponent,
        data: {
          // title: 'Lista sucursales',
          urls: [{ title: 'Dashboard', url: '/dashboard' }, { title: 'Lista sucursales' }]
        }
      },
      {
        path: 'empleados',
        component: EmpleadosComponent,
        data: {
          // title: 'Lista empleados',
          urls: [{ title: 'Dashboard', url: '/dashboard' }, { title: 'Lista empleados' }]
        }
      },
      {
        path: 'reporte-sucursal',
        component: ReporteSucursalComponent,
        data: {
          // title: 'Sucursal'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SucursalRoutingModule {}
