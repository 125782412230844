import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModalOptions, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService, SucursalService } from '@app/core';
import { Sucursal } from '@app/core/model/sucursal.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-assign-sucursal',
  templateUrl: './assign-sucursal.component.html',
  styleUrls: ['./assign-sucursal.component.css']
})
export class AssignSucursalComponent implements OnInit {
  sucursalForm: FormGroup;

  @Input() userId: number;
  @Input() estado: boolean;
  isLoading = false;

  sucursales: any;

  modalOptions: NgbModalOptions = {};

  dropdownList: any = [];
  cities: any = [];
  selectedItems: any = [];
  singleselectedItems: any = [];
  dropdownSettings = {};
  singledropdownSettings = {};
  closeDropdownSelection = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private sucursalService: SucursalService,
    public activeModal: NgbActiveModal
  ) {
    this.modalOptions = {
      size: 'lg',
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };

    // this.roles2 = new Array();
  }

  ngOnInit() {
    this.listarSucursales();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'sucursal_id',
      textField: 'nombre',
      selectAllText: 'Seleccionar todos.',
      unSelectAllText: 'Deseleccionar todos.',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.createForm();

    this.sucursalService.getSucrsalesUser(this.userId).subscribe(data => {
      // console.log(data);
      // this.userForm.setValue(data);
      this.selectedItems = data;
    });
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  createForm() {
    this.sucursalForm = this.formBuilder.group({
      sucursal_id: ['', Validators.required]
    });

    // this.addCheckboxes();
  }

  crearRol(): FormGroup {
    return this.formBuilder.group({
      id: '',
      name: '',
      selected: false
    });
  }

  listarSucursales() {
    this.sucursalService.getSucursales().subscribe(
      data => {
        this.sucursales = data;
        // console.log(this.sucursales);
      },
      (error: any) => {
        Swal.fire({
          position: 'top-end',
          width: '23vh',
          heightAuto: true,
          icon: 'error',
          text: error.error,
          showConfirmButton: false,
          timer: 2000
        });
      }
    );
  }

  getSelectedSucursal(event: Sucursal) {
    // alert(event);
    // console.log('Sucursal Changed: ' + event.sucursal_id);
    // console.log(JSON.stringify(event));
    // this.resetPurchase();
    // this.showDiv = null;
    // console.log(i);
    //   this.todos = event.producto_id;
    //  this.patchValues(event.producto_id, i);
    // this.addressCustomer = event.address;
  }

  registerSucursal() {
    const data = {
      user_id: this.userId,
      sucursales: this.selectedItems
    };
    this.userService.saveSucursalesUser(data).subscribe(
      (data3: any) => {
        Swal.fire({
          position: 'top-end',
          width: '23vh',
          heightAuto: true,
          icon: 'success',
          text: data3.success,
          showConfirmButton: false,
          timer: 2000
        });
        this.activeModal.close(data3);
      },
      (error: any) => {
        Swal.fire({
          position: 'top-end',
          width: '23vh',
          heightAuto: true,
          icon: 'error',
          text: error.error,
          showConfirmButton: false,
          timer: 2000
        });
      }
    );
  }
}
