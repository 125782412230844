import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Rol } from '@app/core/model/rol.model';
import { User } from '@app/core/model/user.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@app/core';
import { CreateUserComponent } from '../create-user/create-user.component';
import { AssignSucursalComponent } from '../assign-sucursal/assign-sucursal.component';

@Component({
  selector: 'app-lista-usuarios',
  templateUrl: './lista-usuarios.component.html',
  styleUrls: ['./lista-usuarios.component.css']
})
export class ListaUsuariosComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private modalService: NgbModal
  ) {
    this.selectedRols = new Array();
    this.roles2 = new Array();
    this.editRoles = new Array();

    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  get roles() {
    return this.userForm.get('roles');
  }

  userForm: FormGroup;
  roles2: Rol[];
  editRoles: Rol[];
  users: User[];
  selectedRols: any[];
  usuario: any = {};
  modalOptions: NgbModalOptions = {};
  closeResult: string;

  isAwesome = false;

  ngOnInit() {
    this.listarRoles();
    this.listarUsers();
    //  this.createForm();
  }

  toggleIsAwesome() {
    this.isAwesome = !this.isAwesome;
    // console.log(this.isAwesome);
    // could put additional logic here
  }

  listarRoles() {
    this.userService.getAllRoles().subscribe(
      data => {
        this.roles2 = data;
        // console.log(this.roles2);
      },
      error => {
        console.log('error' + error);
      }
    );
  }

  listarUsers() {
    this.userService.getAll().subscribe(
      data => {
        this.users = data;
        console.log(this.users);
      },
      error => {
        console.log('Error' + error.error);
      }
    );
  }

  createForm() {
    this.userForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      ci: ['', Validators.required],
      roles: this.buildRoles()
    });
  }

  buildRoles() {
    const arr = this.roles2.map((rol: any) => {
      return this.formBuilder.control(rol.enabled);
    });

    // console.log(arr);
    return this.formBuilder.array(arr);
  }

  selectedRoles(e: any, id: number) {
    const objetoRol: any = {};

    if (e.target.checked) {
      objetoRol.id = id;

      this.selectedRols.push(objetoRol);
    } else {
      this.selectedRols.splice(this.selectedRols.indexOf(id), 1);
    }
  }

  enableUser(user: any) {
    // console.log(user);
    this.userService.enableUser(user.detail.id).subscribe(
      data => {
        this.listarUsers();
      },
      error => {
        console.log('error ' + error);
      }
    );
  }

  registerUser() {
    // console.log(this.userForm.value.username);

    this.usuario.username = this.userForm.value.username;
    this.usuario.password = this.userForm.value.password;
    this.usuario.email = this.userForm.value.email;
    this.usuario.nombres = this.userForm.value.nombres;
    this.usuario.apellidos = this.userForm.value.apellidos;
    this.usuario.ci = this.userForm.value.ci;

    this.usuario.roles = this.selectedRols;

    // console.log(this.usuario);
    this.userService.create(this.usuario).subscribe(
      data => {
        alert('Fue creado satisfactoriamente');

        this.listarUsers();
      },
      (error: any) => {
        alert('error');
      }
    );
  }

  private rolesList(roles: any) {
    const array = roles.map((rol: any) => {
      return this.formBuilder.control(rol.enabled);
    });
    // console.log(array);

    return this.formBuilder.array(array);
  }

  editModal(id: string) {
    const modal = this.modalService.open(
      CreateUserComponent,
      this.modalOptions
    );
    modal.componentInstance.userId = id;
    modal.componentInstance.estado = true;
    modal.componentInstance.title = 'Editar Usuario';
    modal.result.then(result => {
      if (result) {
        this.listarUsers();
      }
    });

    // modal.close();
  }

  editSucursal(user: any) {
    // console.log(user.detail.id);
    const modal = this.modalService.open(
      AssignSucursalComponent,
      this.modalOptions
    );
    modal.componentInstance.userId = user.detail.id;
    modal.componentInstance.estado = true;
    modal.componentInstance.title = 'Editar Cliente';
    modal.result.then(result => {
      if (result) {
        this.listarUsers();
      }
    });

    // modal.close();
  }

  createUser() {
    const modalRef = this.modalService.open(
      CreateUserComponent,
      this.modalOptions
    );

    modalRef.componentInstance.roles3 = this.roles2;
    modalRef.componentInstance.title = 'Crear Nuevo Usuario';
    modalRef.result.then(result => {
      if (result) {
        this.listarUsers();
      }
    });
  }

  edit(event: any) {
    console.log(event);
    const modal = this.modalService.open(
      CreateUserComponent,
      this.modalOptions
    );
    modal.componentInstance.userId = event.detail.id;
    modal.componentInstance.estado = true;
    modal.componentInstance.title = 'Editar Usuario';
    modal.result.then(result => {
      if (result) {
        this.listarUsers();
      }
    });
  }
}
