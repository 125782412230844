<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header bg-root">
        <h4 class="card-title text-white float-left">Lista de Sucursales</h4>
        <button type="button" class="btn  btn-success float-right" (click)="nuevo()">
          Nueva
        </button>
      </div>
      <div class="card-body">
        <div class="table-responsive-sm table-responsive-lg table-responsive-md table-hover">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre de sucursal</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let puesto of productos; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ puesto.nombre }}</td>
                <td>
                  <button type="button" class="btn btn-sm btn-icon  btn-outline " (click)="editar(puesto.sucursal_id)">
                    <i class="fas fa-pencil-alt text-warning " style="font-size: 20px"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-icon  btn-outline "
                    title="gestionar empleados de la sucursal"
                    (click)="verEmpleados(puesto.sucursal_id)"
                  >
                    <i class="fas fas fa-users  " style="font-size: 25px"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
