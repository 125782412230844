import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AperturaComponent } from '@app/dashboard/informe/apertura/apertura.component';
import { InfraestructuraComponent } from '@app/dashboard/informe/apertura/infraestructura/infraestructura.component';
import { ActivoComponent } from '@app/dashboard/informe/apertura/activo/activo.component';
import { AmbienteComponent } from '@app/dashboard/informe/apertura/ambiente/ambiente.component';
import { PuestoOperativoComponent } from '@app/dashboard/informe/apertura/puesto-operativo/puesto-operativo.component';
import { AuthenticationGuard } from '@app/core';

const routes: Routes = [
  {
    path: '',
    component: AperturaComponent,
    canActivate: [AuthenticationGuard],
    data: {
      title: '',
      roles: ['administrador', 'supervisor', 'jefe']
    },
    children: [
      {
        path: 'infraestructura',
        component: InfraestructuraComponent,
        data: {
          title: 'Infraestructura'
        }
      },
      {
        path: 'activo',
        component: ActivoComponent,
        data: { title: 'Activo' }
      },
      {
        path: 'ambiente',
        component: AmbienteComponent,
        data: { title: 'Ambiente' }
      },
      {
        path: 'puesto_operativo',
        component: PuestoOperativoComponent,
        data: { title: 'Puesto Operativo' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AperturaRoutingModule {}
