import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InformeService } from '@app/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-detalle',
  templateUrl: './edit-detalle.component.html',
  styleUrls: ['./edit-detalle.component.css']
})
export class EditDetalleComponent implements OnInit {
  @Input() turno_id: any;
  informe: any;

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private informeService: InformeService,
    public location: Location
  ) {}

  ngOnInit() {
    if (this.turno_id) {
      this.informeService.edit(this.turno_id).subscribe(data => {
        this.informe = data;
      });
    }
  }

  back() {
    this.location.back();
  }
}
