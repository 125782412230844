import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PuestoTrabajoService extends BaseAPIClass {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.baseUrl = '/puestotrabajo';
  }
  getpuestotrabajo(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseUrl + `/habilitados`);
  }

  enablePuestoTrabajo(id: any) {
    return this.httpClient.get(this.baseUrl + `/habilitar/${id}`);
  }
}
