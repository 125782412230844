<div class="row">
  <!--  <div class="mt-0 p-0" *ngIf="sucursales">-->
  <div class="col-xs-12 col-sm-6 col-md-4  col-xl-3" *ngFor="let sucursal of sucursales">
    <div class="card bg-root text-white">
      <div class="card-body">
        <div class="d-flex">
          <div class="stats">
            <h2 class="text-white">{{ sucursal.nombre }}</h2>

            <div class="stats-icon text-center ml-auto">
              <a [routerLink]="['informes/lista-supercion/', sucursal.sucursal_id]" class="btn btn-rounded btn-outline btn-success m-t-10 font-14"
              >Informes</a
              >
              <a
                *hasPermission="['administrador']"
                [routerLink]="['/dashboard/sucursales/empleados/', sucursal.sucursal_id]"
                class="btn btn-rounded btn-outline btn-light m-t-10 font-14"
                >Empleados</a
              >
            </div>
          </div>
          <div class="stats-icon text-right ml-auto">
            <i class="mdi mdi-home-map-marker display-5 op-3 text-dark"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card" *hasPermission="['administrador']">
      <div class="card-body text-center">
        <p class="display-3">Panel de Administrador</p>
        <p class="h3">
          Bienvenido <strong>{{ nombres }} {{ apellidos }}</strong>
        </p>
      </div>
    </div>
  </div>
  <!--  </div>-->
</div>

<router-outlet></router-outlet>
