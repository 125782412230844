import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TurnoService extends BaseAPIClass {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.baseUrl = '/turnos';
  }
  getTurnosHabilitados(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseUrl + `/habilitados`);
  }
}
