<div class="modal-header">
  <p class="modal-title h4">{{ title }}</p>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="myForm" (ngSubmit)="submit()">
  <div class="modal-body">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">

          <div class="form-group">
            <label for="file">Fotos</label>
            <input
              formControlName="file"
              id="file"
              type="file"
              class="form-control"
              multiple
              (change)="onFileChange($event)"
            />
            <div
              *ngIf="f.file.touched && f.file.invalid"
              class="alert alert-danger"
            >
              <div *ngIf="f.file.errors.required">File is required.</div>
            </div>
          </div>
          <div class="row">
            <div *ngFor="let url of images; let i = index" class="col-md-6">
              <img
                [src]="url"
                height="150"
                width="200px"
                style="margin: 3px;"
              />
              <span (click)="deleteUser(i)"><i class="fas fa-trash"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary w-30" (click)="activeModal.close('Close click')">
      Cerrar
    </button>
    <button
      class="btn btn-success w-70 btn-block text-uppercase waves-effect waves-light"
      [disabled]="myForm.invalid || isLoading"
    >
      <app-button-loader
        [isLoading]="isLoading"
        [label]="'Guardar'"
      ></app-button-loader>
    </button>
  </div>
</form>
