import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Rol } from '@app/core/model/rol.model';
import { User } from '@app/core/model/user.model';
import { UserService } from '@app/core';
import {
  NgbActiveModal,
  NgbModalOptions,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  userForm: FormGroup;
  roles2: Rol[];
  users: User[];
  selectedRols: any[];
  usuario: any = {};
  rolesAll: any;
  @Input() roles3: any;
  @Input() user: any;
  @Input() flag: boolean;
  @Input() title: string;
  @Input() userId: string;
  @Input() estado: boolean;
  isLoading = false;
  rolesCtrl: FormArray;
  modalOptions: NgbModalOptions = {};
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    public activeModal: NgbActiveModal
  ) {
    this.selectedRols = new Array();
    this.modalOptions = {
      size: 'lg',
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
    // this.roles2 = new Array();
  }

  ngOnInit() {
    this.createForm();
    // this.listarRoles();
    this.llenarRoles();

    if (this.estado === true) {
      this.userService.getById(this.userId).subscribe(data => {
        this.userForm.setValue({
          username: data.username,
          password: '',
          email: data.email,
          nombres: data.nombres,
          apellidos: data.apellidos,
          ci: data.ci,
          celular: data.celular,
          roles: data.roles
        });
      });
    }
  }

  createForm() {
    this.userForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: [''],
      email: ['', [Validators.required, Validators.email]],
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      ci: '',
      celular: '',
      roles: this.formBuilder.array([this.crearRol()])
    });

    // this.addCheckboxes();
  }

  crearRol(): FormGroup {
    return this.formBuilder.group({
      id: '',
      name: '',
      display_name: '',
      selected: false
    });
  }
  listarRoles() {
    this.userService.getAllRoles().subscribe(
      data => {
        this.rolesAll = data;
        console.log(this.rolesAll);
      },
      (error: any) => {
        alert('error');
      }
    );
  }

  llenarRoles() {
    this.userService.getAllRoles().subscribe(
      data => {
        // console.log('llenar roles', data);
        this.rolesAll = data;
        const control = <FormArray>this.userForm.controls['roles'];
        for (let i = 1; i < this.rolesAll.length; i++) {
          control.push(this.crearRol());
        }

        this.userForm.patchValue({ roles: this.rolesAll });
      },
      (error: any) => {
        alert('error');
      }
    );
  }

  get roles(): FormArray {
    return this.userForm.get('roles') as FormArray;
  }

  buildRoles() {
    const arr = this.roles3.map((rol: any) => {
      return this.formBuilder.control(rol.enabled);
    });

    // console.log(arr);
    return this.formBuilder.array(arr);
  }

  registerUser(userForm: any) {
    // console.log(userForm);
    // console.log(this.userId);
    if (this.estado === true) {
      this.userService
        .update(this.userId, userForm)
        .pipe(
          finalize(() => {
            this.userForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          data3 => {
            Swal.fire({
              position: 'top-end',
              width: '20vh',
              heightAuto: true,
              icon: 'success',
              text: data3.success,
              showConfirmButton: false,
              timer: 2000
            });
            this.activeModal.close(data3);
          },
          (error: any) => {
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'error',
              text: error.error,
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
    } else {
      this.userService
        .create(userForm)
        .pipe(
          finalize(() => {
            this.userForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          data => {
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'success',
              text: data.success,
              showConfirmButton: false,
              timer: 2000
            });

            this.activeModal.close(data);
          },
          (error: any) => {
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'error',
              text: error.error,
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
    }
  }
}
