import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PreguntaService } from '@app/core';

@Component({
  selector: 'app-salida-personal',
  templateUrl: './salida-personal.component.html',
  styleUrls: ['./salida-personal.component.css']
})
export class SalidaPersonalComponent implements OnInit {
  salidaForm: FormGroup;
  preguntas: any[];
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  @Output() formReady = new EventEmitter<FormGroup>();
  @Input() categoria: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private preguntasService: PreguntaService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.createForm();

    this.preguntasService.getPreguntaId(13).subscribe(data => {
      this.preguntas = data;
      for (let i = 0; i < data.length; i++) {
        this.salidaPersonal.push(
          this.crearSalidaFormGruop(data[i].pregunta_id)
        );
      }
      this.salidaForm.patchValue({ salidaPersonal: data });
      if (this.categoria) {
        this.salidaForm.patchValue({
          salidaPersonal: this.categoria.preguntas,
          observacion: this.categoria.observacion,
          categoria_id: this.categoria.categoria_id
        });
      }
    });
    this.formReady.emit(this.salidaForm);
  }

  createForm() {
    this.salidaForm = this.formBuilder.group({
      salidaPersonal: this.formBuilder.array([]),
      observacion: null,
      categoria_id: null
    });
  }
  get salidaPersonal(): FormArray {
    return this.salidaForm.get('salidaPersonal') as FormArray;
  }

  crearSalidaFormGruop(pregunta_id?: number) {
    return this.formBuilder.group({
      descripcion: null,
      respuesta: false,
      observacion: null,
      pregunta_id: pregunta_id
    });
  }

  mostrarOb(i: any, input: HTMLInputElement) {
    // const input = document.getElementById(i);
    // console.log(input.classList.add(''));
    input.classList.remove('d-none');
  }

  ocultar(i: number, input: HTMLInputElement) {
    input.classList.add('d-none');
  }
}
