import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@app/core';
import { EmpleadoService } from '@app/core/service/empleado.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-create-empleado',
  templateUrl: './create-empleado.component.html',
  styleUrls: ['./create-empleado.component.css']
})
export class CreateEmpleadoComponent implements OnInit {
  empleadoForm: FormGroup;
  empleados: any;
  @Input() title: string;
  @Input() empleado_id: string;
  @Input() estado: boolean;
  isLoading = false;
  // subir foto
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  imagenURL: any = {};
  progress = 0;
  currentUserSubscription: Subscription;
  images: any = [];
  currentUser: any;
  userid: string;

  constructor(
    private formBuilder: FormBuilder,
    private empleadoService: EmpleadoService,
    public activeModal: NgbActiveModal,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.createForm();
    // console.log(this.cliente_id);
    const id = this.empleado_id;

    // console.log(id);
    // console.log(this.estado);
    if (this.estado === true) {
      this.empleadoService.getById(id).subscribe(data => {
        // console.log(data);
        this.empleadoForm.setValue({
          nombres: data.nombres,
          apellidos: data.apellidos,
          ci: data.ci,
          file: data.foto,
          celular: data.celular
        });
      });
    }
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = _event => {
      this.previewUrl = reader.result;
    };
  }
  onFileChange(event: any) {
    // console.log(event);
    if (event.target.files && event.target.files[0]) {
      // console.log(event.target.files);
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();

        reader.onload = (event2: any) => {
          // console.log('imagen???', event2.target.result);
          this.images.push(event2.target.result);

          this.empleadoForm.patchValue({ file: this.images });
          // console.log('despues de patch', this.empleadoForm.value);
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  createForm() {
    this.empleadoForm = this.formBuilder.group({
      nombres: ['', [Validators.required]],
      apellidos: ['', [Validators.required]],
      ci: [''],
      celular: [''],
      file: [null]
    });
  }

  listClient() {
    this.empleadoService.getAll().subscribe(
      data => {
        this.empleados = data;
        // console.log(data);
      },
      error => {
        console.log(error.error);
      }
    );
  }

  uploadFile(event: any) {
    const reader = new FileReader(); // HTML5 FileReader API
    const file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        // this.imageUrl = reader.result;
        this.empleadoForm.patchValue({
          file: event.target.files[0]
        });
        // this.editFile = false;
        // this.removeUpload = true;
      };
      // ChangeDetectorRef since file is loading outside the zone
      //  this.cd.markForCheck();
    }
  }

  registerEmpleado(empleadoForm: any) {
    const formData = new FormData();
    formData.append('file', this.fileData);
    formData.append('nombres', empleadoForm.nombres);
    formData.append('apellidos', empleadoForm.apellidos);
    formData.append('ci', empleadoForm.ci);
    formData.append('celular', empleadoForm.celular);

    // console.log(formData);

    if (this.estado === true) {
      formData.append('_method', 'PUT');
      this.isLoading = true;
      empleadoForm.file = this.fileData;
      // console.log(empleadoForm.file);
      this.empleadoService
        .updateEmpleado(this.empleado_id, formData)
        .pipe(
          finalize(() => {
            this.empleadoForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          (data: any) => {
            // Swal.fire(data);
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'success',
              text: data.success,
              showConfirmButton: false,
              timer: 2000
            });
            // console.log(data);
            this.empleadoForm.reset();
            this.activeModal.close(data);
          },
          error1 => {
            this.isLoading = false;
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'error',
              text: error1.error,
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
    } else {
      this.isLoading = true;
      // empleadoForm.file = this.fileData;

      console.log('formulario enviado ', empleadoForm);

      this.empleadoService
        .create(formData)
        .pipe(
          finalize(() => {
            this.empleadoForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          data => {
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'success',
              text: data.success,
              showConfirmButton: false,
              timer: 2000
            });
            this.empleadoForm.reset();
            this.activeModal.close(data);
          },
          error1 => {
            this.isLoading = false;
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'error',
              text: error1.error,
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
    }
  }
}
