<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body bg-root">
        <div class="row text-center">
          <div class="col col-md-12  col-xs-12">
            <h3 class="text-white">Gestion de empleados para las aperturas</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-body">
    <form action="" [formGroup]="reporteForm" class="form-horizontal form-material" id="userform" novalidate>
      <div class="row ">
        <div class="col col-sm-12 col-md-12 d-flex justify-content-center">
          <div class="form-group row text-center">
            <label for="inputEmail3" class="col-sm-4 col-md-6 col-form-label text-md-right">Seleccione la sucursal:</label>
            <div class="col-sm-8 col-md-6 ">
              <app-form-error-wrapper [control]="reporteForm.controls['sucursal_id']" [controlName]="'Sucursal'">
                <select class="form-control" id="inputEmail3" formControlName="sucursal_id" (change)="buscar(reporteForm.value)">
                  <option value="0">Todas las sucursales</option>
                  <option *ngFor="let sucursal of sucursales" value="{{ sucursal.sucursal_id }}">
                    {{ sucursal.nombre }}
                  </option>
                </select>
              </app-form-error-wrapper>
            </div>
          </div>
        </div>
      </div>
    </form>
    <hr />
    <div class="" *ngIf="veficarDatos(); else notInforme">
      <p class="display-5 text-danger">
        No existen usuarios.
      </p>
    </div>
    <ng-template #notInforme>
      <div class="table-responsive-sm table-responsive-lg table-responsive-md table-hover">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nombre de sucursal</th>
              <th scope="col">Empleado</th>
              <th scope="col">Cargo</th>
              <th scope="col">Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let infome of infomes; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ infome.sucursal }}</td>
              <td>{{ infome.nombre_completo }}</td>
              <td>{{ infome.cargo }}</td>
              <td>
                <label class="switch switch_type1" role="switch" *ngIf="infome.apertura == true">
                  <input type="checkbox" class="switch__toggle" (click)="enable(infome.sucursal_id, infome.id)" checked="false" />
                  <span class="switch__label"></span>
                </label>
                <label class="switch switch_type1" role="switch" *ngIf="infome.apertura == null || infome.apertura == false">
                  <input type="checkbox" class="switch__toggle" (click)="enable(infome.sucursal_id, infome.id)" />
                  <span class="switch__label"></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </div>
</div>
