<div class="form-group text-center">
  <div class="card" [formGroup]="activoForm">
    <div class="card-body">
      <h4 class="card-title">Activos</h4>
      <div formArrayName="activos">
        <div class="form-row"
             *ngFor="let detalle of activos.controls; let i = index" [formGroupName]="i">
          <div class="col-12">
            <div class="row">
              <div class="col-6 ">
                <p class="text-right">   {{ detalle.get('descripcion').value }}</p>
              </div>
              <div class="col-6">
                <div class="d-flex  justify-content-around">
                  <app-switch-control
                    formControlName="respuesta"
                  ></app-switch-control>
                  <button
                    type="button"
                    class="btn btn-sm btn-icon btn-pure btn-outline "
                    (click)="mostrarOb(i)"
                  >
                    <i class="mdi mdi-tooltip-outline-plus h3"></i>
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control d-none"
                  id="{{ i }}"
                  formControlName="observacion"
                  placeholder="Observación por pregunta"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <label for="observacion">Observación</label>
        <input type="text" class="form-control" id="observacion" formControlName="observacion" placeholder="Observación general"/>
      </div>
    </div>
  </div>

</div>


