<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form action="" [formGroup]="ProductoForm" (ngSubmit)="register(ProductoForm.value)" class="form-horizontal form-material" id="userform" novalidate>
  <div class="modal-body">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="form-row ">
            <div class="form-group  col-md-12 col-sm-12">
              <app-form-error-wrapper [control]="ProductoForm.controls['nombre']" [controlName]="'Nombre'">
                <label for="pregunta">Nombre </label>
                <input class="form-control" type="text" formControlName="nombre" id="pregunta" placeholder="" />
              </app-form-error-wrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary waves-effect w-30" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      Cerrar
    </button>
    <button class="btn btn-success   btn-block text-uppercase waves-effect waves-light w-70" [disabled]="ProductoForm.invalid || isLoading">
      <app-button-loader [isLoading]="isLoading" [label]="'Guardar'"></app-button-loader>
    </button>
  </div>
</form>
