import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InformeService } from '@app/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@app/core';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';
import { InformePoliticaService } from '@core/service/informe-politica.service';

@Component({
  selector: 'app-cierre',
  templateUrl: './cierre.component.html',
  styles: []
})
export class CierreComponent implements OnInit {
  cierreForm: FormGroup;
  currentUserSubscription: Subscription;
  currentUser: any;
  apellidos: string;
  nombres: string;
  userId: string;
  id: string;
  informeDetalle: any;
  isLoading = false;
  @Input() informe: any;
  @Input() editEstado: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private informeService: InformeService,
    private authService: AuthenticationService,
    private politicaService: InformePoliticaService
  ) {}

  ngOnInit() {
    this.route.snapshot.paramMap.get('idSucursal');
    this.route.params.subscribe(params => {
      this.id = params.idSucursal;
      this.informeService.getById(params.idInforme).subscribe(data => {
        this.informeDetalle = data;
        // console.log('informeDetalle:', data);
      });
    });
    // console.log('informe: ', this.informe);
    this.currentUser = this.authService.credentials;
    // console.log('aqui users ', this.currentUser);
    if (this.currentUser) {
      this.apellidos = this.currentUser.user.apellidos;
      this.nombres = this.currentUser.user.nombres;
      this.userId = this.currentUser.user.id;
    }
    // this.currentUserSubscription = this.authService.currentUser.subscribe(
    //   user => {
    //     // console.log(user);
    //     this.currentUser = user;
    //     this.apellidos = this.currentUser.data.user.apellidos;
    //     this.nombres = this.currentUser.data.user.nombres;
    //     this.userId = this.currentUser.data.user.id;
    //   }
    // );
    // console.log('user_id', this.currentUser.data.id);

    this.cierreForm = this.fb.group({
      user_id: this.userId,
      sucursal_id: this.id,
      fecha: null,
      turno_id: this.informe ? this.informe.turno_id : null
    });
    if (this.informe) {
      this.cierreForm.patchValue({
        fecha: this.informe.fecha,
        supervisor_id: this.informe.supervisor_id,
        user_id: this.informe.user_id
      });
    }
  }

  formInitialized(name: string, form: FormGroup) {
    this.cierreForm.setControl(name, form);
  }

  getNombreSupervisor() {
    if (this.informe) {
      return this.informe.usuario.nombre_completo;
    }
    return ` ${this.apellidos} ${this.nombres}`;
  }

  save() {
    this.cierreForm.patchValue({
      fecha: this.informeDetalle
        ? this.informeDetalle.fecha
        : this.informe.fecha
    });
    // console.log(this.cierreForm.value);
    this.isLoading = true;
    if (this.editEstado) {
      this.informeService
        .editCategoriaCierre(this.cierreForm.value)
        .pipe(
          finalize(() => {
            this.cierreForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          data => {
            // console.log(data);
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'success',
              text: data.success,
              showConfirmButton: false,
              timer: 2000
            });
          },
          (error1: any) => {
            this.isLoading = false;
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'error',
              text: error1.error,
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
    } else {
      this.politicaService
        .saveInformeCierre(this.cierreForm.value)
        .pipe(
          finalize(() => {
            this.cierreForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          (data: any) => {
            // console.log(data);
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'success',
              text: data.success,
              showConfirmButton: false,
              timer: 2000
            });
            this.router.navigate([
              `/dashboard/informes/lista-supercion/${this.id}`
            ]);
            // this.router.navigate()
          },
          (error1: any) => {
            this.isLoading = false;
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'error',
              text: error1.error,
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
    }
  }
}
