import { Component, OnInit } from '@angular/core';
import { PuestoTrabajoService } from '@core/service/puesto-trabajo.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CrearPuestoTrabajoComponent } from '@app/dashboard/sucursal/crear-puesto-trabajo/crear-puesto-trabajo.component';

@Component({
  selector: 'app-lista-puesto-trabajo',
  templateUrl: './lista-puesto-trabajo.component.html',
  styleUrls: ['./lista-puesto-trabajo.component.css']
})
export class ListaPuestoTrabajoComponent implements OnInit {
  puestos: any[];
  modalOptions: NgbModalOptions = {};

  constructor(
    private puestoTrabajoService: PuestoTrabajoService,
    private modalService: NgbModal
  ) {
    this.listarPuestoT();
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {}

  listarPuestoT() {
    this.puestoTrabajoService.getAll().subscribe(data => {
      this.puestos = data;
      console.log(data);
    });
  }

  nuevo() {
    const modal = this.modalService.open(
      CrearPuestoTrabajoComponent,
      this.modalOptions
    );
    modal.componentInstance.title = 'Nueva Puesto de trabajo';
    modal.componentInstance.estado = true;
    modal.result.then(res => {
      if (res) {
        this.listarPuestoT();
      }
    });
  }

  enable(trabajo_id: any) {
    this.puestoTrabajoService
      .enablePuestoTrabajo(trabajo_id)
      .subscribe(data => {
        console.log(data);
        this.listarPuestoT();
      });
  }

  editar(pregunta_id: any) {
    const modal = this.modalService.open(
      CrearPuestoTrabajoComponent,
      this.modalOptions
    );
    modal.componentInstance.title = 'Editar Puesto de trabajo';
    modal.componentInstance.puestoId = pregunta_id;
    modal.result.then(res => {
      if (res) {
        this.listarPuestoT();
      }
    });
  }
}
