<div class="row">
  <div class="col">
    <a  (click)="back()">
      <i class="fas fa-arrow-left text-info"></i>
    </a>
  </div>
</div>
<div class="modal-header">
  <h4 class="modal-title"></h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card-body" *ngIf="informe">
    <div class="row">
      <div class="col-12" *ngIf="informe.estado === 'APERTURA'">
        <app-apertura [informe]="informe" [editEstado]="true"></app-apertura>
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="informe.estado === 'TRANSICION'">
        <app-transicion [informe]="informe" [editEstado]="true"></app-transicion>
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="informe.estado === 'CIERRE'">
        <app-cierre [informe]="informe" [editEstado]="true"></app-cierre>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default waves-effect" aria-label="Close" (click)="activeModal.close('Cross click')">
    Cerrar
  </button>
</div>
