import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CierreRoutingModule } from './cierre-routing.module';
import { CierreComponent } from './cierre.component';
import { ActivoComponent } from '@app/dashboard/informe/cierre/activo/activo.component';
import { InfraestructuraComponent } from '@app/dashboard/informe/cierre/infraestructura/infraestructura.component';
import { SaldoProductosComponent } from './saldo-productos/saldo-productos.component';
import { InformeFinancieroComponent } from './informe-financiero/informe-financiero.component';
import { SalidaPersonalComponent } from './salida-personal/salida-personal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [
    CierreComponent,
    ActivoComponent,
    InfraestructuraComponent,
    SaldoProductosComponent,
    InformeFinancieroComponent,
    SalidaPersonalComponent
  ],
  exports: [CierreComponent],
  imports: [
    CommonModule,
    CierreRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatTabsModule,
    MatSlideToggleModule,
    SharedModule
  ]
})
export class CierreModule {}
