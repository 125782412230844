import { Component, OnInit } from '@angular/core';
import { TurnoService } from '@core/service/turno.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-categoria',
  templateUrl: './lista-categoria.component.html',
  styleUrls: ['./lista-categoria.component.css']
})
export class ListaCategoriaComponent implements OnInit {
  turnos: any[];
  form: FormControl;
  hora: any;
  type: string;

  constructor(
    private turnosService: TurnoService,
    private formBuilder: FormBuilder
  ) {
    this.type = 'time';
  }

  ngOnInit() {
    this.listarTurnos();
    this.form = new FormControl();
    // console.log('hora1', this.hora);
    this.form.valueChanges.subscribe(value => (this.hora = value));
  }

  listarTurnos() {
    this.turnosService.getAll().subscribe(data => {
      // console.log(data);
      this.turnos = data;
    });
  }

  editar(politica_id: any, hora?: any) {
    // console.log(politica_id);
    // console.log('hora', hora);
    // console.log(this.hora);
    if (this.hora) {
      const time = {
        politica_id: politica_id,
        hora: this.hora
      };
      this.turnosService.update(politica_id, time).subscribe(data => {
        // console.log(data);
        // this.form.reset();
        Swal.fire({
          position: 'top-end',
          width: '23vh',
          heightAuto: true,
          icon: 'success',
          text: data.success,
          showConfirmButton: false,
          timer: 2000
        });
        this.listarTurnos();
      });
    }
  }
}
