import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SucursalRoutingModule } from './sucursal-routing.module';
import { SucursalComponent } from './sucursal.component';
import { EmpleadosSucursalComponent } from './empleados-sucursal/empleados-sucursal.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListaPuestoTrabajoComponent } from './lista-puesto-trabajo/lista-puesto-trabajo.component';
import { CrearPuestoTrabajoComponent } from './crear-puesto-trabajo/crear-puesto-trabajo.component';
import { SharedModule } from '@app/shared';
import { CrearProductoComponent } from './crear-producto/crear-producto.component';
import { ListaProductoComponent } from './lista-producto/lista-producto.component';
import { EmpleadosComponent } from './empleados/empleados.component';
import { ListaSucursalComponent } from './lista-sucursal/lista-sucursal.component';
import { CrearSucursalComponent } from './crear-sucursal/crear-sucursal.component';
import { CreateEmpleadoComponent } from './empleados/create-empleado/create-empleado.component';
import { ReporteSucursalComponent } from '@app/dashboard/sucursal/reporte-sucursal/reporte-sucursal.component';

@NgModule({
  declarations: [
    SucursalComponent,
    EmpleadosSucursalComponent,
    ListaPuestoTrabajoComponent,
    CrearPuestoTrabajoComponent,
    CrearProductoComponent,
    ListaProductoComponent,
    EmpleadosComponent,
    ListaSucursalComponent,
    CrearSucursalComponent,
    CreateEmpleadoComponent,
    ReporteSucursalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SucursalRoutingModule,
    NgMultiSelectDropDownModule,
    SharedModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    CrearPuestoTrabajoComponent,
    CrearSucursalComponent,
    CrearProductoComponent,
    CreateEmpleadoComponent
  ]
})
export class SucursalModule {}
