import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SucursalService extends BaseAPIClass {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.baseUrl = '/sucursales';
  }

  getSucrsalesUser(idUser: number): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + `/user/${idUser}`);
  }

  getSucursales(): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/habilitados');
  }

  getUserSucursal(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(this.baseUrl + '/usuarios', data);
  }

  addEmpleados(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(
      this.baseUrl + '/asignar/empleados',
      data
    );
  }

  deleteEmpleado(empleado: any): Observable<any> {
    // elimina  emplado de una sucursal signada
    return this.httpClient.post<any>(this.baseUrl + '/empleado', empleado);
  }
}
