<div [formGroup]="group" class="form-group row mb-0">
  <label
    for="input{{ label }}"
    class="form-label col  col-xs-6 text-md-center  text-sm-right  col-sm-7  mb-0 "
    >{{ label }} :</label
  >
  <div class="col col-xs-4 col-sm-4  mb-2  ">
    <app-form-error-wrapper
      [control]="group.controls['cantidad']" [controlName]="'Cantidad'">
      <input type="number" formControlName="cantidad" class="form-control" id="input{{ label }}"
      />
    </app-form-error-wrapper>
  </div>
</div>
