import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Detalle } from '@core/model/detalle.model';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InformePoliticaService extends BaseAPIClass {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.baseUrl = '/informe';
  }

  saveInforme(informe: any) {
    return this.httpClient.post(this.baseUrl + '/apertura', informe);
  }
  saveInformeTransicion(informe: any) {
    return this.httpClient.post(this.baseUrl + '/transicion', informe);
  }
  saveInformeCierre(informe: any) {
    return this.httpClient.post(this.baseUrl + '/cierre', informe);
  }
  getDetalleInforme(informeId: any): Observable<Detalle[]> {
    return this.httpClient.get<Detalle[]>(this.baseUrl + `/${informeId}`);
  }
}
