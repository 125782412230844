<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body bg-root">
        <div class="row ">
          <div class="col col-md-10  col-xs-12">
            <h3 class="text-white">Seguimiento de empleado</h3>
          </div>
          <!--            todo  refactorizar para buscar cuando haga cambios -->
          <!--          <div class="col col-xs-12">-->
          <!--            <button-->
          <!--              class="btn btn-outline-light"-->
          <!--              [disabled]="reporteForm.invalid || isLoading"-->
          <!--              (click)="buscar(reporteForm.value)"-->
          <!--            >-->
          <!--              <app-button-loader [isLoading]="isLoading" [label]="'Buscar'"></app-button-loader>-->
          <!--            </button>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <form action="" [formGroup]="reporteForm" class="form-horizontal form-material" id="userform" novalidate>
              <div class="row">
                <div class="col col-sm-6 col-md-4">
                  <div class="form-group m-t-20">
                    <div class="col-xs-12">
                      <app-form-error-wrapper [control]="reporteForm.controls['fecha1']" [controlName]="'Fecha'">
                        <label for="fecha1">Fecha inicio</label>
                        <input class="form-control" id="fecha1" type="date" formControlName="fecha1" (change)="buscar(reporteForm.value)"/>
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="col col-sm-6 col-md-4">
                  <div class="form-group m-t-20">
                    <div class="col-xs-12">
                      <app-form-error-wrapper [control]="reporteForm.controls['fecha2']" [controlName]="'Fecha'">
                        <label for="fecha2">Fecha final</label>
                        <input class="form-control" id="fecha2" type="date" formControlName="fecha2" (change)="buscar(reporteForm.value)"/>
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="col col-sm-12 col-md-4">
                  <div class="form-group m-t-20">
                    <div class="col-xs-12">
                      <app-form-error-wrapper [control]="reporteForm.controls['empleado_id']" [controlName]="'Empleado'">
                        <label for="empleado">Empleados:</label>
                        <select class="form-control" id="empleado" formControlName="empleado_id" (change)="buscar(reporteForm.value)">
                          <option value="0">Todos los empleados</option>
                          <option *ngFor="let empleado of empleados" value="{{ empleado.empleado_id }}">{{ empleado.nombre_completo }}</option>
                        </select>
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <div class="" *ngIf="veficarDatos(); else notInforme">
      <p class="h1 text-danger">
        Ups! No se hallaron resultados para su búsqueda, intente otra vez cambiando las fechas o el nombre del empleado
      </p>
    </div>
    <ng-template #notInforme>
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" *ngFor="let infome of infomes">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <!--             {{infome.fecha}}-->
            </mat-panel-title>
            <mat-panel-description>
              {{ infome.fecha }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="table-responsive-sm table-responsive-lg table-responsive-md table-hover">
            <table class="table">
              <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Sucursal</th>
                <th scope="col">Nombre del empleado</th>
                <!--                <th scope="col">Estado</th>-->
                <th scope="col">Puesto</th>
                <!--                <th scope="col">Fecha</th>-->
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let list of infome.lista; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ list.sucursal }}</td>
                <td>{{ list.nombres }} {{ list.apellidos }}</td>
                <!--                <td>{{ list.estado }}</td>-->
                <td>{{ list.puesto }}</td>
                <!--                <td>{{ infome.fecha }}</td>-->
              </tr>
              </tbody>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-template>
  </div>
</div>
