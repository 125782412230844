<div class="row">
  <!--  <div class="mt-0 p-0" *ngIf="sucursales">-->
  <div
    class="col-xs-12 col-sm-6 col-md-4  col-xl-3"
    *ngFor="let sucursal of sucursales"
  >
    <div class="card card-outline-success">
      <div class="card-header">
        <h4 class="m-b-0 text-white">Sucursal</h4>
      </div>
      <div class="card-body">
        <h3 class="card-title">{{ sucursal.nombre }}</h3>
        <!--        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>-->
        <!--        <a href="#" class="btn btn-inverse">Go somewhere</a>-->
        <a
          [routerLink]="['../lista-supercion/', sucursal.sucursal_id]"
          class="btn btn-rounded btn-inverse"
        >Ir Sucursal</a
        >
      </div>
    </div>
  </div>
  <!--  </div>-->
</div>
