import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InformeComponent } from '@app/dashboard/informe/informe.component';
import { ListaInformesComponent } from '@app/dashboard/informe/lista-informes/lista-informes.component';
import { ListaSupervicionComponent } from '@app/dashboard/informe/lista-supervicion/lista-supervicion.component';
import { ListaCategoriaComponent } from '@app/dashboard/informe/lista-categoria/lista-categoria.component';
import { ListaPreguntaComponent } from '@app/dashboard/informe/lista-pregunta/lista-pregunta.component';
import { DetalleComponent } from '@app/dashboard/informe/detalle/detalle.component';
import { ListaDiarioComponent } from '@app/dashboard/informe/lista-diario/lista-diario.component';
import { ReporteEmpleadosComponent } from '@app/dashboard/informe/reporte-empleados/reporte-empleados.component';
import { GaleriaComponent } from '@app/dashboard/informe/galeria/galeria.component';

const routes: Routes = [
  {
    path: '',
    component: InformeComponent,
    children: [
      {
        path: 'lista-informe',
        component: ListaInformesComponent,
        data: {
          title: 'Lista de informe'
        }
      },
      {
        path: 'categorias',
        component: ListaCategoriaComponent,
        data: {
          // title: 'Categorias',
          urls: [
            { title: 'Dashboard', url: '/dashboard' },
            { title: 'Sucursal' },
            { title: 'Categorias' }
          ]
        }
      },

      {
        path: 'preguntas',
        component: ListaPreguntaComponent,
        data: {
          // title: 'Preguntas',
          urls: [
            { title: 'Dashboard', url: '/dashboard' },
            { title: 'Sucursal' },
            { title: 'Preguntas' }
          ]
        }
      },
      {
        path: 'diario',
        component: ListaDiarioComponent,
        data: {
          // title: 'Informe diario',
          // urls: [
          //   { title: 'Dashboard', url: '/dashboard' },
          //   { title: 'Informe' },
          //   { title: 'Lista de informe diario' }
          // ]
        }
      },
      {
        path: 'seguimiento-empleado',
        component: ReporteEmpleadosComponent,
        data: {
          // title: 'Seguimiento empleados',
          // urls: [
          //   { title: 'Dashboard', url: '/dashboard' },
          //   { title: 'Informe' },
          //   { title: 'Seguimiento empleados' }
          // ]
        }
      },
      {
        path: 'lista-supercion/:idSucursal',
        component: ListaSupervicionComponent,
        data: {
          title: 'Lista de Informes',
          urls: [
            { title: 'Sucursales', url: '/dashboard' },
            { title: 'Informe' },
            { title: 'Lista de Informes' }
          ]
        }
      },
      {
        path: 'detalles/:idInforme',
        component: DetalleComponent,
        data: {
          // title: 'Detalle informe'
        }
      },
      {
        path: 'galeria/:idInforme',
        component: GaleriaComponent,
        data: {
          title: 'Archivos adjuntos de informe'
        }
      },
      {
        path: 'apertura/:idSucursal',
        loadChildren: () =>
          import('./apertura/apertura.module').then(m => m.AperturaModule)
      },
      {
        path: 'transicion/:idSucursal/:idInforme',
        loadChildren: () =>
          import('./transicion/transicion.module').then(m => m.TransicionModule)
      },
      {
        path: 'cierre/:idSucursal/:idInforme',
        loadChildren: () =>
          import('./cierre/cierre.module').then(m => m.CierreModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InformeRoutingModule {}
