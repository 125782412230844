import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  resetPasswordForm: FormGroup;
  isLoading = false;
  email: string;
  type: string;
  estadoType: boolean;

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.createForm();
    this.type = 'password';
    this.estadoType = false;
  }

  ngOnInit() {
    this.email = localStorage.getItem('email') || '';
    // console.log(this.email);
    if (this.email) {
      // console.log(this.email);
      this.loginForm.patchValue({ email: this.email, recuerdame: true });
    }
  }

  login() {
    this.isLoading = true;
    this.authenticationService
      .login(this.loginForm.value)
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (credentials: Authentication.Credentials) => {
          console.log(credentials);
          this.recuerdame(credentials);
          this.router.navigate(['/dashboard'], {
            replaceUrl: true
          });
        },
        (error1: any) => {
          console.log(error1);
          this.router.navigate(['/dashboard'], {
            replaceUrl: true
          });
          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'error',
            text: error1.error,
            showConfirmButton: false,
            timer: 2000
          });
        }
      );
  }

  resetPassword() {
    // TODO: Implement Reset Password
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required],
      recuerdame: null
    });
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  recuerdame(data?: any) {
    // console.log('user', data);
    if (this.loginForm.value.recuerdame) {
      localStorage.setItem('email', data.data.user.username);
    } else {
      localStorage.removeItem('email');
    }
  }

  cambiar(estado: boolean) {
    // console.log('click me!',this.estadoType)
    if (!estado) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
    this.estadoType = !estado;
  }
}
