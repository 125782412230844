<div *ngIf="loading">
  <app-spinner></app-spinner>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body bg-root">
        <div class="row d-flex justify-content-between">
            <h3 class=" text-white ">Reporte de Sucursales</h3>
          <div class="">
            <!--          <button-->
            <!--              class="btn btn-outline-light"-->
            <!--              [disabled]="reporteForm.invalid || isLoading"-->
            <!--              (click)="buscar(reporteForm.value)"-->
            <!--            >-->
            <!--              <app-button-loader-->
            <!--                [isLoading]="isLoading"-->
            <!--                [label]="'Buscar'"-->
            <!--              ></app-button-loader>-->
            <!--            </button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body p-1">
        <div class="row">
          <div class="col-12">
            <form
              action=""
              [formGroup]="reporteForm"
              class="form-horizontal form-material"
              id="userform"
              novalidate
            >
              <div class="row">
                <div class="col col-sm-6 col-md-4">
                  <div class="form-group m-t-20">
                    <div class="col-xs-12">
                      <app-form-error-wrapper
                        [control]="reporteForm.controls['fecha1']"
                        [controlName]="'Fecha'"
                      >
                        <label for="">Fecha inicio</label>
                        <input
                          class="form-control"
                          type="date"
                          formControlName="fecha1"
                          (change)="buscar(reporteForm.value)"
                        />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="col col-sm-6 col-md-4">
                  <div class="form-group m-t-20">
                    <div class="col-xs-12">
                      <app-form-error-wrapper
                        [control]="reporteForm.controls['fecha2']"
                        [controlName]="'Fecha'"
                      >
                        <label for="">Fecha final</label>
                        <input
                          class="form-control"
                          type="date"
                          formControlName="fecha2"
                          (change)="buscar(reporteForm.value)"
                        />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="col col-sm-12 col-md-4">
                  <div class="form-group m-t-20">
                    <div class="col-xs-12">
                      <app-form-error-wrapper
                        [control]="reporteForm.controls['sucursal_id']"
                        [controlName]="'Sucursal'"
                      >
                        <label for="">Sucursales:</label>
                        <select
                          class="form-control"
                          formControlName="sucursal_id"
                          (change)="buscar(reporteForm.value)"
                        >
                          <option value="0">Todas las sucursales</option>
                          <option
                            *ngFor="let sucursal of sucursales"
                            value="{{ sucursal.sucursal_id }}"
                            >{{ sucursal.nombre }}</option
                          >
                        </select>
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <div class="" *ngIf="veficarDatos(); else notInforme">
      <p class="h1 text-danger">
        Ups! No se hallaron resultados para su búsqueda, intente otra vez cambiando las fechas o la sucursal
      </p>
    </div>
    <ng-template #notInforme>
      <div
        class="table-responsive-sm table-responsive-lg table-responsive-md table-hover"
      >
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nombre de sucursal</th>
              <th scope="col">Estado</th>
              <th scope="col">Fecha</th>
              <th scope="col"></th>
              <th scope="col">Registrado Por</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let infome of infomes">
              <th scope="row">{{ infome.informe_id }}</th>
              <td>{{ infome.sucursal.nombre }}</td>
              <td>{{ infome.estado }}</td>
              <td>{{ infome.fecha | date: 'dd/MM/yyyy' }}</td>
              <td>
                <ul>
                  <li
                    *ngFor="let list of infome.list_turnos"
                    style="font-size: 12px"
                    title="{{ list.created_at | date: 'shortDate' }}"
                  >
                    {{ list.estado }}: {{ list.created_at | date: 'shortTime' }}
                  </li>
                </ul>
              </td>
              <td>{{ infome.supervisor.nombre_completo }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm btn-icon  btn-outline "
                  (click)="verInfrome(infome.informe_id)"
                >
                  <i
                    class="fas fa-eye text-warning "
                    style="font-size: 25px"
                  ></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </div>
</div>
