import { Component, OnInit } from '@angular/core';
import { EmpleadoService } from '@app/core/service/empleado.service';
import { ActivatedRoute } from '@angular/router';
import { SucursalService } from '@app/core';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment.prod';

@Component({
  selector: 'app-empleados-sucursal',
  templateUrl: './empleados-sucursal.component.html',
  styleUrls: ['./empleados-sucursal.component.css']
})
export class EmpleadosSucursalComponent implements OnInit {
  empleados: any[];
  empleadosSucursal: any[];

  id: number;
  selectedItems: any[] = [];
  dropdownSettings = {};
  isLoading: boolean;
  loading: boolean;
  url: string;
  constructor(
    private empleadoService: EmpleadoService,
    private sucursalService: SucursalService,
    private route: ActivatedRoute
  ) {
    this.isLoading = false;
    this.loading = false;
    this.url = environment.imgUrl;
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'empleado_id',
      textField: 'nombre_completo',
      selectAllText: 'Seleccionar todos.',
      unSelectAllText: 'Deseleccionar todos.',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.listarEmpleadosPorSucusal(this.id);
    });

    this.listarEmpleados();
  }
  onItemSelect(item: any) {
    // console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  listarEmpleadosPorSucusal(id: number) {
    this.empleadoService.getEmpleadoSucursalId(this.id).subscribe(data => {
      console.log(data);
      this.empleadosSucursal = data;
    });
  }

  listarEmpleados() {
    this.empleadoService.getEmpleadosDisponibles(this.id).subscribe(data => {
      console.log(data);
      this.empleados = data;
    });
  }

  agregarEmpleado() {
    const data = {
      sucursal_id: this.id,
      empleados: this.selectedItems
    };
    if (this.selectedItems.length > 0) {
      console.log('items:', this.selectedItems);
      this.isLoading = true;
      this.sucursalService
        .addEmpleados(data)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          data3 => {
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'success',
              text: 'Correcto.',
              showConfirmButton: false,
              timer: 2000
            });
            this.selectedItems = [];
            this.listarEmpleadosPorSucusal(this.id);
            this.listarEmpleados();
          },
          (error: any) => {
            this.isLoading = false;
            Swal.fire({
              position: 'top-end',
              width: '23vh',
              heightAuto: true,
              icon: 'error',
              text: error.error,
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
    }
  }

  eliminar(empleado_id: any) {
    const empleado = {
      empleado_id: empleado_id,
      sucursal_id: this.id
    };
    Swal.fire({
      title: 'Esta seguro...?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar'
    }).then((result: any) => {
      if (result.value) {
        this.sucursalService.deleteEmpleado(empleado).subscribe(data => {
          this.listarEmpleadosPorSucusal(this.id);
          this.listarEmpleados();
        });
      }
    });
  }
}
