<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header bg-root ">
        <h4 class="card-title text-white float-left">Lista de Preguntas</h4>
        <button type="button" class="btn  btn-success float-right" (click)="nuevaPregunta()">
          Nueva
        </button>
      </div>
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col-9">
            <form action="" [formGroup]="formGroup">
              <div class="form-row">
                <div class="form-group col">
                  <label for="politica_id">Politica</label>
                  <select class="custom-select" id="politica_id" formControlName="politica_id" (change)="listCategorias(formGroup.value.politica_id)">
                    <option value="0" selected>Seleccionar todo...</option>
                    <option *ngFor="let politica of turnos" [value]="politica.politica_id">{{ politica.nombre }}</option>
                  </select>
                </div>
                <div class="form-group col">
                  <label for="categoria_id">Categoria </label>
                  <select
                    class="custom-select"
                    id="categoria_id"
                    formControlName="categoria_id"
                    (change)="changeCategoria(formGroup.value.categoria_id)"
                  >
                    <option value="0" selected>Seleccionar todo...</option>
                    <option *ngFor="let categoria of categorias" [value]="categoria.categoria_id">{{ categoria.nombre }}</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="table-responsive-sm table-responsive-lg table-responsive-md table-hover">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Pregunta</th>
                <th scope="col">Categoría</th>
                <th scope="col" width="10%">Estado</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let pregunta of preguntas; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ pregunta.descripcion }}</td>
                <td>{{ pregunta.categoria.nombre }}</td>

                <td>
                  <label class="switch switch_type1" role="switch" *ngIf="pregunta.activo == true">
                    <input type="checkbox" class="switch__toggle" (click)="enable(pregunta.pregunta_id)" checked="false" />
                    <span class="switch__label"></span>
                  </label>
                  <label class="switch switch_type1" role="switch" *ngIf="pregunta.activo == false">
                    <input type="checkbox" class="switch__toggle" (click)="enable(pregunta.pregunta_id)" />
                    <span class="switch__label"></span>
                  </label>
                </td>
                <td>
                  <button type="button" class="btn btn-sm btn-icon  btn-outline " (click)="editar(pregunta.pregunta_id)">
                    <i class="fas fa-pencil-alt text-warning "></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
