import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AperturaRoutingModule } from './apertura-routing.module';
import { AperturaComponent } from './apertura.component';
import { NavbarComponent } from './navbar/navbar.component';
import { InfraestructuraComponent } from './infraestructura/infraestructura.component';
import { ActivoComponent } from './activo/activo.component';
import { AmbienteComponent } from './ambiente/ambiente.component';
import { PuestoOperativoComponent } from './puesto-operativo/puesto-operativo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from '@app/shared';
import { FotosComponent } from '../fotos/fotos.component';

@NgModule({
  declarations: [
    AperturaComponent,
    NavbarComponent,
    InfraestructuraComponent,
    ActivoComponent,
    AmbienteComponent,
    PuestoOperativoComponent
  ],
  exports: [AperturaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AperturaRoutingModule,
    MatInputModule,
    MatFormFieldModule,
    MatTabsModule,
    MatSlideToggleModule,
    SharedModule
  ]
})
export class AperturaModule {}
