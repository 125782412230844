<div class="row">
  <div class="col">
    <a [routerLink]="['/dashboard/informes/lista-supercion/', id]">
      <i class="fas fa-arrow-left text-info"></i>
    </a>
  </div>
</div>
<div class="mat-typography">
  <h2 class="text-center">Transición</h2>
  <div class="checkout-form">
    <form novalidate [formGroup]="transicionForm">
      <div class="row d-flex justify-content-around">
        <div class="col">
          <p class=" text-left">
            <label>Supervisor:</label> <br>
            {{ getNombreSupervisor() }}
          </p>
        </div>
        <div class=" col">
          <label class="float-right" *ngIf="informeDetalle"
          >Fecha: <br>
            {{ informeDetalle.fecha ? (informeDetalle.fecha | date: 'dd/MM/yyyy')  : (informe.fecha | date: 'dd/MM/yyyy') }}
          </label>
        </div>
      </div>
      <mat-tab-group class="text-center">
        <mat-tab label="Productos e Insumos en transición ">
          <app-producto-insumo
            [categoria]="informe.categorias[0]"
            *ngIf="informe"
            (formReady)="formInitialized('productos', $event)"
          ></app-producto-insumo>
          <app-producto-insumo *ngIf="!informe" (formReady)="formInitialized('productos', $event)"></app-producto-insumo>
        </mat-tab>
        <mat-tab label="Puesto Operativo">
          <app-puesto-operativo
            [categoria]="informe.categorias[3]"
            *ngIf="informe"
            (formReady)="formInitialized('puestoOperativo', $event)"
            [sucursalId]="informe.sucursal_id"
          ></app-puesto-operativo>
          <app-puesto-operativo (formReady)="formInitialized('puestoOperativo', $event)" [sucursalId]="id" *ngIf="!informe"></app-puesto-operativo>
        </mat-tab>
        <mat-tab label="Activo">
          <app-activo [categoria]="informe.categorias[1]" *ngIf="informe" (formReady)="formInitialized('activo', $event)"></app-activo>
          <app-activo *ngIf="!informe" (formReady)="formInitialized('activo', $event)"></app-activo>
        </mat-tab>
        <mat-tab label="Higiene y Limpieza" style="background:yellow">
          <app-higiene-limpieza
            [categoria]="informe.categorias[2]"
            *ngIf="informe"
            (formReady)="formInitialized('higiene', $event)"
          ></app-higiene-limpieza>
          <app-higiene-limpieza *ngIf="!informe" (formReady)="formInitialized('higiene', $event)"></app-higiene-limpieza>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>

  <div class="row text-center">
    <div class="col">
      <button type="button" uiSref="work" class="btn btn-success btn-raised " (click)="save()" [disabled]="transicionForm.invalid || isLoading">
        <app-button-loader [isLoading]="isLoading" [label]="'Registrar'"></app-button-loader>
      </button>
    </div>
  </div>
  <!--   <pre>
    {{ transicionForm.value | json }}
  </pre>-->
</div>
