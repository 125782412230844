<div class="row">
  <div class="col">
    <a [routerLink]="['/dashboard/informes/lista-supercion/', id]">
    <i class="fas fa-arrow-left text-info"></i>
    </a>
  </div>
</div>
<div class="mat-typography">
  <h2 class="text-center ">Apertura Sucursal</h2>
  <div class="checkout-form">
    <form novalidate [formGroup]="aperturaForm">
      <div class="row">
        <div class=" col-md-4 col-sm-12 ">
          <!--        <p class="h3">{{usuario.nombres }} {{ usuario.apellidos}}</p>-->
          <p class="">
            <label>Supervisor:</label> <br>
            <span>{{ getNombreSupervisor() }}</span>
          </p>
        </div>
        <div class="col-md-4 col-sm-12">
          <app-form-error-wrapper [control]="aperturaForm.controls['fecha']" [controlName]="'Fecha'">
            <label for="fecha"> Fecha: </label>
            <input type="date" id="fecha" class="form-control datepicker" formControlName="fecha" />
          </app-form-error-wrapper>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="form-row">
            <div class="form-group  col-md-10 col-sm-12">
              <app-form-error-wrapper [control]="aperturaForm.controls['supervisor_id']" [controlName]="'Usuario'">
                <label for="usuarios">Transferir a: </label>
                <select formControlName="supervisor_id" id="usuarios" class="custom-select ">
                  <option [ngValue] = "null" disabled > Seleccione supervisor</option>
                  <option *ngFor="let usuario of usuarios" value="{{ usuario.id }}"
                    >{{ usuario.nombre_completo }} (<span [ngStyle]="{ 'font-size': '10px' }">{{ usuario.cargo }}</span>)
                  </option>
                </select>
              </app-form-error-wrapper>
            </div>
          </div>
        </div>
      </div>

      <mat-tab-group class="text-center">
        <mat-tab label="Infraestructura">
          <!--          <billing-form (formReady)="formInitialized('billing', $event)"></billing-form>-->
          <app-infraestructura
            [categoria]="informe.categorias[0]"
            *ngIf="informe"
            (formReady)="formInitialized('infra', $event)"
          ></app-infraestructura>
          <app-infraestructura *ngIf="!informe" (formReady)="formInitialized('infra', $event)"></app-infraestructura>
        </mat-tab>
        <mat-tab label="Activo">
          <!--                    <shipping-form (formReady)="formInitialized('shipping', $event)"></shipping-form>-->
          <app-activo [categoria]="informe.categorias[1]" *ngIf="informe" (formReady)="formInitialized('activo', $event)"></app-activo>
          <app-activo *ngIf="!informe" (formReady)="formInitialized('activo', $event)"></app-activo>
        </mat-tab>
        <mat-tab label="Ambientes">
          <app-ambiente [categoria]="informe.categorias[2]" *ngIf="informe" (formReady)="formInitialized('ambiente', $event)"></app-ambiente>
          <app-ambiente *ngIf="!informe" (formReady)="formInitialized('ambiente', $event)"></app-ambiente>
        </mat-tab>
        <mat-tab label="Puesto Operativo">
          <app-puesto-operativo
            [categoria]="informe.categorias[3]"
            *ngIf="informe"
            (formReady)="formInitialized('puestoOperativo', $event)"
            [sucursalId]="informe.sucursal_id"
          ></app-puesto-operativo>
          <app-puesto-operativo *ngIf="!informe" (formReady)="formInitialized('puestoOperativo', $event)" [sucursalId]="id"></app-puesto-operativo>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>
  <div class="row text-center">
    <div class="col-md-12">
      <button type="button" uiSref="work" class="btn btn-success btn-lg btn-raised " (click)="save()" [disabled]="aperturaForm.invalid || isLoading">
        <app-button-loader [isLoading]="isLoading" [label]="'Registrar'"></app-button-loader>
      </button>
    </div>
  </div>

  <!--  <pre>-->
  <!--    {{ aperturaForm.value | json }}-->
  <!--  </pre>-->
</div>
