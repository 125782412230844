import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SucursalService } from '@app/core';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-crear-sucursal',
  templateUrl: './crear-sucursal.component.html',
  styleUrls: ['./crear-sucursal.component.css']
})
export class CrearSucursalComponent implements OnInit {
  @Input() title: string;
  @Input() sucursalId: string;
  @Input() estado: boolean;
  sucursalForm: FormGroup;
  isLoading: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private sucursalService: SucursalService,
    public activeModal: NgbActiveModal
  ) {
    this.isLoading = false;
  }

  ngOnInit() {
    this.createForm();
    // console.log(this.sucursalId);
    if (this.sucursalId) {
      this.sucursalService.getById(this.sucursalId).subscribe(data => {
        this.sucursalForm.patchValue(data);
      });
    }
  }

  createForm() {
    this.sucursalForm = this.formBuilder.group({
      nombre: ['', [Validators.required]]
    });
  }

  register(value: any) {
    this.isLoading = true;
    if (this.estado) {
      this.sucursalService
        .create(value)
        .pipe(
          finalize(() => {
            this.sucursalForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(data => {
          // console.log(data);
          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'success',
            text: data.success,
            showConfirmButton: false,
            timer: 2000
          });
          this.activeModal.close(data);
        });
    } else {
      this.sucursalService
        .update(this.sucursalId, value)
        .pipe(
          finalize(() => {
            this.sucursalForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(data => {
          // console.log(data);
          Swal.fire({
            position: 'top-end',
            width: '23vh',
            heightAuto: true,
            icon: 'success',
            text: data.success,
            showConfirmButton: false,
            timer: 2000
          });
          this.activeModal.close(data);
        });
    }
  }
}
