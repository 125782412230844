import { Component, OnInit } from '@angular/core';
import { SucursalService, AuthenticationService } from '@app/core';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  sucursales: any[];
  currentUser: any;
  apellidos: string;
  nombres: string;
  email: string;
  id: number;

  currentUserSubscription: Subscription;

  constructor(
    private sucursalService: SucursalService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.currentUser = this.authService.credentials.user;
    this.apellidos = this.currentUser.apellidos;
    this.nombres = this.currentUser.nombres;
    this.email = this.currentUser.email;
    this.id = this.currentUser.id;
    // console.log(this.currentUser);
    this.listarSucursal();
  }
  listarSucursal() {
    this.sucursalService.getSucrsalesUser(this.id).subscribe(data => {
      // console.log(data);
      this.sucursales = data;
    });
  }
}
