import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InformeService } from '@app/core';
import * as moment from 'moment';
import { EmpleadoService } from '@core/service/empleado.service';

@Component({
  selector: 'app-reporte-empleados',
  templateUrl: './reporte-empleados.component.html',
  styleUrls: ['./reporte-empleados.component.css']
})
export class ReporteEmpleadosComponent implements OnInit {
  loading: false;
  reporteForm: FormGroup;
  isLoading: false;
  sucursales: any[];
  infomes: any[];
  empleados: any[];
  panelOpenState = false;
  constructor(
    private formBuilder: FormBuilder,
    private informeService: InformeService,
    private empleadosService: EmpleadoService
  ) {}

  ngOnInit() {
    this.createForm();
    // this.listarInforme();

    this.empleadosService.getAllEmpleados().subscribe(data => {
      this.empleados = data;
      // console.log(data);
    });

    this.informeService
      .getReporteFechaEmpleado(this.reporteForm.value)
      .subscribe(data => {
        this.infomes = data;
        // console.log(data);
      });
  }

  createForm() {
    this.reporteForm = this.formBuilder.group({
      fecha1: [moment().format('YYYY-MM-DD'), [Validators.required]],
      fecha2: [moment().format('YYYY-MM-DD'), [Validators.required]],
      empleado_id: ['0', [Validators.required]],
      user_id: null
    });
  }

  buscar(value?: any) {
    this.informeService.getReporteFechaEmpleado(value).subscribe(data => {
      this.infomes = data;
    });
  }

  veficarDatos() {
    // console.log(this.infomes);
    if (this.infomes) {
      if (this.infomes && this.infomes.length <= 0) {
        return true;
      }
      return false;
    }
    return false;
  }
}
