import { Component, Input, OnInit } from '@angular/core';
import { InformeService } from '@app/core';
import { Detalle } from '@core/model/detalle.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { EditDetalleComponent } from '@app/dashboard/informe/edit-detalle/edit-detalle.component';
import { ScriptService } from '@core/service/script.service';
import { InformePoliticaService } from '@core/service/informe-politica.service';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment.prod';
// import { ScriptService } from './script.service';
declare let pdfMake: any;

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css']
})
export class DetalleComponent implements OnInit {
  informeId: string;
  detalles: any[];
  idSucursal: any;
  @Input() fecha: string;
  loading = true;
  modalOptions: NgbModalOptions = {};
  url = environment.serverUrl;

  constructor(
    private informeService: InformeService,
    private politicaService: InformePoliticaService,
    private route: ActivatedRoute,
    public modalService: NgbModal,
    private scriptService: ScriptService,
    public location: Location,
    private httpClient: HttpClient
  ) {
    this.modalOptions = {
      size: 'lg',
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
    console.log('Loading External Scripts');
    this.scriptService.load('pdfMake', 'vfsFonts').then(data => console.log(data));
  }

  ngOnInit() {
    this.route.snapshot.paramMap.get('idInforme');
    this.route.params.subscribe(params => {
      this.informeId = params.idInforme;
      console.log(params.idInforme);
      // tslint:disable-next-line:radix
      this.listarDetalleInforme(this.informeId);
    });
  }

  listarDetalleInforme(informeId?: any) {
    this.politicaService.getDetalleInforme(informeId).subscribe((data: Detalle[]) => {
      console.log(data);
      this.detalles = data;
      this.idSucursal = data[0].sucursal_id;
      this.loading = false;
    });
  }

  editar(turno_id: any) {
    console.log(turno_id);
    const modal = this.modalService.open(
      EditDetalleComponent,
      this.modalOptions
    );
    modal.componentInstance.turno_id = turno_id;
    modal.result.then(res => {
      console.log(res);
      this.listarDetalleInforme(this.informeId);
    });
  }

  generatePdf(action = 'open') {
    const documentDefinition = this.getDocumentDefinition();
    switch (action) {
      case 'open':
        pdfMake.createPdf(documentDefinition).open();
        break;
      case 'print':
        pdfMake.createPdf(documentDefinition).print();
        break;
      case 'download':
        pdfMake.createPdf(documentDefinition).download();
        break;
      default:
        pdfMake.createPdf(documentDefinition).open();
        break;
    }
  }

  getDocumentDefinition() {
    return {
      content: [
        {
          text: 'RESUME',
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            [
              {
                layout: {
                  defaultBorder: false,
                  hLineWidth: function(i: any, node: any) {
                    return 1;
                  },
                  vLineWidth: function(i: any, node: any) {
                    return 1;
                  },
                  hLineColor: function(i: any, node: any) {
                    if (i === 1 || i === 0) {
                      return '#bfdde8';
                    }
                    return '#eaeaea';
                  },
                  vLineColor: function(i: any, node: any) {
                    return '#eaeaea';
                  },
                  hLineStyle: function(i: any, node: any): any {
                    // if (i === 0 || i === node.table.body.length) {
                    return null;
                    // }
                  },
                  // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                  paddingLeft: function(i: any, node: any) {
                    return 10;
                  },
                  paddingRight: function(i: any, node: any) {
                    return 10;
                  },
                  paddingTop: function(i: any, node: any) {
                    return 2;
                  },
                  paddingBottom: function(i: any, node: any) {
                    return 2;
                  },
                  fillColor: function(
                    rowIndex: any,
                    node: any,
                    columnIndex: any
                  ) {
                    return rowIndex % 2 === 0 ? '#f7faf9' : null;
                  }
                },
                table: {
                  headerRows: 1,
                  widths: ['*', '*', '*'],
                  body: [
                    [
                      {
                        text: this.detalles[0].fecha,
                        fillColor: '#eaf2f5',
                        border: [false, true, false, true],
                        margin: [0, 3, 0, 0],
                        textTransform: 'uppercase',
                        fontSize: 10
                      },
                      {
                        text: this.detalles[0].sucursal,
                        fillColor: '#eaf2f5',
                        border: [false, true, false, true],
                        margin: [0, 3, 0, 0],
                        textTransform: 'uppercase',
                        fontSize: 10
                      },
                      {
                        text: this.detalles[0].codigo,
                        fillColor: '#eaf2f5',
                        border: [false, true, false, true],
                        margin: [0, 3, 0, 0],
                        textTransform: 'uppercase',
                        fontSize: 10
                      }
                    ],
                    ...this.detalles[0].turnos.map((data: any, i: number) => {
                      return [
                        {
                          text: data.titulo,
                          fontSize: 8
                        },
                        {
                          text: data.fecha,
                          fontSize: 8
                        },
                        {
                          text: data.detalle,
                          fontSize: 8
                        }
                      ];
                    })
                  ]
                }
              },
              {
                text: 'hola',
                style: 'name'
              },
              {
                text: 'quillacollo'
              },
              {
                text: 'Email : ' + 'hola@gmail.com'
              },
              {
                text: 'Contant No : ' + '......'
              },
              {
                text: 'GitHub: ' + 'eber-kachi',
                link: 'git',
                color: 'blue'
              }
            ]
          ]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ['*', 'auto', 100, '*'],

            body: [
              ['First', 'Second', 'Third', 'The last one'],
              ['Value 1', 'Value 2', 'Value 3', 'Value 4'],
              [{ text: 'Bold value', bold: true }, 'Val 2', 'Val 3', 'Val 4']
            ]
          }
        }
      ]
    };
  }

  getBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log(reader.result);
      // this.resume.profilePic = reader.result as string;
    };
    reader.onerror = error => {
      console.log('Error: ', error);
    };
  }

  back() {
    this.location.back();
  }

}
