import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { Observable } from 'rxjs';
import { Rol } from '../model/rol.model';
import { User } from '../model/user.model';

@Injectable()
export class UserService extends BaseAPIClass {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.baseUrl = '/users';
  }
  getAllRoles(): Observable<Rol[]> {
    return this.httpClient.get<Rol[]>(this.baseUrl + '/roles');
  }
  enableUser(id: number) {
    return this.httpClient.get(this.baseUrl + '/habilitar/' + id);
  }

  saveSucursalesUser(informe: any) {
    return this.httpClient.post(this.baseUrl + '/sucursales', informe);
  }
  getCompareUser(usuario: any) {
    return this.httpClient.post('/compare', usuario);
  }
  getUsersAperuraList(sucursalId: any): Observable<any[]> {
    return this.httpClient.post<any[]>(this.baseUrl + '/sucursal', sucursalId);
  }
  enableUsersApertura(data: any) {
    return this.httpClient.post(this.baseUrl + '/apertura', data);
  }

  userOpening(data: { sucursal_id: any; user_id: any }): Observable<any> {
    return this.httpClient.post(this.baseUrl + `/permitir/apertura`, data);
  }

  userClose(data: { sucursal_id: any; user_id: any }): Observable<any> {
    return this.httpClient.post(this.baseUrl + `/permitir/cierre`, data);
  }

  userTransicion(data: any): Observable<any> {
    return this.httpClient.post(this.baseUrl + `/permitir/transicion`, data);
  }
}
