<div class="form-group text-center">
  <div class="card" [formGroup]="salidaForm">
    <div class="card-body">
      <h4 class="card-title">Salida Personal</h4>
      <div formArrayName="salidaPersonal">
        <div
          class="form-row"
          *ngFor="let detalle of salidaPersonal.controls; let i = index"
          [formGroupName]="i"
        >
          <div class="col-12">
            <div class="row">
              <div class="col-6 ">
                <p class="text-right">   {{ detalle.get('descripcion').value }}</p>
              </div>
              <div class="col-6">
                <div class="d-flex  justify-content-around">
                  <app-switch-control
                    formControlName="respuesta"
                  ></app-switch-control>
                  <button
                    type="button"
                    class="btn btn-sm btn-icon btn-pure btn-outline "
                    (click)="mostrarOb(i, input)" (dblclick)="ocultar(i,input)"
                  >
                    <i class="mdi mdi-tooltip-outline-plus h3"></i>
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control d-none"
                  id="{{ i }}"
                  #input
                  formControlName="observacion"
                  placeholder="Observación por pregunta"
                />
                <!--     <mat-slide-toggle
                  class=""
                  [color]="color"
                  [checked]="checked"
                  formControlName="respuesta"
                  [disabled]="disabled"
                >
                  &lt;!&ndash;              {{(respuesta().controls.value)?'Si':'No' }}&ndash;&gt;
                  {{ respuesta() ? 'Si' : 'No' }}
                  &lt;!&ndash;              {{(checked)?'Si':'No' }}&ndash;&gt;
                </mat-slide-toggle>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <label for="observacion">Observación</label>
        <input
          type="text"
          class="form-control"
          id="observacion"
          formControlName="observacion"
          placeholder="Observación general"
        />
      </div>
    </div>
  </div>
  <!--  <button
    type="button"
    uiSref="work"
    class="btn btn-success btn-raised"
    (click)="save()"
  >
    Siguiente
  </button>-->
</div>
