<div class="modal-header">
  <p class="modal-title h4">{{ title }}</p>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="myForm" (ngSubmit)="submit()">
  <div class="modal-body">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="file">Archivos</label>
            <input type="file" id="file" accept=".xlsx, .docx, .pdf" (change)="onFileChange($event)" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary w-30" (click)="activeModal.close('Close click')">
      Cerrar
    </button>
    <button class="btn btn-success  w-70 btn-block text-uppercase waves-effect waves-light" [disabled]="isLoading">
      <app-button-loader [isLoading]="isLoading" [label]="'Guardar'"></app-button-loader>
    </button>
  </div>
</form>
