import {
  Component,
  AfterViewInit,
  EventEmitter,
  Output,
  OnInit
} from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@app/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit, AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;

  currentUser: any;
  apellidos: string;
  nombres: string;
  email: string;

  currentUserSubscription: Subscription;

  constructor(
    private modalService: NgbModal,
    public router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    // this.currentUserSubscription = this.authService.currentUser.subscribe(
    //    user => {
    //    this.currentUser = user;
    //   this.apellidos = this.currentUser.data.user.apellidos;
    //  this.nombres = this.currentUser.data.user.nombres;
    //  this.email = this.currentUser.data.user.email;
    //  console.log(this.currentUser);
    //  }
    //   );
    this.currentUser = this.authService.credentials.user;
    this.apellidos = this.currentUser.apellidos;
    this.nombres = this.currentUser.nombres;
    this.email = this.currentUser.email;
  }

  ngAfterViewInit() {}

  eliminarCache() {
    this.authService.logout();

    this.router.navigate(['/login']);
  }
}
