import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material';
import { PreguntaService } from '@app/core';
import { EmpleadoService } from '@core/service/empleado.service';

@Component({
  selector: 'app-ambiente',
  templateUrl: './ambiente.component.html',
  styleUrls: ['./ambiente.component.css']
})
export class AmbienteComponent implements OnInit {
  ambienteForm: FormGroup;
  preguntas: any[];
  empleados: any[];
  @Output() formReady = new EventEmitter<FormGroup>();
  @Input() categoria: any;

  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private preguntasService: PreguntaService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.createForm();

    this.preguntasService.getPreguntaId(3).subscribe(data => {
      this.preguntas = data;
      for (let i = 0; i < data.length; i++) {
        this.ambientes.push(this.crearAmbientesFormGruop(data[i].pregunta_id));
      }
      this.ambienteForm.patchValue({ ambientes: data });
      if (this.categoria) {
        this.ambienteForm.patchValue({
          ambientes: this.categoria.preguntas,
          observacion: this.categoria.observacion,
          categoria_id: this.categoria.categoria_id
        });
      }
    });
    this.formReady.emit(this.ambienteForm);
  }

  createForm() {
    this.ambienteForm = this.formBuilder.group({
      ambientes: this.formBuilder.array([]),
      observacion: null,
      categoria_id: null
    });
  }

  get ambientes(): FormArray {
    return this.ambienteForm.get('ambientes') as FormArray;
  }

  crearAmbientesFormGruop(pregunta_id?: number) {
    return this.formBuilder.group({
      descripcion: null,
      respuesta: false,
      observacion: null,
      pregunta_id: pregunta_id
    });
  }

  save(form?: any) {
    /*    if (!form.valid)
          return;
        this.formDataService.setPersonal(this.personal);

        let firstState = this.workflowService.getFirstInvalidStep(STEPS.work);
        if (firstState.length > 0) {
        };*/
    this.router.navigateByUrl('/dashboard/informes/apertura/puesto_operativo', {
      relativeTo: this.route.parent,
      skipLocationChange: true
    });
  }

  mostrarOb(i: any, input: HTMLInputElement) {
    // const input = document.getElementById(i);
    // console.log(input.classList.add(''));
    input.classList.remove('d-none');
  }

  ocultar(i: number, input: HTMLInputElement) {
    input.classList.add('d-none');
  }
}
