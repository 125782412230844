import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Detalle } from '@core/model/detalle.model';

@Injectable({
  providedIn: 'root'
})
export class MultimediaService extends BaseAPIClass {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.baseUrl = '/multimedia';
  }

  saveFotosInforme(dataFoto: any) {
    return this.httpClient.post('/multimedia/turno', dataFoto);
  }
  saveArchivosInforme(dataFoto2: any) {
    return this.httpClient.post('/archivos/turno', dataFoto2);
  }
}
