import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreguntaService extends BaseAPIClass {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.baseUrl = '/preguntas';
  }

  getPreguntaId(id: number): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseUrl + `/categoria/${id}`);
  }

  getCustomSelect(data: any): Observable<any[]> {
    return this.httpClient.post<any[]>(this.baseUrl + `/search`, data);
  }

  enable(pregunta_id: any) {
    return this.httpClient.get(this.baseUrl + `/habilitar/${pregunta_id}`);
  }
}
