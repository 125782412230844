import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { PreguntaService } from '@app/core';
import { ThemePalette } from '@angular/material/core';
import { migrateLegacyGlobalConfig } from '@angular/cli/utilities/config';

@Component({
  selector: 'app-infraestructura',
  templateUrl: './infraestructura.component.html',
  styleUrls: ['./infraestructura.component.css']
})
export class InfraestructuraComponent implements OnInit {
  infraForm: FormGroup;
  preguntas: any[];
  @Output() formReady = new EventEmitter<FormGroup>();
  @Input() categoria: any;
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;

  lastInput: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private preguntasService: PreguntaService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.createForm();

    this.preguntasService.getPreguntaId(1).subscribe(data => {
      this.preguntas = data;
      // console.log('prefuntas', data);
      for (let i = 0; i < data.length; i++) {
        this.infraestructura.push(
          this.crearInfraestructuraFormGruop(data[i].pregunta_id)
        );
      }
      this.infraForm.patchValue({ infraestructura: data });
      if (this.categoria) {
        this.infraForm.patchValue({
          infraestructura: this.categoria.preguntas,
          observacion: this.categoria.observacion,
          categoria_id: this.categoria.categoria_id
        });
      }
    });
    // console.log('categoria: ', this.categoria);
    /*   if (this.respuestas) {
      console.log('infa', this.respuestas);
      console.log(this.infraForm);
      // this.infraForm.patchValue(this.respuestas);
      // this.infraestructura.
      // const control = <FormArray>this.userForm.controls['roles'];
      this.infraForm.patchValue({infraestructura: this.respuestas});

      console.log(this.infraForm);
    }*/
    this.formReady.emit(this.infraForm);
  }

  createForm() {
    this.infraForm = this.formBuilder.group({
      infraestructura: this.formBuilder.array([]),
      observacion: null,
      categoria_id: null
    });
  }

  get infraestructura(): FormArray {
    return this.infraForm.get('infraestructura') as FormArray;
  }

  crearInfraestructuraFormGruop(pregunta_id?: string): FormGroup {
    return this.formBuilder.group({
      descripcion: null,
      respuesta: false,
      observacion: null,
      pregunta_id: pregunta_id
    });
  }

  respuesta() {
    // console.log(this.infraForm.get('infraestructura.respuesta'))
    // console.log(<FormGroup>this.infraForm.get('infraestructura'));
    return <FormControl>this.infraForm.get('infraestructura.respuesta');
    // return this.infraForm.get('infra.infraestructura');
  }

  save(form?: any) {
    /*    if (!form.valid)
      return;
    this.formDataService.setPersonal(this.personal);

    let firstState = this.workflowService.getFirstInvalidStep(STEPS.work);
    if (firstState.length > 0) {
    };*/
    this.router.navigateByUrl('/dashboard/informes/apertura/activo', {
      relativeTo: this.route.parent,
      skipLocationChange: true
    });
  }

  /*  this.router.navigate(['/dashboard/informes/apertura/infraestructura'], {
    skipLocationChange: true
  });*/

  mostrarOb(i: number, input: HTMLInputElement) {
    // console.log(input.attributes);
    //
    // console.log(this.lastInput);
    // this.lastInput = i;
    input.classList.remove('d-none');
    // input.focus({preventScroll: false});

    this.infraForm.get('observacion').valueChanges.subscribe(val => {
      console.log(val);
    });

    // if (i === this.lastInput) {
    //
    //   console.log('aqui');
    //   // input.classList.add('d-none');
    this.lastInput = i;
    // }
    input.addEventListener('focus', (val: any) => {
      console.log(val.target.value.length);
      // input.classList.add('d-none');
      if (val.target.value.length) {
        // console.log('aqui');
        // input.classList.add('d-none');
      }
    });
    // console.log(input.value.length);
    // if (input.value.length === 0) {
    //
    //   input.classList.add('d-none');
    // }

  }

  // mostrarOb(i: any) {
  //   const input = document.getElementById(i);
  //   // console.log(input.classList.add(''));
  //   input.classList.remove('d-none');
  //
  // }
  ocultar(i: number, input: HTMLInputElement) {

    const lastinput = document.getElementById(this.lastInput);
    // console.log(lastinput);
    // if(lastinput){
    //
    // }


    input.classList.add('d-none');
  }
}
