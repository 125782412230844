<div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-ride="carousel">
  <ol class="carousel-indicators">
    <li
      data-target="#carouselExampleIndicators"
      *ngFor="let imagen of imagenes; let i = index"
      [class]="contador === i ? 'active' : i + 1"
      (click)="indicators(i)"
    ></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item" *ngFor="let imagen of imagenes; let i = index" [class]="contador === i ? 'active' : i + 1">
      <img [src]="url + imagen.nombre" class="d-block w-100 " *ngIf="contador === i" alt="img" height="50%" />
    </div>
  </div>
  <a class="carousel-control-prev" href="javascript:void(0)" role="button" data-slide="prev" (click)="onPreviousClick()">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="javascript:void(0)" role="button" data-slide="next" (click)="onNextClick()">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
