import { Component, OnInit } from '@angular/core';
import { InformeService } from '@app/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment.prod';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FotosComponent } from '../fotos/fotos.component';
import { ArchivosComponent } from '../archivos/archivos.component';
import { CarouselComponent } from '@shared/carousel/carousel.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.css']
})
export class GaleriaComponent implements OnInit {
  multimedias: any[];
  id: any;
  url: string;

  modalOptions: NgbModalOptions = {};

  constructor(
    private informesService: InformeService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private location: Location
  ) {
    this.url = environment.imgUrl;
  }

  ngOnInit() {
    this.route.snapshot.paramMap.getAll('idInforme');
    this.route.params.subscribe(params => {
      this.id = params.idInforme;
      // console.log('params', params);
      this.getMultimedia(this.id);
    });
    // console.log(this.id)
  }

  getMultimedia(id: number) {
    this.informesService.getMultimedia(id).subscribe(data => {
      this.multimedias = data;
      // console.log('multimendia', data);
    });
  }

  addFotos(idTurno: string) {
    //  alert(idTurno);
    const modalRef = this.modalService.open(FotosComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Agregar Fotos';
    modalRef.componentInstance.id = idTurno;

    modalRef.result.then(result => {
      if (result) {
        this.getMultimedia(this.id);
      }
    });
  }

  addFiles(idTurno: string) {
    //  alert(idTurno);
    const modalRef = this.modalService.open(
      ArchivosComponent,
      this.modalOptions
    );
    modalRef.componentInstance.title = 'Agregar Archivos';
    modalRef.componentInstance.id = idTurno;

    modalRef.result.then(result => {
      if (result) {
        this.getMultimedia(this.id);
      }
    });
  }

  verFoto(_fotos: any[], id: any) {
    const fotos = [];
    let contador = 0;
    let i = 0;

    for (const foto of _fotos) {
      if (foto.id === id) {
        // @ts-ignore
        contador = i;
        foto.texto = 'active';
      }
      // @ts-ignore
      i++;
      fotos.push(foto);
    }
    const modalRef = this.modalService.open(
      CarouselComponent,
      this.modalOptions
    );
    modalRef.componentInstance.imagenes = fotos;
    modalRef.componentInstance.contador = contador;
    /*  fotos.map((foto:any)=>{
        console.log('map',foto)
      });*/
  }

  back() {
    this.location.back();
  }
}
