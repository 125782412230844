import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransicionRoutingModule } from './transicion-routing.module';
import { TransicionComponent } from './transicion.component';
import { ProductoInsumoComponent } from './producto-insumo/producto-insumo.component';
import { PuestoOperativoComponent } from '@app/dashboard/informe/transicion/puesto-operativo/puesto-operativo.component';
import { ActivoComponent } from '@app/dashboard/informe/transicion/activo/activo.component';
import { HigieneLimpiezaComponent } from './higiene-limpieza/higiene-limpieza.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InputsOpcionComponent } from '@app/dashboard/informe/transicion/inputs-opcion/inputs-opcion.component';
import { SharedModule } from '@app/shared';
import { ConfirmarComponent } from '@app/dashboard/informe/transicion/confirmar/confirmar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    TransicionComponent,
    ProductoInsumoComponent,
    PuestoOperativoComponent,
    ActivoComponent,
    HigieneLimpiezaComponent,
    InputsOpcionComponent,
    ConfirmarComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    TransicionRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatTabsModule,
    MatSlideToggleModule,
    SharedModule
  ],
  exports: [TransicionComponent],
  entryComponents: [ConfirmarComponent]
})
export class TransicionModule {}
