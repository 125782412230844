import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'CONFIGURACION',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    label: '',
    labelClass: '',
    submenu: [],
    roles: 'Administrador'
  },
  {
    path: '',
    title: 'Seguridad',
    icon: 'mdi mdi-gauge',
    class: 'has-arrow',
    label: '1',
    labelClass: 'side-badge badge badge-info',
    extralink: false,
    submenu: [
      {
        path: '/dashboard/usuarios/lista-usuarios',
        title: 'Registrar Usuarios',
        icon: 'mdi mdi-adjust',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Sucursales',
    icon: 'mdi mdi-apps',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: '/apps/chat',
        title: 'Sucursales',
        icon: 'mdi mdi-comment-processing-outline',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/apps/ticketlist',
        title: 'Empleados',
        icon: 'mdi mdi-book-multiple',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/apps/ticketdetails',
        title: 'Turnos',
        icon: 'mdi mdi-book-plus',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/dashboard/sucursales/puestos-trabajos',
        title: 'Puestos de Trabajo',
        icon: 'mdi mdi-calendar',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/dashboard/sucursales/productos',
        title: 'Productos',
        icon: 'mdi mdi-bulletin-board',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      }
    ]
  },

  {
    path: '',
    title: 'Informes',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  {
    path: '',
    title: 'Informes',
    icon: 'mdi mdi-chart-bubble',
    class: 'has-arrow',
    label: '3',
    labelClass: 'side-badge badge badge-danger',
    extralink: false,
    submenu: [
      {
        path: '/dashboard/informes/categorias',
        title: 'Categorias',
        icon: 'mdi mdi-equal',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/dashboard/informes/preguntas',
        title: 'Preguntas',
        icon: 'mdi mdi-message-bulleted',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/carousel',
        title: 'Politicas',
        icon: 'mdi mdi-view-carousel',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Reportes',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  {
    path: '',
    title: 'Reporte Informes',
    icon: 'mdi mdi-chart-bubble',
    class: 'has-arrow',
    label: '3',
    labelClass: 'side-badge badge badge-danger',
    extralink: false,
    submenu: [
      {
        path: '/component/accordion',
        title: 'CAtegorias',
        icon: 'mdi mdi-equal',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/alert',
        title: 'Preguntas',
        icon: 'mdi mdi-message-bulleted',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/carousel',
        title: 'Politicas',
        icon: 'mdi mdi-view-carousel',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      }
    ]
  }
];
