<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body bg-root">
        <p class="h4 text-white float-left">Lista de Usuarios</p>
        <button (click)="createUser()" class="btn  btn-success float-right">
          <i class="fa fa-plus-square mr-2"></i>
          Nuevo Usuario
        </button>
      </div>
    </div>
  </div>
</div>
<!-- <custom-vaxis title="hola" color='red'></custom-vaxis> -->
<custom-card [data]="users"
             (editClick)="edit($event)"
             (sucursalClick)="editSucursal($event)"
             (enableClick)="enableUser($event)"
></custom-card>
<div class="row">
  <!-- .col -->
  <div *ngIf="!users">
    <app-spinner></app-spinner>
  </div>


<!--  <div *ngFor="let item of users" class="col-md-4 col-lg-4 col-xlg-4">-->
    <!-- (deleteClick)="delete($event)"
               (editClick)="edit($event)"
               (enableClick)="enable($event)"

               [urlPath]="url" -->

    <!--  <div class="card card-body h-80">
      <div class="row">
        <div class="col-md-12 text-center">
          <span class="round"> {{ item.nombres | slice: 0:1 }} {{ item.apellidos | slice: 0:1 }} </span>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <h5 class=" pt-20">{{ item.nombres }} {{ item.apellidos }}</h5>
          <small>
            <div class="badge badge-pill badge-primary" *ngFor="let rol of item.roles">
              {{ rol.display_name }}
            </div>
          </small>
          <address class="text-center">
            <label class="switch switch_type1" role="switch" *ngIf="item.enabled == true">
              <input type="checkbox" class="switch__toggle" (click)="enableUser(item.id)" checked="false" />
              <span class="switch__label"></span>
            </label>
            <label class="switch switch_type1" role="switch" *ngIf="item.enabled == false">
              <input type="checkbox" class="switch__toggle" (click)="enableUser(item.id)" />
              <span class="switch__label"></span>
            </label>
          </address>
          <br />
          <div class="ml-2 badge  badge-warning" *ngFor="let sucursal of item.lista_sucursales">{{ sucursal.nombre }}</div>
          <br />
          <div class="text-right">
            <button type="button" class="btn btn-sm btn-icon btn-pure btn-outline " (click)="editSucursal(item.id)">
              <i class="icon-credit-card  text-inverse "></i>
            </button>
            <button type="button" class="btn btn-sm btn-icon btn-pure btn-outline " (click)="editModal(item.id)">
              <i class=" icon-pencil  text-inverse "></i>
            </button>
            &lt;!&ndash; <button type="button" class="btn btn-sm btn-icon btn-pure btn-outline " data-toggle="tooltip" data-original-title="Eliminar">
              <i class="icon-trash  text-danger"></i>
            </button>&ndash;&gt;
          </div>
        </div>
      </div>
    </div>-->
<!--  </div>-->
</div>
