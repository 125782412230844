import { Routes } from '@angular/router';

import { AuthenticationGuard } from '@app/core';

import { DashboardComponent } from '@app/dashboard/dashboard/dashboard.component';

export const DashboardRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'Dashboard',
          roles: ['administrador', 'supervisor', 'jefe']
        }
      },
      {
        path: 'usuarios',
        loadChildren: () =>
          import('./usuario/usuario.module').then(m => m.UsuarioModule)
      },
      {
        path: 'informes',
        loadChildren: () =>
          import('./informe/informe.module').then(m => m.InformeModule)
      },
      {
        path: 'sucursales',
        loadChildren: () =>
          import('./sucursal/sucursal.module').then(m => m.SucursalModule)
      }
    ]
  }
];
