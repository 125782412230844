<div class="form-group text-center">
  <!--  <button type="button" uiSref="work" class="btn btn-success btn-raised" [disabled]="!personalForm.valid" (click)="save(personalForm)"> Next </button>-->
  <div class="card" [formGroup]="puestoOperativoForm">
    <div class="card-body">
      <h4 class="card-title">Puesto Operativo</h4>
      <div formArrayName="puestoOperativos">
        <div
          class="form-row"
          *ngFor="let detalle of puestoOperativos.controls;let i = index"
          [formGroupName]="i"
        >
          <div class="col-12">
            <div class="row">
              <div class="col-6 ">
                <p class="text-right">   {{ detalle.get('descripcion').value }}</p>
              </div>
              <div class="col-6">
                <div class="d-flex  justify-content-around">
                  <app-switch-control
                    formControlName="respuesta"
                  ></app-switch-control>
                  <button
                    type="button"
                    class="btn btn-sm btn-icon btn-pure btn-outline "
                    (click)="mostrarOb(i, input)" (dblclick)="ocultar(i,input)"
                  >
                    <i class="mdi mdi-tooltip-outline-plus h3"></i>
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control d-none"
                  id="{{ i }}"
                  #input
                  formControlName="observacion"
                  placeholder="Observación por pregunta"
                />
                <!--     <mat-slide-toggle
                  class=""
                  [color]="color"
                  [checked]="checked"
                  formControlName="respuesta"
                  [disabled]="disabled"
                >
                  &lt;!&ndash;              {{(respuesta().controls.value)?'Si':'No' }}&ndash;&gt;
                  {{ respuesta() ? 'Si' : 'No' }}
                  &lt;!&ndash;              {{(checked)?'Si':'No' }}&ndash;&gt;
                </mat-slide-toggle>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="form-row" formArrayName="planOperativos">
        <div class="col-md-6 col-xl-4 "
          *ngFor="let detalle of planOperativos.controls; let i = index" [formGroupName]="i">
          <div class=" row">
            <label class="col col-sm-4" for="empleado{{ i }}">
              {{ detalle.get('nombre').value }}
            </label>
            <select class="custom-select col col-sm-8 mt-1" id="empleado{{ i }}" formControlName="empleado_id">
              <option [ngValue] = "null" disabled > Seleccione empleado</option>
              <option *ngFor="let empleado of empleados" value="{{ empleado.empleado_id }}">
                {{ empleado.nombre_completo }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 mt-2">
        <label for="observacion">Observación</label>
        <input type="text" class="form-control" id="observacion" formControlName="observacion" placeholder="Observación general"/>
      </div>
    </div>
  </div>

  <!--  <button type="button" uiSref="work" class="btn btn-success btn-raised" (click)="save()">Next</button>-->
</div>
