<div class="modal-header">
  <p class="modal-title h4">{{ title }}</p>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form
  action=""
  [formGroup]="empleadoForm"
  (ngSubmit)="registerEmpleado(empleadoForm.value)"
  class="form-horizontal form-material"
  id="empleadoform"
  novalidate
>
  <div class="modal-body">
    <div class="card-body">
      <div class="row">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <span>Seleccione una Foto</span>
            <div class="form-group">
              <div class="holder">
                <input type="file" name="image" accept=".png, .jpg, .jpeg" #fileInput (change)="fileProgress($event)" />
              </div>
            </div>
            <!-- Progress Bar -->
            <div class="progress form-group" *ngIf="progress > 0">
              <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress"></div>
            </div>

            <div *ngIf="fileUploadProgress">progreso: {{ fileUploadProgress }}</div>
            <div class="image-preview mb-3" *ngIf="previewUrl">
              <img [src]="previewUrl" height="300" />
            </div>

            <div class="mb-3" *ngIf="uploadedFilePath">
              {{ uploadedFilePath }}
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <!--        <form
          action=""
          [formGroup]="empleadoForm"
          (ngSubmit)="registerEmpleado(empleadoForm.value)"
          class="form-horizontal form-material"
          id="empleadoform"
          novalidate
        >-->
          <div class="form-group row m-t-20">
            <div class="col-xs-12 col-md-6">
              <app-form-error-wrapper [control]="empleadoForm.controls['nombres']" [controlName]="'Nombre '">
                <label for="name">Nombres</label>
                <input class="form-control" type="text" id="name" formControlName="nombres" placeholder="Nombre" />
              </app-form-error-wrapper>
            </div>
            <div class="col-xs-12 col-md-6">
              <app-form-error-wrapper [control]="empleadoForm.controls['apellidos']" [controlName]="'Apellido '">
                <label for="lastName">Apellidos</label>
                <input class="form-control" type="text" id="lastName" formControlName="apellidos" placeholder="Apellido" />
              </app-form-error-wrapper>
            </div>
          </div>

          <div class="form-group row m-t-20">
            <div class="col-xs-12 col-md-6">
              <app-form-error-wrapper [control]="empleadoForm.controls['ci']" [controlName]="'N° de ci '">
                <label for="ci">CI</label>
                <input class="form-control" type="text" id="ci" formControlName="ci" placeholder="N° de CI " />
              </app-form-error-wrapper>
            </div>
            <div class="col-xs-12 col-md-6">
              <app-form-error-wrapper [control]="empleadoForm.controls['celular']" [controlName]="'N° de celular '">
                <label for="celular">Celular</label>
                <input class="form-control" type="number" id="celular" formControlName="celular" placeholder="N° celular " />
              </app-form-error-wrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary waves-effect w-30" (click)="activeModal.close('Close click')">
      Cerrar
    </button>
    <button class="btn btn-success   btn-block w-70 text-uppercase waves-effect waves-light" [disabled]="empleadoForm.invalid || isLoading">
      <app-button-loader [isLoading]="isLoading" [label]="'Guardar'"></app-button-loader>
    </button>
  </div>
</form>
