import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Detalle } from '@core/model/detalle.model';
import { environment } from '@env/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class InformeService extends BaseAPIClass {
  baseUrl = environment.serverBaseUrl;

  constructor(protected httpClient: HttpClient, handler: HttpBackend) {
    super(httpClient);
    this.httpClient = new HttpClient(handler);
    this.baseUrl = this.baseUrl + '/informes';
    // this.baseUrl = '/informes';
  }

  getInformeSucursal(idSucursal: number): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + `/sucursal/${idSucursal}`);
  }
  /*  getInformeSucursalNext(page: number, id: any): Observable<any> {
    // "http://bkcontrolturno.vaixs.org/public/api/informes/sucursal/3?page=2"
    // return this.httpClient.get<any>(last_page);
    return this.httpClient.get<any>(this.baseUrl + `/sucursal/${id}?page=${page}`);
  }*/
  getInformeSucursalPaginateNext(next: string): Observable<any> {
    // "http://bkcontrolturno.vaixs.org/public/api/informes/sucursal/3?page=2"
    return this.httpClient.get<any>(next);
  }

  getInformeDiario(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseUrl + `/diario`);
  }
  getMultimedia(informeId: any): Observable<any[]> {
    return this.httpClient.get<any[]>(
      this.baseUrl + `/multimedia/${informeId}`
    );
  }
  deleteInforme(informe_id: any): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + informe_id);
  }
  deleteInformeStep(informe_id: any): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + '/last/' + informe_id);
  }
  getReporteFecha(data: any): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/sucursales ', data);
  }
  getReporteFechaEmpleado(data: any): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/empleados ', data);
  }
  edit(turno_id: any): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + `/turno/${turno_id}`);
  }

  editCategoriaApertura(categoria: any): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/modificarA ', categoria);
  }
  editCategoriaTransicion(categoria: any): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/modificarT ', categoria);
  }
  editCategoriaCierre(categoria: any): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/modificarC ', categoria);
  }
}
