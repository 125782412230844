import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SucursalService, UserService } from '@app/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users-apertura',
  templateUrl: './users-apertura.component.html',
  styleUrls: ['./users-apertura.component.css']
})
export class UsersAperturaComponent implements OnInit {
  sucursales: any[];
  reporteForm: FormGroup;
  isLoading: false;
  infomes: any[];

  constructor(
    private sucursalService: SucursalService,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.createForm();
    this.sucursalService.getAll().subscribe(data => {
      this.sucursales = data;
      console.log(data);
    });
    this.buscar(this.reporteForm.value);
  }

  createForm() {
    this.reporteForm = this.formBuilder.group({
      sucursal_id: ['0', [Validators.required]]
    });
  }

  buscar(value: any) {
    this.userService.getUsersAperuraList(value).subscribe(data => {
      this.infomes = data;
      console.log(data);
    });
  }

  veficarDatos() {
    if (this.infomes) {
      if (this.infomes && this.infomes.length <= 0) {
        return true;
      }
      return false;
    }
    return false;
  }

  enable(sucursal_id: any, id: any) {
    const data = {
      sucursal_id: sucursal_id,
      user_id: id
    };

    this.userService.enableUsersApertura(data).subscribe(res => {
      this.buscar(this.reporteForm.value);
      Swal.fire({
        position: 'top-end',
        width: '23vh',
        heightAuto: true,
        icon: 'success',
        text: '' + res,
        showConfirmButton: false,
        timer: 2000
      });
      // console.log(res);
    });
  }
}
