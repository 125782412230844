import { Component, OnInit } from '@angular/core';
import { ProductoService } from '@core/service/producto.service';
import { CrearPuestoTrabajoComponent } from '@app/dashboard/sucursal/crear-puesto-trabajo/crear-puesto-trabajo.component';
import { CrearProductoComponent } from '@app/dashboard/sucursal/crear-producto/crear-producto.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lista-producto',
  templateUrl: './lista-producto.component.html',
  styleUrls: ['./lista-producto.component.css']
})
export class ListaProductoComponent implements OnInit {
  productos: any[];
  modalOptions: NgbModalOptions = {};
  constructor(
    private productoService: ProductoService,
    private modalService: NgbModal
  ) {
    this.listarPrdouctos();
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {}

  listarPrdouctos() {
    this.productoService.getAll().subscribe(data => {
      this.productos = data;
      console.log(data);
    });
  }

  nuevo() {
    const modal = this.modalService.open(
      CrearProductoComponent,
      this.modalOptions
    );
    modal.componentInstance.title = 'Nuevo producto';
    modal.componentInstance.estado = true;
    modal.result.then(res => {
      if (res) {
        this.listarPrdouctos();
      }
    });
  }

  enable(trabajo_id: any) {
    this.productoService.enableProducto(trabajo_id).subscribe(data => {
      console.log(data);
      this.listarPrdouctos();
    });
  }

  editar(trabajo_id: any) {
    const modal = this.modalService.open(
      CrearProductoComponent,
      this.modalOptions
    );
    modal.componentInstance.title = 'Editar Producto';
    modal.componentInstance.productoId = trabajo_id;
    modal.result.then(res => {
      if (res) {
        this.listarPrdouctos();
      }
    });
  }
}
